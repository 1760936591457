import { Hidden } from '@material-ui/core';
import React from 'react';
import { getDefaultBreakpoint } from '~/core/util/device/screen';
import { NarrowPricesSection } from '~/components/Home/PricesSection/NarrowPricesSection';
import { WidePricesSection } from '~/components/Home/PricesSection/WidePricesSection';
import { CLEAR_PRICE_PATH } from '~/constants';
import { analyticsTrackEvent } from '~/core/analytics';
import { ACTION_LEARN_MORE_ABOUT_SELF_PAY, HOMEPAGE } from '~/core/analytics/events';
import { openExternalUrl } from '~/core/util/url';
import { BASE_URL } from '../../../config';
import { useUserAgent } from '~/core/util/device';

const title = <>Solv ClearPrice&trade;</>;
const emphasizedText = 'Everyone deserves to know how much care costs.';
const mainText =
  "Healthcare saves lives, but surprise medical bills can just as easily destroy them. We're committed to make getting care as easy as shopping for groceries, and that includes knowing the price of care upfront.";

const learnMoreButtonLabel = 'Learn more';

const handleLearnMoreButtonClick = () => {
  analyticsTrackEvent(HOMEPAGE, { action: ACTION_LEARN_MORE_ABOUT_SELF_PAY });
  openExternalUrl(`${BASE_URL}${CLEAR_PRICE_PATH}`, '_self');
};

export const PricesSection = () => {
  const userAgent = useUserAgent();
  return (
    <>
      <Hidden initialWidth={getDefaultBreakpoint(userAgent)} smUp>
        <NarrowPricesSection
          emphasizedText={emphasizedText}
          handleLearnMoreButtonClick={handleLearnMoreButtonClick}
          learnMoreButtonLabel={learnMoreButtonLabel}
          mainText={mainText}
          title={title}
        />
      </Hidden>
      <Hidden initialWidth={getDefaultBreakpoint(userAgent)} xsDown>
        <WidePricesSection
          emphasizedText={emphasizedText}
          handleLearnMoreButtonClick={handleLearnMoreButtonClick}
          learnMoreButtonLabel={learnMoreButtonLabel}
          mainText={mainText}
          title={title}
        />
      </Hidden>
    </>
  );
};
