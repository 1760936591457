import { CssReset } from '@solvhealth/jigsaw';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import FindCare from '~/components/Home/FindCare';
import MentalHealthBanner from '~/components/Home/MentalHealthBanner';
import SolvNowSection from '~/components/Home/SolvNowSection';
import TriageSection from '~/components/Home/TriageSection';
import { useLDFlags } from '~/core/launch-darkly/hooks/useLDFlags';
import useShouldShowPremiumVisitEntrypoints from '~/hooks/membership/useShouldShowPremiumVisitEntrypoints';
import { LOGGED_OUT_FIND_CARE_VIEW } from '../../components/Account/util/views';
import BenefitsSection from '../../components/Home/BenefitsSection';
import BottomNavSection from '../../components/Home/BottomNavSection';
import Hero from '../../components/Home/Hero';
import HiringBanner from '../../components/Home/HiringBanner';
import HowItWorksSection from '../../components/Home/HowItWorksSection';
import LogosSection from '../../components/Home/LogosSection';
import ProviderSection from '../../components/Home/ProviderSection';
import ConnectedServicesSection from '../../components/Home/ServicesSection/ConnectedServicesSection';
import SuccessStoriesSection from '../../components/Home/SuccessStoriesSection';
import Layout from '../../components/Layout';
import PastBookingNotice from '../../components/PastBookingNotice';
import LoadingScreen from '../../components/SolvPatternLibrary/LoadingScreen';
import { analyticsTrackEvent } from '../../core/analytics';
import {
  ACTION_CHANGE_SEARCH_TAB,
  HOMEPAGE,
  SOLV_PLUS_ENTRYPOINT_HOMEPAGE,
} from '../../core/analytics/events';
import { redirectAfterLogin } from '../../core/login';
import withAuthentication from '../../core/login/withAuthentication';
import { isNativeApp, useUserAgent } from '../../core/util/device';
import { NativeFunctions } from '../../core/util/native';
import { corporationSchema, websiteSchema } from './homepageSchema';

const ConstrainedSection = styled.div`
  max-width: 1200px;
  padding: 0 10px;
  margin: 0 auto;
`;

const NoPaddingConstrainedSection = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const HalfSpaced = styled.div`
  & > :not(:last-child) {
    margin-bottom: 60px;
  }
`;

const HomepageContent = styled.div`
  & > *:not(:first-child):not(:last-child) {
    margin-bottom: 120px;
  }
  max-width: 100vw;
  overflow-x: hidden;
`;

const isFetchingAccountData = (isAuthorized: boolean, isLoggedIn: boolean): boolean =>
  isAuthorized && !isLoggedIn;

/**
 * There are two cases where we don't want the user to see this page, but rather go somewhere else
 * 1. If the user is a returning user with a valid auth token in their cookies, in which case we want to
 *    send them to their account home as soon as they've loaded account data
 * 2. If the user is seeing this page through the iOS webview, in which case we want to
 *    redirect them to the native landing page
 *
 * @returns Whether rendering should be blocked
 */
const shouldBlockRendering = (
  isAuthorized: boolean,
  isLoggedIn: boolean,
  userAgent: string
): boolean => isFetchingAccountData(isAuthorized, isLoggedIn) || isNativeApp(userAgent);

export type HomepageSearchType = 'inPerson' | 'labTest' | 'covid' | 'video' | 'vaccine';

interface HomepageProps {
  // eslint-disable-next-line camelcase
  accountSummary?: { solv_user_date: string };
  pastBookings: {};
  viewport: {};
  isAuthorized: boolean;
  isLoggedIn: boolean;
}

const Homepage = (props: HomepageProps) => {
  const { isAuthorized, isLoggedIn, accountSummary, pastBookings } = props;
  const userAgent = useUserAgent();
  const showSolvNow = useShouldShowPremiumVisitEntrypoints({
    showToExistingMembers: false,
    overrideExcludedLocations: false,
    entrypointId: SOLV_PLUS_ENTRYPOINT_HOMEPAGE,
  });

  const [loading, setLoading] = useState(() => false);

  useEffect(() => {
    if (isNativeApp()) {
      NativeFunctions.navigateToLandingPage.call(window.location.href);
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn && accountSummary?.solv_user_date) redirectAfterLogin(accountSummary);
  }, [isLoggedIn, accountSummary]);

  const [searchType, setSearchType] = useState<HomepageSearchType>('inPerson');

  useEffect(() => {
    analyticsTrackEvent(HOMEPAGE, {
      action: ACTION_CHANGE_SEARCH_TAB,
      tab: searchType,
    });
  }, [searchType]);

  const { triageEnabled } = useLDFlags();

  return (
    <div>
      <script
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: JSON.stringify(corporationSchema) }}
        type="application/ld+json"
      />
      <script
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: JSON.stringify(websiteSchema) }}
        type="application/ld+json"
      />
      <Layout hasDesktopStyling selectedTabId={LOGGED_OUT_FIND_CARE_VIEW} showIntercom>
        {loading && <LoadingScreen />}
        {!loading && (
          <div>
            <MentalHealthBanner />
            <PastBookingNotice pastBookings={pastBookings} />
            <HomepageContent>
              <HalfSpaced>
                <Hero currentSearchType={searchType} setCurrentSearchType={setSearchType} />
                <LogosSection />
              </HalfSpaced>
              <NoPaddingConstrainedSection>
                <ConnectedServicesSection />
              </NoPaddingConstrainedSection>
              {triageEnabled && (
                <ConstrainedSection>
                  <TriageSection />
                </ConstrainedSection>
              )}
              {showSolvNow && <SolvNowSection />}
              <ConstrainedSection>
                <BenefitsSection />
              </ConstrainedSection>
              <HowItWorksSection />
              <ConstrainedSection>
                <SuccessStoriesSection />
              </ConstrainedSection>
              <ConstrainedSection>
                <ProviderSection />
              </ConstrainedSection>
              <ConstrainedSection>
                <BottomNavSection setSearchType={setSearchType} />
              </ConstrainedSection>
              <div>
                <HiringBanner />
                <FindCare />
              </div>
            </HomepageContent>
          </div>
        )}
      </Layout>
      <CssReset />
    </div>
  );
};

export default withAuthentication(Homepage);
