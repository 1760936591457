import React from 'react';
import styled from 'styled-components';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { colors } from '../../../constants/colors';
import { fontSize } from '../../../constants/text';
import { biggerThan, smallerThanOrEqualTo } from '../../../core/util/styledComponents';
import AutocompleteIcon from '../AutocompleteIcon';
import SecondaryLabel from './SecondaryLabel';
import { AutoCompleteResult, SuggestionDoc } from '../AutocompleteResults';

type Props = {
  /** The results to display */
  results?: (AutoCompleteResult & { fullDoc?: SuggestionDoc })[];
};

const Suggestion = styled.div`
  ${smallerThanOrEqualTo.sm`
    margin-left: 10px;
  `}
`;

const Icon = styled(AutocompleteIcon)`
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  ${biggerThan.sm`
    margin-right: 10px;
  `}
`;

const Item = styled(ListItem)<{ $narrow?: boolean; $arrow?: boolean; $hoverEffect?: boolean }>`
  padding: 10px;
  display: flex;
  min-height: ${(props) => (props.$narrow ? '20' : '50')}px;
  transition: background-color 100ms ease;
`;

const Primary = styled.div`
  color: ${colors.myRightFoot};
  font-size: ${fontSize.default};
  line-height: 20px;

  & em {
    color: ${colors.crunchBerry};
    font-style: normal;
  }
`;

const Secondary = styled.div`
  color: ${colors.lightText};
  font-size: ${fontSize.extraSmall};
  line-height: ${fontSize.mediumSmall};
`;

/**
 * A list of autocomplete results or dropdown options to display on the homepage search.
 * Supports images and icons.
 */
const Results = ({ results }: Props) => {
  return (
    <List>
      {results?.map((result, i) => (
        <Item
          $narrow={result.narrow}
          button={!result.static as true}
          data-testid={`auto-complete-${result.primary.split(' ').join('-')}${
            result.secondary ? `-${result?.secondary.split(' ').join('-')}` : ''
          }`}
          key={i}
          onClick={result.static ? undefined : result.onClick}
        >
          <Icon suggestion={result} />
          <Suggestion>
            {result.primaryNode ? (
              result.primaryNode
            ) : (
              <Primary dangerouslySetInnerHTML={{ __html: result.primary }} />
            )}
            <SecondaryLabel result={result} />
          </Suggestion>
        </Item>
      ))}
    </List>
  );
};

export default Results;
