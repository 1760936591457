import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React from 'react';
import styled from 'styled-components';
import { colors } from '../../constants/colors';
import { fontFamily, fontSize } from '../../constants/text';
import { smallerThan } from '../../core/util/styledComponents';

const Banner = styled.a`
  text-decoration: none;
  background: ${colors.crunchBerry};
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${fontFamily.bold};
  font-size: ${fontSize.large};
  color: white;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  transition: background 0.15s;

  &:visited {
    color: white;
  }

  ${smallerThan.md`
    flex-direction: column;
    text-align: center;
  `}

  &:hover {
    background: ${colors.cherryBlossom};
  }

  > *:not(:last-child) {
    margin: 0;
    margin-right: 20px;
  }
`;

const Cta = styled.span`
  display: flex;
  align-items: center;
  color: ${colors.pinkie};
  font-family: ${fontFamily.medium};

  ${smallerThan.md`
  margin-top: 15px;
`}
`;

const CtaText = styled.span`
  margin-right: 8px;
`;

/**
 * A nice pink banner that tells people we're hiring!
 */
const HiringBanner = () => {
  return (
    <Banner href="https://www.solvhealth.com/careers" target="_BLANK">
      <p>We’re hiring! Help us change healthcare — for good.</p>
      <Cta className="cta">
        <CtaText className="text">See openings</CtaText>
        <ArrowForwardIcon className="icon" opacity={0.75} />
      </Cta>
    </Banner>
  );
};

export default HiringBanner;
