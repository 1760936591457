import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';
import { fontSize } from '../../../constants/text';
import { smallerThan } from '../../../core/util/styledComponents';
import dataTestIds from '../../../test/test-ids';

const SearchButtonBase = styled.button`
  padding: 15px 20px;
  background: ${colors.crunchBerry};
  border-radius: var(--search-border-radius);
  appearance: none;
  border: none;
  cursor: pointer;
  align-self: stretch;
  font-size: ${fontSize.hellaLarge};

  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 70px;

  &:disabled {
    pointer-events: none;
    opacity: 0.7;
  }

  ${smallerThan.md`
    font-size: ${fontSize.large};
    padding: 10px 15px;
  `}

  &:focus {
    outline: 2px solid black;
    outline-offset: 2px;
  }
`;

type Props = {
  /** Callback when search should be executed */
  onSearch: () => void;

  /** Whether the search is disabled */
  disabled?: boolean;
};

/**
 * The search button for the homepage search
 */
const SearchButton = ({ onSearch, disabled }: Props) => {
  return (
    <SearchButtonBase
      aria-label="Search"
      data-testid={dataTestIds.homePage.searchButton}
      disabled={disabled}
      id="universal-search-button"
      onClick={onSearch}
    >
      <SearchRoundedIcon style={{ color: 'white', fontSize: 'inherit' }} />
    </SearchButtonBase>
  );
};

export default SearchButton;
