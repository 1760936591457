import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import ServicesSection from '.';
import { symptomsSubmit } from '../../../actions/symptoms';
import { analyticsTrackEvent } from '../../../core/analytics';
import { ACTION_CLICK_SELF_PAY_SERVICE_TILE, HOMEPAGE } from '../../../core/analytics/events';
import history from '../../../core/history';
import { REQUEST_UNIVERSAL_SEARCH } from '../../../sagas/universalSearch';
import { scrollToSearch } from '../scrollToSearch';
import { getSearchUpdate, ServiceForSearch } from './services';
import { setReasonForVisit } from '~/actions/newBooking';

/**
 * Services section connected to our Redux store
 */
const ConnectedServicesSection = () => {
  const searchPosition = useSelector((state: any) => ({
    searchPosition: state.searchPreferences.location || state.position.result,
  }));

  const dispatch = useDispatch();

  /**
   * Triggers the search action, either via it's onClick handler,
   * or by settings the correct universal search parameters.
   */
  function setSearchValues(service: ServiceForSearch) {
    const update = getSearchUpdate(service);

    if (update.type === 'service') {
      analyticsTrackEvent(HOMEPAGE, {
        action: ACTION_CLICK_SELF_PAY_SERVICE_TILE,
        tile: update.name,
      });
    }

    if (service.onClick) {
      service.onClick();
      return;
    }

    dispatch(symptomsSubmit({ patientType: update.patientType }));
    dispatch({ type: REQUEST_UNIVERSAL_SEARCH, fullDoc: update });
    dispatch(setReasonForVisit(service.name));

    if (!isEmpty(searchPosition)) {
      history.push('/search');
    } else {
      scrollToSearch();
    }
  }

  return <ServicesSection setSearchValues={setSearchValues} />;
};

export default ConnectedServicesSection;
