import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TouchableInputProps } from '.';
import { hideModal, showModal } from '../../../actions/runtime';
import { analyticsTrackEvent } from '../../../core/analytics';
import {
  ACTION_CHANGE_VALUE,
  ACTION_CLEAR_VALUE,
  UNIVERSAL_AUTOSUGGEST_INPUT,
} from '../../../core/analytics/events';
import { apiGetJson } from '../../../core/dapi';
import { getSymptomsIsEmergencyUrl } from '../../../core/dapi/symptom';
import logger from '../../../core/logger';
import { isMobile } from '../../../core/util/device';
import { isEmptyString } from '../../../core/util/string';
import { SolvReduxState } from '../../../reducers';
import { REQUEST_UNIVERSAL_SEARCH } from '../../../sagas/universalSearch';
import EmergencyModal from '../../EmergencyModal';
import { EMERGENCY_MODAL_ID } from '../../EmergencyModal/EmergencyModal';
import { AutoCompleteResult, SuggestionDoc } from '../AutocompleteResults';
import { generateDefaultSuggestions, generateDefaultLabSuggestions } from '../Inputs/defaults';
import UserChoice from '../UserChoice';
import { FieldDisplayProps } from './inputs/Field';
import TextInput from './inputs/TextInput';
import dataTestIds from '../../../../src/test/test-ids';
import { HomepageSearchType } from '../../../routes/home/Homepage';
import { useFetchSuggestions } from '~/components/UniversalSearch/Homepage/hooks/useFetchSuggestions';
import { useHandleBlur } from '~/components/UniversalSearch/Homepage/hooks/useHandleBlur';

type Props = { tab: HomepageSearchType } & TouchableInputProps & Omit<FieldDisplayProps, 'label'>;

/**
 * Homepage input for the universal search.
 */
const UniversalInput = ({ touched, tab, ...props }: Props) => {
  const [suggestions, setSuggestions] = useState<
    Array<AutoCompleteResult & { fullDoc?: SuggestionDoc }>
  >([]);

  const { initialValue } = useSelector((state: SolvReduxState) => ({
    initialValue: state.universalSearch.displayQuery,
  }));
  const [value, setValue] = useState(initialValue ?? '');

  const dispatch = useDispatch();

  const toggleEmergencyModal = useCallback(
    (show: boolean) => {
      dispatch((show ? showModal : hideModal)(EMERGENCY_MODAL_ID));
    },
    [dispatch]
  );

  const conditionallyShowEmergencyToolTip = useCallback(
    (query: any) => {
      if (isEmptyString(query)) {
        return;
      }
      let withoutComma = query;
      if (withoutComma.includes(',')) {
        withoutComma = query.split(',').join(' ');
      }

      apiGetJson(getSymptomsIsEmergencyUrl(withoutComma))
        .then((response: any) => response.data.is_emergency && toggleEmergencyModal(true))
        .catch((err: any) => logger.debug(err));
    },
    [toggleEmergencyModal]
  );

  const fetchSuggestions = useFetchSuggestions();

  const setSelectedDocument = useCallback(
    (doc: any) => {
      setValue(doc.name);
      conditionallyShowEmergencyToolTip(doc.name);
      dispatch({
        type: REQUEST_UNIVERSAL_SEARCH,
        fullDoc: doc,
        onlySelectDoc: true,
      });
    },
    [conditionallyShowEmergencyToolTip, dispatch]
  );

  useEffect(() => {
    analyticsTrackEvent(UNIVERSAL_AUTOSUGGEST_INPUT, {
      action: ACTION_CHANGE_VALUE,
      value,
      isMobile: isMobile(),
    });
    if (!value || value?.trim() === '') {
      setSuggestions([]);
    } else {
      const shouldIncludeLabServiceQuery = tab === 'labTest';
      fetchSuggestions(value, shouldIncludeLabServiceQuery, setSelectedDocument, setSuggestions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchSuggestions, setSelectedDocument, value]);

  const defaultSuggestions =
    tab === 'labTest'
      ? generateDefaultLabSuggestions(setSelectedDocument)
      : generateDefaultSuggestions(setSelectedDocument);

  const handleClear = useCallback(() => {
    analyticsTrackEvent(UNIVERSAL_AUTOSUGGEST_INPUT, {
      action: ACTION_CLEAR_VALUE,
      isMobile: isMobile(),
    });
    // TODO: Add clear functionality
  }, []);

  const handleBlur = useHandleBlur(value);

  const LIMIT_SUGGESTIONS = tab === 'labTest' ? 10 : 7;

  return (
    <>
      <TextInput
        dataTestId={dataTestIds.homePage.symptomSearch}
        hideDivider
        label="What?"
        onBlur={handleBlur}
        onChange={setValue}
        onClear={handleClear}
        placeholder={
          tab === 'labTest' ? 'Service, lab, or test type' : 'Symptom, service, or provider'
        }
        results={suggestions.length ? suggestions.slice(0, LIMIT_SUGGESTIONS) : defaultSuggestions}
        selectAllOnFocus
        setIsTouched={touched}
        trackingLabel={UNIVERSAL_AUTOSUGGEST_INPUT}
        value={value}
        {...props}
      />
      <UserChoice />
      <EmergencyModal />
    </>
  );
};

export default UniversalInput;
