import React from 'react';
import styled, { css } from 'styled-components';
import { hexToHSLA } from '../../../../core/util/color';

const CircleBase = styled.div<{ $color: string }>`
  width: 180px;
  height: 180px;
  border-radius: 100%;
  transition: all 0.15s;
  ${({ $color }) => css`
    background: ${hexToHSLA($color, { override: { lightness: 93 } })};
    box-shadow: 0 0 0 50px ${hexToHSLA($color, { override: { lightness: 95 } })},
      0 0 0 100px ${hexToHSLA($color, { override: { lightness: 97 } })};
  `}
`;

type Props = {
  /** The base color for the circles */
  color: string;
};

/**
 * Concentric circles decreasing opacity.
 */
const Circles = ({ color }: Props) => {
  return <CircleBase $color={color}></CircleBase>;
};

export default Circles;
