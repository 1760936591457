/* eslint-disable max-len */
/* eslint-disable react/no-unknown-property */
/* jscs:disable maximumLineLength */
import React, { PureComponent } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

class Alert extends PureComponent {
  render = () => (
    <SvgIcon viewBox="0 0 18 16" {...this.props}>
      <g
        fill="none"
        fillRule="evenodd"
        id="alert-orange-white-border"
        stroke="none"
        strokeWidth="1"
      >
        <g
          fill="none"
          fillRule="evenodd"
          id="CDP-Booking-Module-Desktop"
          stroke="none"
          strokeWidth="1"
        >
          <path
            d="M13.3550818,10.3183349 C13.4800814,10.5683344 13.5191439,10.8222399 13.472269,11.0800518 C13.4253941,11.3378637 13.3003945,11.556613 13.0972699,11.7363002 C12.8941453,11.9159874 12.6519585,12.0058308 12.3707091,12.0058308 L1.12073594,12.0058308 C0.839486616,12.0058308 0.597299812,11.9159874 0.394175177,11.7363002 C0.191050542,11.556613 0.0660509595,11.3378637 0.0191760713,11.0800518 C-0.0276988169,10.8222399 0.0113637091,10.5683344 0.136363292,10.3183349 L5.76134988,0.568358109 C5.90197455,0.318358586 6.10119282,0.150390475 6.35900471,0.0644530607 C6.61681659,-0.0214843536 6.87462848,-0.0214843536 7.13244036,0.0644530607 C7.39025225,0.150390475 7.58947052,0.318358586 7.73009519,0.568358109 L13.3550818,10.3183349 Z M6.74572253,8.30271467 C6.44884812,8.30271467 6.1949426,8.40818317 5.9840056,8.61912016 C5.7730686,8.83005716 5.6676001,9.08396269 5.6676001,9.3808371 C5.6676001,9.67771151 5.7730686,9.93161704 5.9840056,10.142554 C6.1949426,10.353491 6.44884812,10.4589595 6.74572253,10.4589595 C7.04259695,10.4589595 7.29650247,10.353491 7.50743947,10.142554 C7.71837646,9.93161704 7.82384496,9.67771151 7.82384496,9.3808371 C7.82384496,9.08396269 7.71837646,8.83005716 7.50743947,8.61912016 C7.29650247,8.40818317 7.04259695,8.30271467 6.74572253,8.30271467 Z M5.71447499,4.43553639 L5.90197455,7.62302879 C5.90197455,7.68552876 5.92931835,7.74412237 5.9840056,7.79880962 C6.03869285,7.85349687 6.10509918,7.88084067 6.18322388,7.88084067 L7.30822119,7.88084067 C7.38634589,7.88084067 7.45275222,7.85349687 7.50743947,7.79880962 C7.56212672,7.74412237 7.58947052,7.68552876 7.58947052,7.62302879 L7.77697008,4.43553639 C7.77697008,4.34178661 7.74962627,4.26756792 7.69493902,4.21288067 C7.64025177,4.15819342 7.57384544,4.13084962 7.49572075,4.13084962 L5.99572432,4.13084962 C5.91759963,4.13084962 5.8511933,4.15819342 5.79650605,4.21288067 C5.7418188,4.26756792 5.71447499,4.34178661 5.71447499,4.43553639 Z"
            fill="#FF234E"
            id="icon-alert-red"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default Alert;
