import { colors } from '../../../constants/colors';

export interface Step {
  text: string;
  imageUrl: string;
  color: string;
}

export const steps: Step[] = [
  {
    text: 'Find top-rated care near you.',
    imageUrl: '/images/homepage/HowItWorks/step-1.png',
    color: colors.crunchBerry,
  },
  {
    text: 'Instantly book an in-person or video visit.',
    imageUrl: '/images/homepage/HowItWorks/step-2.png',
    color: colors.purpleHaze,
  },
  {
    text: 'Add your info to skip the paperwork.',
    imageUrl: '/images/homepage/HowItWorks/step-3.png',
    color: colors.mrBlueSky,
  },
  {
    text: 'Check-in on Solv and avoid a wait.',
    imageUrl: '/images/homepage/HowItWorks/step-4.png',
    color: colors.greenWeed,
  },
];
