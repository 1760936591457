import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../../../constants/colors';
import { fontSize } from '../../../../constants/text';
import { analyticsTrackEvent } from '../../../../core/analytics';
import { ACTION_BLUR, ACTION_FOCUS } from '../../../../core/analytics/events';
import { isMobile } from '../../../../core/util/device';
import { useClickOutside } from '../../../../hooks/useClickOutside';
import { AutoCompleteResult } from '../../AutocompleteResults';
import Field, { FieldDisplayProps } from '../inputs/Field';

const Input = styled.input`
  appearance: none;
  border: none;
  width: 100%;
  display: block;
  padding: 6px 0;
  background: none;
  font-size: ${fontSize.default};
  pointer-events: none;

  &:focus {
    outline: none;
  }
`;

const DialogInput = styled.input`
  display: block;
  padding: 15px 15px;
  border: solid 1px ${colors.theOtherGray};
  transition: border 0.15s;
  border-radius: 4px;
  appearance: none;

  &:focus {
    outline: 0;
    border-color: ${colors.mrBlueSky};
  }

  &::placeholder {
    color: ${colors.greyLock};
  }
`;

type Props = FieldDisplayProps & {
  /** The placeholder for the text field */
  placeholder: string;

  /** Callback to change the value */
  onChange: (value: string) => void;

  /** Called when the field is blurred */
  onBlur?: () => void;

  /** Called when the field is focused */
  onFocus?: () => void;

  /** Called when the clear button is clicked */
  onClear?: () => void;

  /** The current avlue */
  value: string;

  /** A set of results to display while seraching */
  results: AutoCompleteResult[];

  /** Callback for the first the the field is activated or focused
   * (used to know when to show or hide collapsible fields on mobile) */
  setIsTouched?: () => void;

  /** Whether to select all content on focus */
  selectAllOnFocus?: boolean;

  /** The label to track for analytics */
  trackingLabel?: string;

  /** selctor for automated testing */
  dataTestId?: string;
};

/**
 * A text input field for the home page, which supports autocomplete while typing, and shows a full screen dialog of
 * results on mobile.
 */
const TextInput = ({
  placeholder,
  value,
  onChange,
  onFocus,
  onBlur,
  onClear,
  results,
  setIsTouched,
  selectAllOnFocus,
  trackingLabel,
  dataTestId,
  ...fieldProps
}: Props) => {
  const [focused, setFocused] = useState(false);
  const [showResults, setShowResults] = useState(false);

  const fieldRef = useRef(null);
  useClickOutside(fieldRef, () => {
    setShowResults(false);
  });

  const handleChange = (value: any) => {
    setShowResults(false);
    onChange(value);
  };

  // Detect first touch/focus
  useEffect(() => {
    if (focused && setIsTouched) {
      setIsTouched();
    }
  }, [focused, setIsTouched]);

  /**
   *
   */
  function generateClickHandler(result: AutoCompleteResult) {
    const clickHandler = result.onClick;
    if (clickHandler !== undefined) {
      return () => {
        setShowResults(false);
        clickHandler();
      };
    }

    return () => {
      setShowResults(false);
    };
  }

  const mappedResults = results.map((result) => ({
    ...result,
    onClick: generateClickHandler(result),
  }));

  const handleFocus = useCallback(
    (e) => {
      onFocus?.();
      setShowResults(true);
      setFocused(true);
      if (selectAllOnFocus) {
        e.target.select();
      }

      if (trackingLabel) {
        analyticsTrackEvent(trackingLabel, { action: ACTION_FOCUS, isMobile: isMobile() });
      }
    },
    [onFocus, selectAllOnFocus, trackingLabel]
  );

  const handleBlur = useCallback(() => {
    onBlur?.();
    setFocused(false);
    if (trackingLabel) {
      analyticsTrackEvent(trackingLabel, { action: ACTION_BLUR, isMobile: isMobile() });
    }
  }, [onBlur, trackingLabel]);

  // Input ref used to manually trigger Blur action
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Field
      {...fieldProps}
      blur={() => inputRef.current?.blur()}
      dataTestId={dataTestId}
      dialogInput={
        <DialogInput
          autoFocus
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          value={value}
        />
      }
      isFocused={focused}
      onChange={handleChange}
      ref={fieldRef}
      results={mappedResults}
      showResults={showResults && !!results.length}
    >
      <Input
        data-testid={`${dataTestId}-input`}
        onBlur={handleBlur}
        onChange={(e) => onChange(e.target.value)}
        onFocus={handleFocus}
        placeholder={placeholder}
        ref={inputRef}
        value={value}
      />
    </Field>
  );
};

export default TextInput;
