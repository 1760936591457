import { RefObject, useEffect } from 'react';

/**
 * Triggers a callback when anything _outside_ a ref is clicked.
 *
 * @param ref The ref object to detect clicks outside of
 * @param callback The callback to trigger when clicked
 */
export function useClickOutside(ref: RefObject<any>, callback: () => void) {
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [callback, ref]);
}
