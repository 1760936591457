import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import { fontFamily, fontSize } from '../../../constants/text';
import { smallerThan, biggerThan } from '../../../core/util/styledComponents';
import { HomepageSearchType } from '../../../routes/home/Homepage';
import HomepageSearch from '../../UniversalSearch/Homepage';
import Background from './Background';
import Tabs from './Tabs';

const Root = styled(motion.div)`
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  z-index: 10;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 0;
  ${smallerThan.md`
    padding: 40px 0;
  `}
  > *:not(:last-child) {
    margin-bottom: 25px;
  }
`;

const TabsContainer = styled.div`
  align-self: center;
  max-width: 100%;
`;

const UniversalSearchContainer = styled.div`
  padding: 0 10px;
`;

const TextContainer = styled.div`
  text-align: center;
  max-width: 1000px;
  ${smallerThan.lg`
    max-width: 650px;
  `}
  margin: 0 auto;
  z-index: 9;
  /* ensures that the height of the hero background does not change */
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0px;
  right: 0px;
`;

const Header = styled.h1`
  margin: 0;
  padding: 0;
  font-size: ${fontSize.wowThatsHuge};
  font-family: ${fontFamily.bold};
  color: black;
  text-align: center;
  margin-bottom: 15px;
  z-index: 10;

  ${smallerThan.lg`
    font-size: ${fontSize.hellzaLarge};
  `}

  ${smallerThan.md`
    font-size: ${fontSize.hellaLarge};
  `}

  ${smallerThan.sm`
    font-size: ${fontSize.extraExtraLarge};
    padding-left: 2px; 
    padding-right: 2px; 
  `}
`;

const Subtitle = styled.p`
  margin: 0;
  padding: 0;
  font-size: ${fontSize.quiteExtraLarge};
  font-family: ${fontFamily.book};
  color: black;
  text-align: center;
  z-index: 10;

  ${smallerThan.lg`
    font-size: ${fontSize.large};
  `}

  ${smallerThan.sm`
    font-size: ${fontSize.default};
    padding-left: 4px; 
    padding-right: 4px;
  `}
`;

// These breaks are for optimizing line breaks around punctuation
// Without them, the copy can break an unintuive ways depending on the screen size
const InPersonHeaderBreak = styled.br`
  @media (max-width: 380px) {
    display: none;
  }
`;

const MobileBreak = styled.br`
  ${biggerThan.sm`
    display: none;
  `}
`;

type TabCopy = {
  header: React.ReactNode;
  subtitle: React.ReactNode;
};

const tabCopy: Record<string, TabCopy> = {
  inPerson: {
    header: (
      <>
        Feel better faster,
        <InPersonHeaderBreak /> from the clinic or your couch
      </>
    ),
    subtitle: (
      <>
        Find high quality, <MobileBreak /> same-day care for you and your kids.{' '}
      </>
    ),
  },
  labTest: {
    header: 'Quick and easy lab tests',
    subtitle: 'Book same-day blood draws, STI tests and more.',
  },
  covid: {
    header: (
      <>
        COVID or cold? <MobileBreak /> Find out fast
      </>
    ),
    subtitle: 'Book same-day tests for you and your family.',
  },
  video: {
    header: 'Get quality care without leaving your comfy couch',
    subtitle: 'Book same-day video visits for you and your kids.',
  },
};

type Props = {
  currentSearchType: HomepageSearchType;
  setCurrentSearchType: (t: HomepageSearchType) => void;
};

/**
 * The hero section of the homepage, which give users a place to search for various kinds of bookings.
 */
const Hero = ({ currentSearchType, setCurrentSearchType }: Props) => {
  return (
    <Root>
      <SearchContainer>
        <TabsContainer>
          <Tabs
            onChange={(selected) => {
              setCurrentSearchType(selected as HomepageSearchType);
            }}
            selectedTab={currentSearchType}
            tabs={[
              { title: 'In Person Care', value: 'inPerson', compactTitle: 'In Person' },
              { title: 'COVID-19 Test', value: 'covid', compactTitle: 'COVID Test' },
              { title: 'Lab Test', value: 'labTest', compactTitle: 'Lab Test' },
              { title: 'Video Visit', value: 'video', compactTitle: 'Video' },
            ]}
          />
        </TabsContainer>
        <UniversalSearchContainer>
          <HomepageSearch type={currentSearchType} />
        </UniversalSearchContainer>
      </SearchContainer>
      <div>
        <TextContainer>
          <Header>{tabCopy[currentSearchType]?.header || tabCopy.inPerson.header}</Header>
          <Subtitle>{tabCopy[currentSearchType]?.subtitle || tabCopy.inPerson.subtitle}</Subtitle>
        </TextContainer>
        <Background type={currentSearchType} />
      </div>
    </Root>
  );
};

export default Hero;
