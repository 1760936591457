import styled from 'styled-components';
import { colors } from '../../constants/colors';
import { fontFamily, fontSize } from '../../constants/text';
import { smallerThan } from '../../core/util/styledComponents';

/**
 * Title of a homepage section.
 */
const SectionTitle = styled.h2`
  font-family: ${fontFamily.medium};
  font-size: ${fontSize.damnLarge};
  line-height: 52px;
  color: ${colors.darkText};
  text-align: center;

  ${smallerThan.tablet`
    font-size: ${fontSize.large};
    line-height: 35px;
  `}
`;

export default SectionTitle;
