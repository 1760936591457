interface HSL {
  hue: number;
  saturation: number;
  lightness: number;
}

/**
 * Converts Hex to HSLA
 * Adapted from https://css-tricks.com/converting-color-spaces-in-javascript/
 *
 * @param hexString  The hex string to convert
 * @param options.deltaChange HSL values to add to existing parsed values
 * @param options.override Override any HSL or Alpha value
 * @returns A css `hsla` string
 */
export function hexToHSLA(
  hexString: string,
  options?: {
    deltaChange?: Partial<HSL>;
    override?: Partial<HSL> | { alpha?: number };
  }
) {
  // Convert hex to RGB first
  let r = 0;
  let g = 0;
  let b = 0;

  if (hexString.length === 4) {
    r = parseInt(`${hexString[1]}${hexString[1]}`, 16);
    g = parseInt(`${hexString[2]}${hexString[2]}`, 16);
    b = parseInt(`${hexString[3]}${hexString[3]}`, 16);
  } else if (hexString.length === 7) {
    r = parseInt(`${hexString[1]}${hexString[2]}`, 16);
    g = parseInt(`${hexString[3]}${hexString[4]}`, 16);
    b = parseInt(`${hexString[5]}${hexString[6]}`, 16);
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r, g, b);
  let cmax = Math.max(r, g, b);
  let delta = cmax - cmin;
  let h = 0;
  let s = 0;
  let l = 0;

  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  // Modify from change object
  const dh = options?.deltaChange?.hue || 0;
  const ds = options?.deltaChange?.saturation || 0;
  const dl = options?.deltaChange?.lightness || 0;

  const final = {
    hue: h + dh,
    saturation: s + ds,
    lightness: l + dl,
    alpha: 1,
    ...(options?.override || {}),
  };

  return `hsla(${final.hue},${final.saturation}%,${final.lightness}%, ${final.alpha})`;
}
