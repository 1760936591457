import { ArrowForwardRounded } from '@material-ui/icons';
import { Box, Link, palette, Stack, Text } from '@solvhealth/jigsaw';
import React from 'react';
import { isEmptyObject } from '../../core/util/object';

const pastBooking = (pastBookings: any) => {
  if (isEmptyObject(pastBookings)) {
    return null;
  }

  let lastBooking = null;
  for (const bookingId in pastBookings) {
    if (pastBookings.hasOwnProperty(bookingId)) {
      lastBooking = pastBookings[bookingId];
    }
  }

  return lastBooking;
};

type PastBookingNoticeProps = {
  pastBookings?: any;
};

const PastBookingNotice = (props: PastBookingNoticeProps) => {
  const booking = pastBooking(props.pastBookings);

  if (!booking) {
    return null;
  }

  return (
    <Box
      bg="karl"
      borderBottom="solid 1px"
      borderBottomColor="gray-200"
      display="flex"
      justifyContent="center"
      py={2}
    >
      <Stack alignItems="center" row space={1}>
        <Text muted>Hi! Looks like you have</Text>
        <Link
          endIcon={
            <Stack justifyContent="center">
              <ArrowForwardRounded fontSize="small" htmlColor={palette.crunchberry.light} />
            </Stack>
          }
          href={`/pass/${booking.id}`}
          inline
          sx={{
            '--focusable-offset-color': palette.karl,
          }}
        >
          a visit coming up
        </Link>
      </Stack>
    </Box>
  );
};

export default PastBookingNotice;
