import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
import { TouchableInputProps } from '.';
import { setUserLocation } from '~/actions/searchPreferences';
import { analyticsTrackEvent } from '~/core/analytics';
import { ACTION_CLEAR_VALUE, UNIVERSAL_LOCATION_INPUT } from '~/core/analytics/events';
import { isMobile } from '~/core/util/device';
import { biggerThan } from '~/core/util/styledComponents';
import { useLocationAutocomplete } from '~/hooks/geo/googleMaps';
import { PositionTrigger, useUserPosition } from '~/hooks/geo/useUserPosition';
import { AutoCompleteResult } from '../AutocompleteResults';
import { FieldDisplayProps } from './inputs/Field';
import TextInput from './inputs/TextInput';
import { stripCountry } from '~/components/GeoAutoComplete/util';
import dataTestIds from '../../../test/test-ids';
import { useBestGuessUserLocation } from '~/components/UniversalSearch/Homepage/hooks/useBestGuessUserLocation';

const GpsIcon = styled.img`
  width: 13px;
  height: 13px;
  margin-left: 10px;
  ${biggerThan.sm`
    margin-right: 10px;
    margin-left: 0;
  `}
`;

const PoweredByGoogle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & img {
    width: 80px;
    padding-right: 10px;
  }
`;

type Props = TouchableInputProps & Omit<FieldDisplayProps, 'label'>;

/**
 * Homepage input which supports autocomplete for cities, streets, and zip codes.
 */
const LocationInput = ({ touched, ...inputProps }: Props) => {
  // GPS or IP location
  const { requestPosition } = useUserPosition();

  const locationLabel = useBestGuessUserLocation()?.label || '';

  const { value, setValue, data, clearSuggestions } = useLocationAutocomplete(locationLabel);

  // Sets the text in the input to be our location label any time it has changed
  // outside of typing in the input
  useEffect(() => {
    setValue(locationLabel, false);
  }, [locationLabel, setValue]);

  const dispatch = useDispatch();

  // Handle a google maps location being selected
  const handleSelect = useCallback(
    (prediction: google.maps.places.AutocompletePrediction) => {
      const label = stripCountry(prediction.description);
      setValue(label, false);
      clearSuggestions();

      getGeocode({ address: prediction.description })
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          dispatch(
            setUserLocation({
              latitude: lat,
              longitude: lng,
              label,
              cityLabel: prediction.structured_formatting.main_text,
            })
          );
        });
    },
    [clearSuggestions, dispatch, setValue]
  );

  const results = useMemo<AutoCompleteResult[]>(() => {
    return [
      {
        key: 'current',
        primary: 'Use Current Location',
        onClick: () => {
          requestPosition(PositionTrigger.AccurateSearch);
        },
        icon: <GpsIcon src="/images/icons/search-icons/Icon-Search-GPS.svg" />,
      },
      ...data.map((d) => ({
        key: d.description,
        primary: d.structured_formatting.main_text,
        secondary: d.structured_formatting.secondary_text,
        onClick: () => handleSelect(d),
      })),
      {
        key: 'google',
        primary: 'Powered by Google',
        primaryNode: (
          <PoweredByGoogle>
            <img alt="powered by google" src="/images/homepage/powered-by-google.png" />
          </PoweredByGoogle>
        ),
        static: true,
        narrow: true,
      },
    ];
  }, [data, handleSelect, requestPosition]);

  const handleClear = useCallback(() => {
    analyticsTrackEvent(UNIVERSAL_LOCATION_INPUT, {
      action: ACTION_CLEAR_VALUE,
      isMobile: isMobile(),
    });
    // TODO: Add clear functionality
  }, []);

  return (
    <TextInput
      dataTestId={dataTestIds.homePage.locationSearch}
      label="Where?"
      onChange={setValue}
      onClear={handleClear}
      placeholder="City, street, or zip"
      results={results}
      selectAllOnFocus
      setIsTouched={touched}
      trackingLabel={UNIVERSAL_LOCATION_INPUT}
      value={value}
      {...inputProps}
    />
  );
};

export default LocationInput;
