import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { fontFamily, fontSize } from '../../constants/text';
import { colors } from '../../constants/colors';
import { smallerThan } from '../../core/util/styledComponents';
import SectionTitle from './SectionTitle';

const Root = styled.div`
  text-align: center;

  ${smallerThan.tablet`
    padding: 0 15px;
  `}
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  max-width: 1000px;
  margin: 20px auto;
`;

const Card = styled.div`
  flex-shrink: 0;
  flex-grow: 1;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 20px;
  max-width: 300px;
`;

const CardImage = styled.img`
  display: block;
  height: 150px;
  margin: 0 auto;
`;

const CardTitle = styled.p`
  font-family: ${fontFamily.bold};
  font-size: ${fontSize.medium};
  color: ${colors.squid};
  margin: 23px auto 14px;
`;

const CardCopy = styled.p`
  font-family: ${fontFamily.book};
  font-size: ${fontSize.default};
  color: ${colors.blueMonday};
`;

const BenefitsSection = () => {
  return (
    <Root>
      <SectionTitle>Get care now, on your phone or in person</SectionTitle>

      <CardContainer>
        <Card>
          <CardImage
            alt="No Paper Forms"
            loading="lazy"
            src="/images/homepage/Benefits/laptop-provider.svg"
          />
          <CardTitle>Care ASAP, 24/7</CardTitle>
          <CardCopy>
            On your phone or in your neighborhood, whenever you need it (like right now)
          </CardCopy>
        </Card>
        <Card>
          <CardImage
            alt="Near You"
            loading="lazy"
            src="/images/homepage/Benefits/self-pay-spot.svg"
          />
          <CardTitle>Clear self-pay prices</CardTitle>
          <CardCopy>See the Solv ClearPrice™ ahead of time, no surprises</CardCopy>
        </Card>

        <Card>
          <CardImage
            alt="Video Visit"
            loading="lazy"
            src="/images/homepage/Benefits/quick-easy-spot.svg"
          />
          <CardTitle>Quick &amp; easy</CardTitle>
          <CardCopy>Book care for you &amp; your family in just a few taps</CardCopy>
        </Card>
      </CardContainer>
    </Root>
  );
};

export default BenefitsSection;
