import Dialog from '@material-ui/core/Dialog';
import { motion } from 'framer-motion';
import React, { forwardRef, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../../../constants/colors';
import { fontFamily, fontSize } from '../../../../constants/text';
import { smallerThan } from '../../../../core/util/styledComponents';
import { useBreakpoint } from '../../../../hooks/useBreakpoint';
import { ForwardRefProps } from '../../../util/ForwardRefProps';
import { AutoCompleteResult } from '../../AutocompleteResults';
import Header from '../../Header';
import Results from '../Results';

const Root = styled(motion.div)<{ $width?: string }>`
  display: flex;
  align-items: center;
  justify-content: stretch;
  flex: 1;
  transition: all 0.15s;
  border-radius: var(--search-border-radius);
  position: relative;
  border: solid 0px white;
  max-width: ${(props) => props.$width ?? 'none'};

  ${smallerThan.md`
    flex-direction: column;
    align-items: stretch;
    max-width: none;

  `}

  /** This targets the divider of the current field AND the next field and hides it.  */
  &:hover + .field .divider,
  &:hover + * > .field .divider,
  &:hover .divider,
  &:focus-within .divider,
  &:focus-within + .field .divider,
  &:focus-within + * > .field .divider {
    background: transparent;
  }

  &:hover {
    background: ${colors.fiveSevenNineGrey};
  }

  &:focus-within {
    background: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
`;

const Label = styled.label`
  padding: 10px 40px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex: 1;
  z-index: 100;

  ${smallerThan.md`
    padding: 10px 25px;
  `}
`;

const Title = styled.span`
  font-size: ${fontSize.mediumSmall};
  color: ${colors.darkText};
  font-family: ${fontFamily.medium};
`;

const ResultsWrap = styled.div`
  position: absolute;
  background: white;
  bottom: -10px;
  left: 0;
  width: 100%;
  border-radius: var(--search-border-radius) var(--search-border-radius);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  transform: translateY(100%);
`;
const DialogContainer = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
`;

const Divider = styled.div`
  width: 1px;
  height: 40px;
  background: ${colors.onTheBorderline};
  flex-shrink: 0;
  flex-grow: 0;
  align-self: center;
  margin-left: 0;
  z-index: 0;
  transition: background 0.15s;

  ${smallerThan.md`
    margin: 0;
    height: 1px;
    width: 100%;
  `}
`;

export type FieldDisplayProps = {
  /** The visible title of the field */
  label: string;

  /** Whether to hide the divider, displayed BEFORE the field by default
   * (This is done because we want to hide the divider both before AND after
   * a given field when it is hovered or focused. Because CSS selectors only
   * support subsequent siblings [+ and ~], we therefore have to have each field
   * place its divider on its left, so we can select the next adjacent field with CSS
   * on hover or focus states.)
   */
  hideDivider?: boolean;

  /** Whether this field should be hidden by default on mobile. */
  collapseOnMobile?: boolean;

  /** A maximum width this field should be constrained to (on desktop) */
  constrainToWidth?: string;
};

type Props = {
  /** The input element itself */
  children: ReactNode;

  /** List of items to display as results or options */
  results?: AutoCompleteResult[];

  /**
   * Whether to show the results dropdown (*not* the full-screen dialog).
   * Only applies on non-mobile screens.
   */
  showResults?: boolean;

  /** Callback to update the value (when selecting a result) */
  onChange: (value: any) => void;

  /** A call to forcibly blur the field's input or tell the parent input to close its results. */
  blur: () => void;

  /** Whether the current input is focused or should be active. */
  isFocused?: boolean;

  /** An optional input element to dispaly in the full screen dialog, if necessary */
  dialogInput?: ReactNode;

  /** selector for automated tests */
  dataTestId?: string;
} & FieldDisplayProps;

/**
 * A field for the homepage search with a consistent display style. Supports showing a dropdown
 * either of results or items to select. Also supports showing as a full-screen dialog when on
 * mobile.
 */
const Field = (
  {
    label,
    children,
    results,
    showResults,
    onChange,
    isFocused,
    blur,
    dialogInput,
    hideDivider,
    collapseOnMobile,
    constrainToWidth,
    dataTestId,
  }: Props,
  ref: ForwardRefProps<any>
) => {
  const isMobile = useBreakpoint('md', 'below');
  const [showDialog, setShowDialog] = useState(false);
  useEffect(() => {
    if (isMobile && isFocused && !showDialog) {
      setShowDialog(true);
      blur();
    }
    // We only want to use isFocused as an ad-hoc onClick trigger:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused, isMobile]);

  /**
   *
   */
  function close() {
    setShowDialog(false);
  }

  // Bind a click handler to each result
  const resultsWithActions = results?.map((r) => ({
    ...r,
    onClick: () => {
      close();
      if (r.onClick) r.onClick();
      else onChange(r.key);
    },
  }));

  return (
    <>
      <Root
        $width={constrainToWidth}
        animate={{ opacity: 1 }}
        className={`field ${collapseOnMobile ? 'expandable' : ''}`}
        data-testid={dataTestId}
        initial={{ opacity: 0 }}
        key={label}
        ref={ref}
      >
        {!hideDivider && <Divider className="divider" />}
        <Label>
          <Title>{label}</Title>
          {children}
        </Label>

        {showResults && !isMobile && (
          <ResultsWrap>
            <Results results={resultsWithActions} />
          </ResultsWrap>
        )}
      </Root>

      <Dialog fullScreen onClose={close} open={showDialog && isMobile}>
        <DialogContainer>
          <Header onCancel={close} onConfirm={close} title={label} />
          {dialogInput}
        </DialogContainer>
        <Results results={resultsWithActions} />
      </Dialog>
    </>
  );
};

export default forwardRef(Field);
