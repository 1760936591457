import React, { forwardRef, MutableRefObject, RefObject } from 'react';
import styled, { css } from 'styled-components';
import { TabItem } from '.';
import { colors } from '../../../../constants/colors';
import { fontFamily, fontSize } from '../../../../constants/text';
import { smallerThan } from '../../../../core/util/styledComponents';
import { ForwardRefProps } from '../../../util/ForwardRefProps';

const TabRoot = styled.div<{ $active: boolean; $animating: boolean; $passive?: boolean }>`
  cursor: pointer;
  flex-shrink: 0;

  z-index: 10;
  padding: 10px 20px;
  white-space: nowrap;
  font-size: ${fontSize.default};
  font-family: ${fontFamily.medium};
  color: ${colors.darkText};
  border-radius: 10000px;

  > .sm {
    display: none;
  }

  ${smallerThan.sm`
    > .sm {
      display: inline;
    }

    > .lg {
      display: none;
    }
  `}

  ${smallerThan.sm`
    font-size: ${fontSize.mediumSmall};
    padding: 7px 15px;

    color: ${colors.darkText};
    background: white;
    border-radius: 1000px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  `}

  ${({ $active, $animating, $passive }) =>
    !$passive &&
    !$animating &&
    $active &&
    css`
      color: white !important;
      background: ${colors.darkText} !important;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    `}

    ${({ $passive }) =>
    $passive &&
    css`
      color: white;
      padding: 0;
    `}

  &:focus {
    outline: none;
  }
`;

type Props = {
  /** The tab data to render */
  tab: TabItem;

  /** Whether the tab is being used as a stand-in in the Pill component */
  passive?: boolean;

  /** Whether the tab is selected */
  active?: boolean;

  /** Whether the underline is animating currently */
  animating?: boolean;

  /** Handler for when the tab is clicked */
  onClick?: () => void;
};

/**
 * A single tab in our Tabs object.
 */
function Tab(
  { tab, onClick = () => ({}), passive = false, active = false, animating = false }: Props,
  ref: ForwardRefProps<any>
) {
  return (
    <TabRoot $active={active} $animating={animating} $passive={passive} onClick={onClick} ref={ref}>
      <span className="lg">{tab.title}</span>
      <span className="sm">{tab.compactTitle}</span>
    </TabRoot>
  );
}

export default forwardRef(Tab);
