import Hidden from '@material-ui/core/Hidden';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import { wrap } from 'popmotion';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';
import { analyticsTrackEvent } from '../../../core/analytics';
import { ACTION_HOW_IT_WORKS_SET_STEP, HOMEPAGE } from '../../../core/analytics/events';
import { smallerThan } from '../../../core/util/styledComponents';
import { useBreakpoint } from '../../../hooks/useBreakpoint';
import { useInterval } from '../../../hooks/useInterval';
import SectionTitle from '../SectionTitle';
import Badges from './components/Badges';
import Circles from './components/Circles';
import Steps from './components/Steps';
import { steps } from './steps';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
`;

const Title = styled.div`
  z-index: 10;
`;

const StepsTitle = styled(SectionTitle)`
  text-align: left;
  margin-bottom: 15px;
`;

const Content = styled.div`
  display: flex;

  ${smallerThan.md`
    flex-direction: column;
    align-items: center;
  `}
`;

const StepsContainer = styled.div`
  text-align: left;
  ${smallerThan.md`
    text-align: center;
  `}
`;

const ImageContainer = styled.div`
  position: relative;
  width: 350px;
  height: 450px;
  margin-right: 40px;

  ${smallerThan.md`
    margin-right: 0;
    margin: 20px 0;
  `}

  ${smallerThan.sm`
    width: 275px;
    height: 325px;
  `}
`;

const Image = styled(motion.img)`
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: contain;
  object-position: center;

  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
`;

const CirclesContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-45%, -5%);
  z-index: 0;
`;

const BadgesContainer = styled.div`
  margin-top: 40px;
`;

// Swipe configuration, makes it so that you can trigger a page change with
// a short, fast swipe, or a long, slow swipe.
const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

/**
 * Homepage section with an interactive demo of how the Solv booking flow works.
 */
const HowItWorksSection = () => {
  const [[page, direction], setPage] = useState([0, 0]);
  const step = wrap(0, steps.length, page);

  useEffect(() => {
    analyticsTrackEvent(HOMEPAGE, { action: ACTION_HOW_IT_WORKS_SET_STEP, step });
  }, [step]);

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };

  // Change slide every 5 seconds
  useInterval(
    () => {
      paginate(1);
    },
    5000,
    // Reset the interval when the step changes
    [page, direction]
  );

  const isCompactLayout = useBreakpoint('md', 'below');

  // We want the image to slide off the screen on mobile, but not on desktop.
  const animationOffset = isCompactLayout ? 1000 : 50;

  // Set each of our animation states, dependent on the direction
  const variants: Variants = useMemo(
    () => ({
      enter: (direction: number) => {
        return {
          x: direction > 0 ? animationOffset : -animationOffset,
          opacity: 0,
        };
      },
      center: {
        zIndex: 1,
        x: 0,
        opacity: 1,
      },
      exit: (direction: number) => {
        return {
          zIndex: 0,
          x: direction < 0 ? animationOffset : -animationOffset,
          opacity: 0,
        };
      },
    }),
    [animationOffset]
  );

  return (
    <Root>
      <Title>
        <Hidden mdUp>
          <SectionTitle>How it works</SectionTitle>
        </Hidden>
      </Title>
      <Content>
        <ImageContainer>
          <CirclesContainer>
            <Circles color={steps[step].color} />
          </CirclesContainer>
          <AnimatePresence custom={direction} initial={false}>
            <Image
              alt={steps[step].text}
              animate="center"
              className="image"
              custom={direction}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              exit="exit"
              initial="enter"
              key={steps[step].imageUrl}
              onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x);

                if (swipe < -swipeConfidenceThreshold) {
                  paginate(1);
                } else if (swipe > swipeConfidenceThreshold) {
                  paginate(-1);
                }
              }}
              src={steps[step].imageUrl}
              transition={{
                // Turn down the spring effect on mobile, it gets a little too extreme by default
                x: { type: 'spring', stiffness: 300, damping: isCompactLayout ? 30 : 20 },
                opacity: { duration: 0.2 },
              }}
              variants={variants}
            />
          </AnimatePresence>
        </ImageContainer>
        <StepsContainer>
          <Hidden smDown>
            <StepsTitle>How it works</StepsTitle>
          </Hidden>
          <Steps
            activeColor={colors.crunchBerry}
            selectedStep={step}
            setStep={(s) => setPage([s, s - step])}
            steps={steps}
          />
          <BadgesContainer>
            <Badges />
          </BadgesContainer>
        </StepsContainer>
      </Content>
    </Root>
  );
};

export default HowItWorksSection;
