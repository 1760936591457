import React, { useState } from 'react';
import styled from 'styled-components';
import { smallerThan } from '../../../core/util/styledComponents';
import { HomepageSearchType } from '../../../routes/home/Homepage';
import CovidForm from './forms/CovidForm';
import InPersonForm from './forms/InPersonForm';
import LabTestForm from './forms/LabTestForm';
import VideoForm from './forms/VideoForm';

const InputBackground = styled.div<{ $expanded: boolean }>`
  --search-border-radius: 20px;

  background: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: var(--search-border-radius);
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  text-align: left;
  align-self: stretch;

  ${smallerThan.md`
    align-items: stretch;

  `}

  @media (max-width: 960px) {
    .expandable {
      display: ${(props) => (props.$expanded ? 'block' : 'none')};
    }
    flex-direction: ${(props) => (props.$expanded ? 'column' : 'row')};
  }
`;

/** Props for components which bubble up whether their form has been touched. */
export type TouchableInputProps = {
  /** Whether the input has been touched */
  touched?: () => void;
};

type Props = {
  /** The type of search that should be shown */
  type: HomepageSearchType;
};

/**
 * The search bar for the homepage.
 */
const HomepageSearch = ({ type }: Props) => {
  const [expanded, setExpanded] = useState(false);

  const inPerson = type === 'inPerson';
  const covid = type === 'covid';
  const labTest = type === 'labTest';
  const video = type === 'video';

  const expand = () => setExpanded(true);

  return (
    <InputBackground $expanded={expanded}>
      {inPerson && <InPersonForm touched={expand} />}
      {covid && <CovidForm touched={expand} />}
      {labTest && <LabTestForm touched={expand} />}
      {video && <VideoForm touched={expand} />}
    </InputBackground>
  );
};

export default HomepageSearch;
