import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { Text } from '@solvhealth/jigsaw';
import { colors } from '~/constants/colors';
import { analyticsTrackEvent } from '~/core/analytics';
import { ACTION_BLUR, ACTION_CLICK } from '~/core/analytics/events';
import { isMobile } from '~/core/util/device';
import { useClickOutside } from '~/hooks/useClickOutside';
import Field, { FieldDisplayProps } from '../inputs/Field';

const Button = styled.button`
  appearance: none;
  border: none;
  background: transparent;
  margin: 0;
  text-align: left;
  width: 100%;
  display: block;
  padding: 6px 0;
  background: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:active {
    color: ${colors.darkText};
  }
`;

type Props = FieldDisplayProps & {
  /** The dropdown's options */
  options: { label: string; value: string; subLabel?: string }[];

  /** The currently selected value */
  value: any;

  /** Callback to change the value */
  onChange: (value: any) => void;

  /** Callback for when menu is opened or closed */
  onMenuVisibilityChange?: (shown: boolean) => void;

  /** Override the displayed label */
  labelOverride?: string;

  /** The label to use for tracking blur/click events */
  trackingLabel?: string;

  /** flag indicating that the displayed value is the default, not a user-selected value **/
  isDefaultSelection?: boolean;
};

/**
 * A dropdown field for the homepage search. Looks like the other text fields, but when clicked shows
 * either a list box (desktop) or a dialog (mobile) with options.
 */
const DropdownInput = ({
  options,
  value,
  onChange,
  labelOverride,
  onMenuVisibilityChange,
  trackingLabel,
  isDefaultSelection = false,
  ...fieldProps
}: Props) => {
  const [showMenu, setShowMenu] = useState(false);

  const handleChange = (v: any) => {
    setShowMenu(false);
    onChange(v);
  };

  const fieldRef = useRef();
  useClickOutside(fieldRef, () => {
    setShowMenu(false);
  });

  useEffect(() => {
    onMenuVisibilityChange?.(showMenu);
    if (trackingLabel) {
      const action = showMenu ? ACTION_CLICK : ACTION_BLUR;
      analyticsTrackEvent(trackingLabel, { action, isMobile: isMobile() });
    }
  }, [onMenuVisibilityChange, showMenu, trackingLabel]);

  // Find the label of the currently selected item
  const selectedItemLabel = useMemo(() => {
    return options?.find((o) => o.value === value)?.label ?? '';
  }, [options, value]);

  return (
    <Field
      {...fieldProps}
      blur={() => setShowMenu(false)}
      isFocused={showMenu}
      onChange={handleChange}
      ref={fieldRef}
      results={options.map((option) => ({
        primary: option.label,
        secondary: option.subLabel,
        key: option.value,
      }))}
      showResults={showMenu}
    >
      <Button
        onClick={() => {
          setShowMenu(!showMenu);
        }}
        tabIndex={0}
      >
        <Text muted={isDefaultSelection}>{labelOverride ?? selectedItemLabel}</Text>
      </Button>
    </Field>
  );
};

export default DropdownInput;
