import { Button, Stack, Text } from '@solvhealth/jigsaw';
import React, { useRef } from 'react';
import TriageIcon from '~/components/Home/TriageIcon';
import { analyticsTrackEvent } from '~/core/analytics';
import {
  TRIAGE_ENTRYPOINT_CLICKED,
  TRIAGE_ENTRYPOINT_HOMEPAGE,
  TRIAGE_ENTRYPOINT_SEEN,
} from '~/core/analytics/events';
import { RemixRoutes } from '~/core/remix';
import { useObserveVisibility } from '~/hooks/useObserveVisibility';

function TriageSection() {
  const ref = useRef();

  useObserveVisibility(ref, () => {
    analyticsTrackEvent(TRIAGE_ENTRYPOINT_SEEN, { entrypoint: TRIAGE_ENTRYPOINT_HOMEPAGE });
  });

  return (
    <div>
      <Stack
        alignItems="center"
        bg="grape-xlight"
        borderRadius="base"
        flexDirection={['column', 'row']}
        justifyContent="space-around"
        p={4}
        ref={ref}
        style={{ gap: '32px' }}
      >
        <TriageIcon />
        <Stack maxWidth={620} space={2}>
          <Stack space={0.5} sx={{ marginBottom: 4 }}>
            <Text as="h2" color="gray-600" sx={{ fontSize: '3xl' }} variant="h2">
              Not sure if you need care today?
            </Text>
            <Text fontSize="lg" sx={{ paddingTop: 2, lineHeight: 'xl' }}>
              Find out quickly what could be causing your symptoms, and get recommendations on what
              to do next. It’s fast, free, and confidential.
            </Text>
          </Stack>
          <Stack alignItems="start">
            <Button
              event={TRIAGE_ENTRYPOINT_CLICKED}
              external
              href={RemixRoutes.triage}
              meta={{ entrypoint: TRIAGE_ENTRYPOINT_HOMEPAGE }}
            >
              Start FREE symptom checker
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
}

export default TriageSection;
