import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { FETCH_UNIVERSAL_SEARCH_SUGGESTIONS } from '~/sagas/universalSearch';

export const useFetchSuggestions = () => {
  const dispatch = useDispatch();

  const fetchSuggestions = useCallback(
    (query: any, shouldIncludeLabServiceQuery: boolean, callback: any, resultsCallback: any) => {
      dispatch({
        type: FETCH_UNIVERSAL_SEARCH_SUGGESTIONS,
        query,
        shouldIncludeLabServiceQuery,
        callback,
        resultsCallback,
      });
    },
    [dispatch]
  );
  return fetchSuggestions;
};
