import { RefObject, useEffect } from 'react';

function getOffsetHelper(
  elem: HTMLElement,
  limit: HTMLElement | null,
  offsetType: 'offsetTop' | 'offsetLeft'
): number {
  if (!elem || elem === limit) return 0;
  const parent = elem.offsetParent;
  if (!(parent instanceof HTMLElement)) return elem[offsetType];
  return elem[offsetType] + getOffsetHelper(parent, limit, offsetType);
}

/**
 * Calculates the total top pixel offset between an element and either the viewport
 * or a bounding container element.
 *
 * @param elem The element to calcualte the offset of
 * @param limit The bounding element, or the viewport if null
 *
 * @returns The pixel offset of the element
 */
export function getOffsetTop(elem: HTMLElement, limit: HTMLElement | null = null): number {
  return getOffsetHelper(elem, limit, 'offsetTop');
}

/**
 * Calculates the total left pixel offset between an element and either the viewport
 * or a bounding container element.
 *
 * @param elem The element to calcualte the offset of
 * @param limit The bounding element, or the viewport if null
 *
 * @returns The pixel offset of the element
 */
export function getOffsetLeft(elem: HTMLElement, limit: HTMLElement | null = null): number {
  return getOffsetHelper(elem, limit, 'offsetLeft');
}

interface DirectionalScrollOptions {
  /** How many pixels of space to offset the scroll position by */
  offset?: number;

  /** An element to use the width/height of to offset scroll position */
  offsetElement?: RefObject<HTMLElement>;
}

interface UseScrollToRefOptions {
  /** Ref to an HTML Element to scroll within */
  scrollContainer?: RefObject<HTMLElement>;

  /** Options for horizontal scrolling */
  horizontal?: DirectionalScrollOptions & {
    align?: 'left' | 'right' | 'center';
  };

  /** Options for vertical scrolling */
  vertical?: DirectionalScrollOptions & { align?: 'top' | 'bottom' | 'center' };
}

/**
 * Will automatically scroll the window or a given container to the passed
 * element to scroll to any time that element changes.
 *
 * @param elementToScrollTo The element to scroll to
 * @param options Configuration for scroll behavior
 */
export function useScrollToElement(
  elementToScrollTo: HTMLElement | null,
  options: UseScrollToRefOptions = {}
) {
  useEffect(() => {
    // Do nothing for a null element
    if (!elementToScrollTo) return;

    // Figure out if we're scrolling in a container or the window.
    // Both support `.scroll()`
    const scrollingContainer = options.scrollContainer?.current || window;

    const scrollPosition = {
      top: 0,
      left: 0,
    };

    // Calculate Top Scroll Offset
    if (options.vertical) {
      const { offset, offsetElement, align = 'center' } = options.vertical;

      // Get the offset to the start of the element
      const offsetToScrollingContainer = getOffsetTop(
        elementToScrollTo,
        options.scrollContainer?.current
      );

      // Calculate the proper offset for our alignment
      const scrollContainerHeight =
        scrollingContainer === window
          ? window.innerHeight
          : (scrollingContainer as HTMLElement).offsetHeight;
      const targetElemHeight = elementToScrollTo.offsetHeight;

      const alignmentOffset = {
        top: 0,
        center: scrollContainerHeight / 2 - targetElemHeight / 2,
        bottom: scrollContainerHeight - targetElemHeight,
      }[align];

      // Calculate the final scroll position
      scrollPosition.top =
        offsetToScrollingContainer -
        (offsetElement?.current?.offsetHeight || 0) -
        (offset || 0) -
        alignmentOffset;
    }

    if (options.horizontal) {
      const { offset, offsetElement, align = 'center' } = options.horizontal;

      // Get the offset to the start of the element
      const offsetToScrollingContainer = getOffsetLeft(
        elementToScrollTo,
        options.scrollContainer?.current
      );

      // Calculate the proper offset for our alignment
      const scrollContainerWidth =
        scrollingContainer === window
          ? window.innerWidth
          : (scrollingContainer as HTMLElement).offsetWidth;
      const targetElemWidth = elementToScrollTo.offsetWidth;

      const alignmentOffset = {
        left: 0,
        center: scrollContainerWidth / 2 - targetElemWidth / 2,
        right: scrollContainerWidth - targetElemWidth,
      }[align];

      // Calculate the final scroll position
      scrollPosition.left =
        offsetToScrollingContainer -
        (offsetElement?.current?.offsetWidth || 0) -
        (offset || 0) -
        alignmentOffset;
    }

    // Execute the scroll
    scrollingContainer.scroll({ ...scrollPosition, behavior: 'smooth' });
  }, [elementToScrollTo, options]); // Trigger scroll when element or options change
}
