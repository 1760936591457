import React, { PureComponent } from 'react';
import Observer from 'react-intersection-observer';
import { ErrorWithCause } from 'pony-cause';
import loadPolyfills from '../../../core/util/polyfills';
import logger from '../../../core/logger/index';

type OwnProps = {
  handleSawComponent: (...args: any[]) => any;
  handleRenderComponent?: (...args: any[]) => any;
  threshold?: number;
};

type State = any;

type Props = OwnProps & typeof ObserverContainer.defaultProps;

class ObserverContainer extends PureComponent<Props, State> {
  static defaultProps = {
    threshold: 0,
  };

  componentHasUnmounted: any;

  state = {
    isObserverPolyfillLoaded: false,
    sectionVisited: false,
  };

  componentDidMount = () => {
    this.componentHasUnmounted = false; // just in case
    if (typeof this.props.handleRenderComponent === 'function') {
      this.props.handleRenderComponent();
    }

    if (typeof window !== 'undefined' && 'InteractionObserver' in window) {
      this.setState({ isObserverPolyfillLoaded: true });
    }

    loadPolyfills()
      .then(
        () =>
          !this.componentHasUnmounted &&
          this.setState({
            isObserverPolyfillLoaded: true,
          })
      )
      .catch((e) => {
        logger.error(
          new ErrorWithCause('Error loading Intersection Observer polyfill', { cause: e })
        );
      });
  };

  componentWillUnmount() {
    this.componentHasUnmounted = true;
  }

  shouldRender = () => {
    return this.state.isObserverPolyfillLoaded;
  };

  handleObserverChange = (inView: any) => {
    const { sectionVisited } = this.state;
    if (inView && !sectionVisited) {
      this.setState({
        sectionVisited: true,
      });

      if (typeof this.props.handleSawComponent === 'function') {
        this.props.handleSawComponent();
      }
    }
  };

  render() {
    const { children, threshold } = this.props;

    if (!this.shouldRender()) return null;

    return (
      <Observer onChange={this.handleObserverChange} threshold={threshold}>
        {children}
      </Observer>
    );
  }
}

export default ObserverContainer;
