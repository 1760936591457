import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Stack, Text } from '@solvhealth/jigsaw';
import { showModal, hideModal } from '../../actions/runtime';
import { colors } from '../../constants/colors';
import Alert from '../SolvPatternLibrary/Icons/Alert';
import {
  CRUCIAL_CARE_GROUP_ID,
  PRESTIGE_EMERGENCY_GROUP_ID,
  NIGHTLIGHT_PEDIATRIC_URGENT_CARE,
} from '../../config/locations';
import { isInGroup } from '../../core/util/location';
import Hidden from '../SolvDesignSystem/Hidden';
import CenteredDialog from '../SolvDesignSystem/Dialog/CenteredDialog';
import SlideInBottomDialog from '../SolvDesignSystem/Dialog/SlideInBottomDialog';
import { SolvButton } from '../SolvDesignSystem';

const A = styled.a`
  cursor: pointer;
  color: ${colors.blueJayWay};
`;

export const EMERGENCY_MODAL_ID = 'emergency';

const shouldDisableEmergencySymptomWarning = (location: any) => {
  if (!location) return false;
  return (
    isInGroup(location, CRUCIAL_CARE_GROUP_ID) ||
    isInGroup(location, PRESTIGE_EMERGENCY_GROUP_ID) ||
    isInGroup(location, NIGHTLIGHT_PEDIATRIC_URGENT_CARE)
  );
};

type EmergencyModalProps = {
  toggleEmergencyModal?: (...args: any[]) => any;
  open?: boolean;
  location?: any;
};

const EmergencyModal = ({ location, open, toggleEmergencyModal }: EmergencyModalProps) => {
  const closeModal = useCallback(() => toggleEmergencyModal?.(false), [toggleEmergencyModal]);

  if (shouldDisableEmergencySymptomWarning(location)) {
    return null;
  }

  const content = (
    <>
      <Stack alignItems="left" margin="auto" maxWidth={450} paddingBottom={5} space={1}>
        <Stack alignItems="center" row>
          <Alert />{' '}
          <Text color={colors.ladyInRed} variant="h3">
            WARNING
          </Text>
        </Stack>
        <Text textAlign="left" variant="h2">
          {' '}
          Possible Emergency
        </Text>
        <Text paddingBottom={2}>
          Your reason for visit could be a sign of a life threatening emergency. Please review the{' '}
          <A href="http://www.911.gov/">guidelines for calling 911</A> and consider visiting the
          nearest Emergency Room.
        </Text>
        <SolvButton color="primary" fullWidth onClick={closeModal} variant="contained">
          OK
        </SolvButton>
      </Stack>
    </>
  );

  return (
    <>
      <Hidden only={['xs']}>
        <CenteredDialog onClose={closeModal} open={!!open}>
          {content}
        </CenteredDialog>
      </Hidden>
      <Hidden only={['sm', 'md', 'lg', 'xl']}>
        <SlideInBottomDialog onClose={closeModal} open={!!open} shouldOffsetBottomNav={false}>
          {content}
        </SlideInBottomDialog>
      </Hidden>
    </>
  );
};

export default connect(
  (state: any) => ({
    open: state.runtime.modal && !!state.runtime.modal[EMERGENCY_MODAL_ID],
  }),
  (dispatch: any) => ({
    toggleEmergencyModal: (value: any) =>
      dispatch((value ? showModal : hideModal)(EMERGENCY_MODAL_ID)),
  })
)(EmergencyModal);
