import { AnimatePresence, motion, Variants } from 'framer-motion';
import { wrap } from 'popmotion';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';
import { analyticsTrackEvent } from '../../../core/analytics';
import { ACTION_CHANGE_TESTIMONIAL, HOMEPAGE } from '../../../core/analytics/events';
import { smallerThan } from '../../../core/util/styledComponents';
import { useBreakpoint } from '../../../hooks/useBreakpoint';
import { useInterval } from '../../../hooks/useInterval';
import SectionTitle from '../SectionTitle';
import { testimonials } from './data';
import TestimonialCard from './TestimonialCard';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  box-sizing: border-box;

  ${smallerThan.tablet`
    padding: 0 20px;
  `}
`;

const Title = styled.div`
  margin-bottom: 24px;
`;

const CardContainer = styled.div`
  display: grid;
  width: 100%;
`;

const Steps = styled.div`
  margin-top: 30px;
  display: flex;

  > *:not(:last-child) {
    margin-right: 10px;
  }
`;

const Step = styled.button<{ $active: boolean }>`
  display: block;
  width: 12px;
  height: 12px;
  background: ${({ $active }) => ($active ? colors.crunchBerry : colors.greyWorm)};
  border-radius: 12px;
  appearance: none;
  cursor: pointer;
  border: 0;
  padding: 0;

  &:focus {
    outline: none;
  }
`;

// Swipe configuration, makes it so that you can trigger a page change with
// a short, fast swipe, or a long, slow swipe.
const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

/**
 * Homepage section with testimonials
 */
const SuccessStoriesSection = () => {
  const [[page, direction], setPage] = useState([0, 0]);
  const step = wrap(0, testimonials.length, page);

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };

  useEffect(() => {
    analyticsTrackEvent(HOMEPAGE, {
      action: ACTION_CHANGE_TESTIMONIAL,
      index: step,
      testimonial: testimonials[step].callout,
    });
  });

  // Change slide every 5 seconds
  useInterval(
    () => {
      paginate(1);
    },
    8000,
    // Reset the interval when the step changes
    [page, direction]
  );

  const isCompactLayout = useBreakpoint('md', 'below');

  // We want the image to slide off the screen on mobile, but not on desktop.
  const animationOffset = isCompactLayout ? 1000 : 50;

  // Set each of our animation states, dependent on the direction
  const variants: Variants = useMemo(
    () => ({
      enter: (direction: number) => {
        return {
          x: direction > 0 ? animationOffset : -animationOffset,
          opacity: 0,
        };
      },
      center: {
        zIndex: 1,
        x: 0,
        opacity: 1,
      },
      exit: (direction: number) => {
        return {
          zIndex: 0,
          x: direction < 0 ? animationOffset : -animationOffset,
          opacity: 0,
        };
      },
    }),
    [animationOffset]
  );

  return (
    <Root>
      <Title>
        <SectionTitle>See why 27M+ people have booked with Solv</SectionTitle>
      </Title>
      <CardContainer>
        <AnimatePresence custom={direction} initial={false}>
          <motion.div
            animate="center"
            custom={direction}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            exit="exit"
            initial="enter"
            key={testimonials[step].photo}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x);

              if (swipe < -swipeConfidenceThreshold) {
                paginate(1);
              } else if (swipe > swipeConfidenceThreshold) {
                paginate(-1);
              }
            }}
            style={{
              gridArea: '1 / 1 / 2 / 2',
            }}
            transition={{
              // Turn down the spring effect on mobile, it gets a little too extreme by default
              x: { type: 'spring', stiffness: 300, damping: isCompactLayout ? 30 : 20 },
              opacity: { duration: 0.2 },
            }}
            variants={variants}
          >
            <TestimonialCard testimonial={testimonials[step]}></TestimonialCard>
          </motion.div>
        </AnimatePresence>
      </CardContainer>
      <Steps>
        {testimonials.map((_, i) => (
          <Step $active={i === step} key={i} onClick={() => setPage([i, i - step])} />
        ))}
      </Steps>
    </Root>
  );
};

export default SuccessStoriesSection;
