import React from 'react';
import styled from 'styled-components';
import { campaignNames } from '../../../../core/analytics/events';
import AppStoreBadge from '../../../DownloadDriver/AppStoreBadge';

const BadgesContainer = styled.div`
  & > *:not(:last-child) {
    margin-right: 15px;
  }
`;

/**
 * App store and Play store badges
 */
const Badges = () => {
  return (
    <BadgesContainer>
      <AppStoreBadge campaignName={campaignNames.HOMEPAGE_HOW_IT_WORKS} newWindow store="ios" />
      <AppStoreBadge campaignName={campaignNames.HOMEPAGE_HOW_IT_WORKS} newWindow store="android" />
    </BadgesContainer>
  );
};

export default Badges;
