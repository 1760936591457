import React from 'react';
import styled from 'styled-components';
import { Text, Link } from '@solvhealth/jigsaw';
import { smallerThan } from '~/core/util/styledComponents';
import { colors } from '~/constants/colors';
import { fontFamily, fontSize } from '~/constants/text';

const RightPane = styled.div`
  flex-basis: 100%;
  width: 0;
  padding: 96px;
  ${smallerThan.lg`
      padding: 48px;
   `};
`;

const EmphasizedText = styled(Text)`
  color: ${colors.darkText};
  font-size: ${fontSize.mediumLarge};
  font-family: ${fontFamily.bold};
  line-height: 32px;
`;

const NormalText = styled(Text)`
  color: ${colors.darkText};
  font-family: ${fontFamily.regular};
  font-size: ${fontSize.mediumLarge};
  line-height: 32px;
`;

const Content = styled.div`
  max-width: 490px;
`;

const Title = styled.div`
  font-family: ${fontFamily.medium};
  font-size: ${fontSize.damnLarge};
  line-height: 48px;
  color: ${colors.darkText};
  text-align: left;
  margin: 0 0 40px;
`;

const LeftPane = styled.div`
  flex-basis: 100%;
  width: 0;
  background: url('/images/homepage/clear-prices-intro.svg') no-repeat center;
  background-size: contain;
  margin: 32px 0px 32px 0px;
`;

const Root = styled.div`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-bottom: 24px;
  display: flex;
  background: linear-gradient(180deg, rgba(0, 213, 177, 0.11) 0%, rgba(0, 213, 177, 0) 100%);
  overflow: hidden;
`;

const StyledLink = styled(Link)`
  font-size: ${fontSize.mediumLarge};
  margin: 16px 0px 40px 0px;
  text-decoration: underline;
`;
type Props = {
  title: React.ReactElement;
  emphasizedText: string;
  mainText: string;
  learnMoreButtonLabel: string;
  handleLearnMoreButtonClick: () => void;
};
export const WidePricesSection = ({
  title,
  emphasizedText,
  mainText,
  learnMoreButtonLabel,
  handleLearnMoreButtonClick,
}: Props) => {
  return (
    <Root>
      <LeftPane />
      <RightPane>
        <Title>{title}</Title>
        <Content>
          <EmphasizedText>{emphasizedText}</EmphasizedText>
          <NormalText>{mainText}</NormalText>
        </Content>
        <StyledLink onClick={handleLearnMoreButtonClick}>{learnMoreButtonLabel}</StyledLink>
      </RightPane>
    </Root>
  );
};
