import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { clearUniversalSearchDoc, setDisplayQuery } from '~/ducks/universalSearch';

export const useHandleBlur = (value: string) => {
  const dispatch = useDispatch();

  const handleBlur = useCallback(() => {
    dispatch(setDisplayQuery(value));
    dispatch(clearUniversalSearchDoc());
  }, [dispatch, value]);

  return handleBlur;
};
