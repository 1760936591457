function supportsIntersectionObserver() {
  return (
    'IntersectionObserver' in global &&
    'IntersectionObserverEntry' in global &&
    'intersectionRatio' in IntersectionObserverEntry.prototype
  );
}

/**
 * Do feature detection, to figure out which polyfills needs to be imported.
 **/
export default () => {
  const polyfills = [];

  if (!supportsIntersectionObserver()) {
    polyfills.push(
      // @ts-ignore ts-migrate(7016) FIXME: Try `npm install @types/intersection-observer` if ... Remove this comment to see the full error message
      import(/* webpackChunkName: 'intersectionObserverPolyfill' */ 'intersection-observer')
    );
  }

  return Promise.all(polyfills);
};
