import React, { createRef, RefObject, useEffect, useMemo, useRef, useState, Fragment } from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import styled from 'styled-components';
import { Tab as HeadlessTab } from '@headlessui/react';
import keyBy from 'lodash/keyBy';
import { smallerThan } from '../../../../core/util/styledComponents';
import { useBreakpoint } from '../../../../hooks/useBreakpoint';
import { useScrollToElement } from '../../../../hooks/useScrollToElement';
import Pill from './Pill';
import Tab from './Tab';

const TabsRoot = styled(HeadlessTab.List)`
  position: relative;
  display: flex;
  background: white;
  border-radius: 1000px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  width: auto;

  ${smallerThan.sm`
      border-radius: 0;
      max-width: 100%;
      padding: 12px 12px;
      margin-top: -12px;
      margin-bottom: -12px;
      overflow-x: scroll;
    `}

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ${smallerThan.sm`
    background: none;
    box-shadow: none;

    > * {
      margin-right: 10px;
    }
  `}
`;

const TabContainer = styled.div`
  border-radius: 10000px;
`;

const Spacer = styled.div`
  height: 1px;
  width: 1px;
  background: transparent;
  flex-shrink: 0;
`;

export interface TabItem {
  /** The displayed title of the item */
  title: string;

  /** Compact title for small screens */
  compactTitle: string;

  /** The unique key of the item */
  value: string;
}

type Props = {
  /** A list of tabs to display */
  tabs: TabItem[];

  /** Optionally control currently selected tab */
  selectedTab: string;

  /** Called when a tab is selected */
  onChange?: (selected: string) => void;
};

/**
 * A tabs component for the homepage search toggle.
 */
function Tabs({ tabs, selectedTab, onChange = () => {} }: Props) {
  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  const ref = useRef(null);
  const [animating, setAnimating] = useState(false);
  const [direction, setDirection] = useState<1 | -1>(1);

  const tabsByValue = useMemo(() => keyBy(tabs, 'value'), [tabs]);
  const activeTab = tabsByValue[selectedTab];
  const activeIndex = tabs.findIndex((tab) => tab.value === selectedTab);

  // Store a ref for each tab
  const tabRefs: { [key: string]: RefObject<HTMLElement> } = useMemo(
    () => tabs.reduce((acc, tab) => ({ ...acc, [tab.value]: createRef() }), {}),
    [tabs]
  );

  // Horizontal overflow scrolling
  const activeTabElement = useMemo(() => tabRefs[selectedTab]?.current, [selectedTab, tabRefs]);

  useScrollToElement(activeTabElement, {
    scrollContainer: ref,
    horizontal: {
      align: 'center',
    },
  });

  const isSm = useBreakpoint('sm', 'below');

  const handleChangeTab = (index: number) => {
    const newDirection = index - activeIndex >= 0 ? 1 : -1;
    setDirection(newDirection);

    // Set our newly active tab and trigger callbacks
    onChange(tabs[index].value);
    setAnimating(!isSm);
  };

  return (
    <HeadlessTab.Group onChange={handleChangeTab} selectedIndex={activeIndex}>
      <HeadlessTab.List as={TabsRoot} ref={ref}>
        {tabs.map((tab, index) => (
          <HeadlessTab as={Fragment} key={tab.value}>
            <TabContainer onClick={(event) => event.preventDefault()}>
              <Tab
                active={tab.value === selectedTab}
                animating={animating}
                key={tab.value}
                onClick={() => handleChangeTab(index)}
                ref={tabRefs[tab.value]}
                tab={tab}
              />
            </TabContainer>
          </HeadlessTab>
        ))}
        <Spacer />
        <Pill
          activeTab={activeTab}
          animating={animating}
          direction={direction}
          finishAnimating={() => setAnimating(false)}
          refs={tabRefs}
        />
      </HeadlessTab.List>
    </HeadlessTab.Group>
  );
}

export default Tabs;
