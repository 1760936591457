export const corporationSchema = {
  '@context': 'https://schema.org',
  '@type': 'Corporation',
  name: 'Solv Health',
  legalName: 'Solv Health, Inc.',
  alternateName: 'Solv',
  url: 'https://www.solvhealth.com',
  '@id': 'https://www.solvhealth.com#Organization',
  logo: 'https://www.solvhealth.com/images/logo/solv_dark.svg',
  address: {
    '@type': 'PostalAddress',
    addressLocality: 'Oakland',
    addressRegion: 'California',
    postalCode: '94612',
    streetAddress: '1423 Broadway, Suite 312',
  },
  email: 'support@solvhealth.com',
  contactPoint: [
    {
      '@type': 'ContactPoint',
      telephone: '+1-214-382-9670',
      contactType: 'customer service',
      contactOption: 'TollFree',
      areaServed: 'US',
      availableLanguage: 'en',
    },
    {
      '@type': 'ContactPoint',
      telephone: '+1-214-382-9670',
      contactType: 'technical support',
      contactOption: 'TollFree',
      areaServed: 'US',
      availableLanguage: 'en',
    },
    {
      '@type': 'ContactPoint',
      telephone: '+1-707-653-6208',
      contactType: 'billing support',
      contactOption: 'TollFree',
      areaServed: 'US',
      availableLanguage: 'en',
    },
    {
      '@type': 'ContactPoint',
      telephone: '+1-707-653-6208',
      contactType: 'bill payment',
      contactOption: 'TollFree',
      areaServed: 'US',
      availableLanguage: 'en',
    },
  ],
  sameAs: [
    'https://www.facebook.com/solvhealth',
    'https://twitter.com/solvhealth',
    'https://www.instagram.com/solvhealth/',
    'https://www.linkedin.com/company/solvhealth',
    'https://www.solvhealth.com/for-providers',
    'https://www.crunchbase.com/organization/solv',
  ],
  founder: [
    {
      '@type': 'Person',
      name: 'Heather Mirjahangir Fernandez',
      sameAs: [
        'https://www.linkedin.com/in/heatherfernandez',
        'https://www.crunchbase.com/person/heather-mirjahangir',
        'https://twitter.com/heathermirj',
        'https://profiles.forbes.com/members/tech/profile/-Heather-Mirjahangir-Fernandez-CEO-Co-Founder-Solv/45e0d372-06c2-422c-8420-e8ed328115a2',
        'https://www.solvhealth.com/blog/people/heather-fernandez',
      ],
    },
    {
      '@type': 'Person',
      name: 'Daniele Farnedi',
      sameAs: [
        'https://www.linkedin.com/in/danielefarnedi',
        'https://www.crunchbase.com/person/daniele-farnedi',
        'https://twitter.com/dfarnedi',
      ],
    },
  ],
  foundingDate: '2016',
  knowsAbout: [
    'https://en.wikipedia.org/wiki/Urgent_care_center',
    'https://en.wikipedia.org/wiki/Healthcare_in_the_United_States',
  ],
};

export const websiteSchema = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  name: 'Solv Health',
  url: 'https://www.solvhealth.com',
  potentialAction: {
    '@type': 'SearchAction',
    target: 'https://www.solvhealth.com/search?reasonForVisit={search_term_string}',
    'query-input': 'required name=search_term_string',
  },
};
