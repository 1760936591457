interface Link {
  name: string;
  url: string;
}

export const urgentCareLocations: Link[] = [
  {
    name: 'Urgent Care in Houston, TX',
    url: 'https://www.solvhealth.com/tx/c/houston-tx-urgent-care',
  },
  {
    name: 'Urgent Care in San Antonio, TX',
    url: 'https://www.solvhealth.com/tx/c/san-antonio-tx-urgent-care',
  },
  {
    name: 'Urgent Care in New York, NY',
    url: 'https://www.solvhealth.com/ny/c/new-york-ny-urgent-care',
  },
  {
    name: 'Urgent Care in Chicago, IL',
    url: 'https://www.solvhealth.com/il/c/chicago-il-urgent-care',
  },
  {
    name: 'Urgent Care in Nashville, TN',
    url: 'https://www.solvhealth.com/tn/c/nashville-tn-urgent-care',
  },
  {
    name: 'Urgent Care in Tucson, AZ',
    url: 'https://www.solvhealth.com/az/c/tucson-az-urgent-care',
  },
  {
    name: 'Urgent Care in Dallas, TX',
    url: 'https://www.solvhealth.com/tx/c/dallas-tx-urgent-care',
  },
  {
    name: 'Urgent Care in Seattle, WA',
    url: 'https://www.solvhealth.com/wa/c/seattle-wa-urgent-care',
  },
  {
    name: 'Urgent Care in Oklahoma City, OK',
    url: 'https://www.solvhealth.com/ok/c/oklahoma-city-ok-urgent-care',
  },
  {
    name: 'Urgent Care in Detroit, MI',
    url: 'https://www.solvhealth.com/mi/c/detroit-mi-urgent-care',
  },
  {
    name: 'Urgent Care in Boston, MA',
    url: 'https://www.solvhealth.com/ma/c/boston-ma-urgent-care',
  },
  {
    name: 'Urgent Care in Phoenix, AZ',
    url: 'https://www.solvhealth.com/az/c/phoenix-az-urgent-care',
  },
  {
    name: 'Urgent Care in Columbia, SC',
    url: 'https://www.solvhealth.com/sc/c/columbia-sc-urgent-care',
  },
  {
    name: 'Urgent Care in Tacoma, WA',
    url: 'https://www.solvhealth.com/wa/c/tacoma-wa-urgent-care',
  },
  {
    name: 'Urgent Care in Myrtle-Beach, SC',
    url: 'https://www.solvhealth.com/sc/c/myrtle-beach-sc-urgent-care',
  },
  { name: 'Urgent Care in Reno, NV', url: 'https://www.solvhealth.com/nv/c/reno-nv-urgent-care' },
  {
    name: 'Urgent Care in Queens, NY',
    url: 'https://www.solvhealth.com/ny/c/queens-ny-urgent-care',
  },
  {
    name: 'Urgent Care in The-Villages, FL',
    url: 'https://www.solvhealth.com/fl/c/the-villages-fl-urgent-care',
  },
  { name: 'Urgent Care in Tulsa, OK', url: 'https://www.solvhealth.com/ok/c/tulsa-ok-urgent-care' },
  {
    name: 'Urgent Care in Brooklyn, NY',
    url: 'https://www.solvhealth.com/ny/c/brooklyn-ny-urgent-care',
  },
  {
    name: 'Urgent Care in Colorado-Springs, CO',
    url: 'https://www.solvhealth.com/co/c/colorado-springs-co-urgent-care',
  },
  {
    name: 'Urgent Care in Oklahoma-City, OK',
    url: 'https://www.solvhealth.com/ok/c/oklahoma-city-ok-urgent-care',
  },
  {
    name: 'Urgent Care in State-College, PA',
    url: 'https://www.solvhealth.com/pa/c/state-college-pa-urgent-care',
  },
  {
    name: 'Urgent Care in Los-Angeles, CA',
    url: 'https://www.solvhealth.com/ca/c/los-angeles-ca-urgent-care',
  },
  {
    name: 'Urgent Care in Las-Vegas, NV',
    url: 'https://www.solvhealth.com/nv/c/las-vegas-nv-urgent-care',
  },
  {
    name: 'Urgent Care in Fort-Worth, TX',
    url: 'https://www.solvhealth.com/tx/c/fort-worth-tx-urgent-care',
  },
  {
    name: 'Urgent Care in Syracuse, NY',
    url: 'https://www.solvhealth.com/ny/c/syracuse-ny-urgent-care',
  },
  {
    name: 'Urgent Care in Hilton-Head-Island, SC',
    url: 'https://www.solvhealth.com/sc/c/hilton-head-island-sc-urgent-care',
  },
  {
    name: 'Urgent Care in South-Padre-Island, TX',
    url: 'https://www.solvhealth.com/tx/c/south-padre-island-tx-urgent-care',
  },
  {
    name: 'Urgent Care in Wichita, KS',
    url: 'https://www.solvhealth.com/ks/c/wichita-ks-urgent-care',
  },
  {
    name: 'Urgent Care in Lubbock, TX',
    url: 'https://www.solvhealth.com/tx/c/lubbock-tx-urgent-care',
  },
  {
    name: 'Urgent Care in Naples, FL',
    url: 'https://www.solvhealth.com/fl/c/naples-fl-urgent-care',
  },
  {
    name: 'Urgent Care in West-Yellowstone, MT',
    url: 'https://www.solvhealth.com/mt/c/west-yellowstone-mt-urgent-care',
  },
  {
    name: 'Urgent Care in Scottsdale, AZ',
    url: 'https://www.solvhealth.com/az/c/scottsdale-az-urgent-care',
  },
  {
    name: 'Urgent Care in Philadelphia, PA',
    url: 'https://www.solvhealth.com/pa/c/philadelphia-pa-urgent-care',
  },
  {
    name: 'Urgent Care in Staten-Island, NY',
    url: 'https://www.solvhealth.com/ny/c/staten-island-ny-urgent-care',
  },
  {
    name: 'Urgent Care in Minneapolis, MN',
    url: 'https://www.solvhealth.com/mn/c/minneapolis-mn-urgent-care',
  },
  {
    name: 'Urgent Care in Washington, DC',
    url: 'https://www.solvhealth.com/dc/c/washington-dc-urgent-care',
  },
  {
    name: 'Urgent Care in Bethany-Beach, DE',
    url: 'https://www.solvhealth.com/de/c/bethany-beach-de-urgent-care',
  },
  {
    name: 'Urgent Care in Huntsville, AL',
    url: 'https://www.solvhealth.com/al/c/huntsville-al-urgent-care',
  },
  {
    name: 'Urgent Care in Rehoboth-Beach, DE',
    url: 'https://www.solvhealth.com/de/c/rehoboth-beach-de-urgent-care',
  },
  {
    name: 'Urgent Care in Breckenridge, CO',
    url: 'https://www.solvhealth.com/co/c/breckenridge-co-urgent-care',
  },
  {
    name: 'Urgent Care in Clarksville, TN',
    url: 'https://www.solvhealth.com/tn/c/clarksville-tn-urgent-care',
  },
];

export const urgentCareByState: Link[] = [
  { name: 'Alabama urgent care', url: 'https://www.solvhealth.com/al/s/alabama-urgent-care' },
  { name: 'Alaska urgent care', url: 'https://www.solvhealth.com/ak/s/alaska-urgent-care' },
  { name: 'Arizona urgent care', url: 'https://www.solvhealth.com/az/s/arizona-urgent-care' },
  { name: 'Arkansas urgent care', url: 'https://www.solvhealth.com/ar/s/arkansas-urgent-care' },
  { name: 'California urgent care', url: 'https://www.solvhealth.com/ca/s/california-urgent-care' },
  { name: 'Colorado urgent care', url: 'https://www.solvhealth.com/co/s/colorado-urgent-care' },
  {
    name: 'Connecticut urgent care',
    url: 'https://www.solvhealth.com/ct/s/connecticut-urgent-care',
  },
  { name: 'Delaware urgent care', url: 'https://www.solvhealth.com/de/s/delaware-urgent-care' },
  { name: 'Florida urgent care', url: 'https://www.solvhealth.com/fl/s/florida-urgent-care' },
  { name: 'Georgia urgent care', url: 'https://www.solvhealth.com/ga/s/georgia-urgent-care' },
  { name: 'Hawaii urgent care', url: 'https://www.solvhealth.com/hi/s/hawaii-urgent-care' },
  { name: 'Idaho urgent care', url: 'https://www.solvhealth.com/id/s/idaho-urgent-care' },
  { name: 'Illinois urgent care', url: 'https://www.solvhealth.com/il/s/illinois-urgent-care' },
  { name: 'Indiana urgent care', url: 'https://www.solvhealth.com/in/s/indiana-urgent-care' },
  { name: 'Iowa urgent care', url: 'https://www.solvhealth.com/ia/s/iowa-urgent-care' },
  { name: 'Kansas urgent care', url: 'https://www.solvhealth.com/ks/s/kansas-urgent-care' },
  { name: 'Kentucky urgent care', url: 'https://www.solvhealth.com/ky/s/kentucky-urgent-care' },
  { name: 'Louisiana urgent care', url: 'https://www.solvhealth.com/la/s/louisiana-urgent-care' },
  { name: 'Maine urgent care', url: 'https://www.solvhealth.com/me/s/maine-urgent-care' },
  { name: 'Maryland urgent care', url: 'https://www.solvhealth.com/md/s/maryland-urgent-care' },
  {
    name: 'Massachusetts urgent care',
    url: 'https://www.solvhealth.com/ma/s/massachusetts-urgent-care',
  },
  { name: 'Michigan urgent care', url: 'https://www.solvhealth.com/mi/s/michigan-urgent-care' },
  { name: 'Minnesota urgent care', url: 'https://www.solvhealth.com/mn/s/minnesota-urgent-care' },
  {
    name: 'Mississippi urgent care',
    url: 'https://www.solvhealth.com/ms/s/mississippi-urgent-care',
  },
  { name: 'Missouri urgent care', url: 'https://www.solvhealth.com/mo/s/missouri-urgent-care' },
  { name: 'Montana urgent care', url: 'https://www.solvhealth.com/mt/s/montana-urgent-care' },
  { name: 'Nebraska urgent care', url: 'https://www.solvhealth.com/ne/s/nebraska-urgent-care' },
  { name: 'Nevada urgent care', url: 'https://www.solvhealth.com/nv/s/nevada-urgent-care' },
  {
    name: 'New Hampshire urgent',
    url: 'care	https://www.solvhealth.com/nh/s/new-hampshire-urgent-care',
  },
  { name: 'New Jersey urgent care', url: 'https://www.solvhealth.com/nj/s/new-jersey-urgent-care' },
  { name: 'New Mexico urgent care', url: 'https://www.solvhealth.com/nm/s/new-mexico-urgent-care' },
  { name: 'New York urgent care	', url: 'https://www.solvhealth.com/ny/s/new-york-urgent-care' },
  {
    name: 'North Carolina urgent care',
    url: 'https://www.solvhealth.com/nc/s/north-carolina-urgent-care',
  },
  {
    name: 'North Dakota urgent care',
    url: 'https://www.solvhealth.com/nd/s/north-dakota-urgent-care',
  },
  { name: 'Ohio urgent care', url: 'https://www.solvhealth.com/oh/s/ohio-urgent-care' },
  { name: 'Oklahoma urgent care', url: 'https://www.solvhealth.com/ok/s/oklahoma-urgent-care' },
  { name: 'Oregon urgent care', url: 'https://www.solvhealth.com/or/s/oregon-urgent-care' },
  {
    name: 'Pennsylvania urgent care',
    url: 'https://www.solvhealth.com/pa/s/pennsylvania-urgent-care',
  },
  {
    name: 'Rhode Island urgent care',
    url: 'https://www.solvhealth.com/ri/s/rhode-island-urgent-care',
  },
  {
    name: 'South Carolina urgent care',
    url: 'https://www.solvhealth.com/sc/s/south-carolina-urgent-care',
  },
  {
    name: 'South Dakota urgent care',
    url: 'https://www.solvhealth.com/sd/s/south-dakota-urgent-care',
  },
  { name: 'Tennessee urgent care', url: 'https://www.solvhealth.com/tn/s/tennessee-urgent-care' },
  { name: 'Texas urgent care', url: 'https://www.solvhealth.com/tx/s/texas-urgent-care' },
  { name: 'Utah urgent care', url: 'https://www.solvhealth.com/ut/s/utah-urgent-care' },
  { name: 'Vermont urgent care', url: 'https://www.solvhealth.com/vt/s/vermont-urgent-care' },
  { name: 'Virginia urgent care', url: 'https://www.solvhealth.com/va/s/virginia-urgent-care' },
  { name: 'Washington urgent care', url: 'https://www.solvhealth.com/wa/s/washington-urgent-care' },
  {
    name: 'Washington, DC urgent care',
    url: 'https://www.solvhealth.com/dc/s/washington-dc-urgent-care',
  },
  {
    name: 'West Virginia urgent care',
    url: 'https://www.solvhealth.com/wv/s/west-virginia-urgent-care',
  },
  { name: 'Wisconsin urgent care', url: 'https://www.solvhealth.com/wi/s/wisconsin-urgent-care' },
  { name: 'Wyoming urgent care', url: 'https://www.solvhealth.com/wy/s/wyoming-urgent-care' },
  {
    name: 'Accredited urgent care',
    url: 'https://www.solvhealth.com/accredited-urgent-care-centers',
  },
  { name: 'Top urgent cares', url: 'https://www.solvhealth.com/company' },
  { name: 'Walk-in clinics', url: 'https://www.solvhealth.com/walk-in-clinic' },
];

export const labsAndServices: Link[] = [
  { name: 'A1C testing', url: 'https://www.solvhealth.com/srv/a1c-test' },
  { name: 'Allergy testing', url: 'https://www.solvhealth.com/srv/allergy-testing' },
  { name: 'Annual exam', url: 'https://www.solvhealth.com/srv/physical-exam' },
  { name: 'Blood test', url: 'https://www.solvhealth.com/srv/blood-test' },
  { name: 'BMP testing', url: 'https://www.solvhealth.com/srv/basic-metabolic-panel' },
  { name: 'Chickenpox vaccine', url: 'https://www.solvhealth.com/srv/chickenpox-vaccine' },
  { name: 'Chlamydia test', url: 'https://www.solvhealth.com/srv/chlamydia-test' },
  { name: 'Cholesterol testing', url: 'https://www.solvhealth.com/srv/cholesterol-test' },
  { name: 'CMP testing', url: 'https://www.solvhealth.com/srv/cmp-test' },
  { name: 'COVID antibody test', url: 'https://www.solvhealth.com/srv/covid-antibody-test' },
  { name: 'COVID pill', url: 'https://www.solvhealth.com/srv/covid-pill' },
  { name: 'COVID testing', url: 'https://www.solvhealth.com/covid-testing' },
  { name: 'COVID vaccine', url: 'https://www.solvhealth.com/covid-vaccine' },
  { name: 'Diabetes test', url: 'https://www.solvhealth.com/srv/diabetes-test' },
  { name: 'Diagnostic testing', url: 'https://www.solvhealth.com/srv/diagnostic-test' },
  { name: 'DNA testing', url: 'https://www.solvhealth.com/srv/dna-test' },
  { name: 'DOT exam physicals', url: 'https://www.solvhealth.com/srv/dot-physicals' },
  { name: 'Drug testing', url: 'https://www.solvhealth.com/srv/drug-test' },
  { name: 'Ear wax removal', url: 'https://www.solvhealth.com/srv/ear-wax-removal' },
  { name: 'Eye exam', url: 'https://www.solvhealth.com/srv/eye-exam' },
  { name: 'Flu shot', url: 'https://www.solvhealth.com/srv/flu-shot' },
  { name: 'Flu test', url: 'https://www.solvhealth.com/srv/flu-test' },
  { name: 'Food allergy testing', url: 'https://www.solvhealth.com/srv/food-allergy-testing' },
  { name: 'Glucose testing', url: 'https://www.solvhealth.com/srv/glucose-test' },
  { name: 'H pylori test', url: 'https://www.solvhealth.com/srv/h-pylori-test' },
  { name: 'Hepatitis vaccine', url: 'https://www.solvhealth.com/srv/hepatitis-vaccine' },
  { name: 'Herpes testing', url: 'https://www.solvhealth.com/srv/herpes-test' },
  { name: 'HIV testing', url: 'https://www.solvhealth.com/srv/hiv-test' },
  { name: 'Lab tests', url: 'https://www.solvhealth.com/srv/lab-tests' },
  { name: 'Measles vaccine', url: 'https://www.solvhealth.com/srv/measles-vaccine-mmr' },
  { name: 'Mono test', url: 'https://www.solvhealth.com/srv/mono-test' },
  { name: 'Pap smear', url: 'https://www.solvhealth.com/srv/pap-smear' },
  { name: 'Pediatric urgent care', url: 'https://www.solvhealth.com/pediatric-urgent-care' },
  { name: 'Pregnancy test', url: 'https://www.solvhealth.com/srv/pregnancy-test' },
  {
    name: 'Pulmonary function test',
    url: 'https://www.solvhealth.com/srv/pulmonary-function-test',
  },
  { name: 'RSV testing', url: 'https://www.solvhealth.com/srv/rsv-test' },
  { name: 'Shingles vaccine', url: 'https://www.solvhealth.com/srv/shingles-vaccine' },
  {
    name: 'Sinus infection treatment',
    url: 'https://www.solvhealth.com/srv/sinus-infection-treatment',
  },
  { name: 'Sports physical', url: 'https://www.solvhealth.com/srv/sports-physicals' },
  { name: 'STD testing', url: 'https://www.solvhealth.com/srv/std-test' },
  { name: 'Strep test', url: 'https://www.solvhealth.com/srv/strep-test' },
  { name: 'TB test', url: 'https://www.solvhealth.com/srv/tb-test' },
  { name: 'Tetanus vaccine', url: 'https://www.solvhealth.com/srv/tetanus-vaccine' },
  { name: 'Thyroid test', url: 'https://www.solvhealth.com/srv/thyroid-test' },
  { name: 'Trichomonas testing', url: 'https://www.solvhealth.com/srv/trichomonas-test' },
  { name: 'Typhoid vaccine', url: 'https://www.solvhealth.com/srv/typhoid-vaccine' },
  { name: 'Urinalysis', url: 'https://www.solvhealth.com/srv/urinalysis' },
  { name: 'VA urgent care', url: 'https://www.solvhealth.com/va-urgent-care' },
  { name: 'Vitamin D testing', url: 'https://www.solvhealth.com/srv/vitamin-d-test' },
  { name: 'Well-woman exam', url: 'https://www.solvhealth.com/srv/well-woman-exam' },
  { name: 'Yellow fever vaccine', url: 'https://www.solvhealth.com/srv/yellow-fever-vaccine' },
];

export const resources: Link[] = [
  { name: 'ClearPrice', url: 'https://www.solvhealth.com/clearprice' },
  { name: 'For Providers', url: 'https://www.solvhealth.com/for-providers' },
  { name: 'Health A-Z', url: 'https://www.solvhealth.com/health-a-z' },
  { name: 'In Good Health Blog', url: 'https://www.solvhealth.com/blog' },
  { name: 'SolvPlus', url: 'https://www.solvhealth.com/membership' },
  { name: 'Solv Mobile Apps', url: 'https://www.solvhealth.com/download' },
  { name: 'COVID Care Guide', url: 'https://www.solvhealth.com/covid19' },
];
