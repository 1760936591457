import React from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import Sticky from 'react-stickynode';
import { createGlobalStyle } from 'styled-components';
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { Text, Stack, Box, palette, BaseClickable } from '@solvhealth/jigsaw';
import { useTranslation } from 'react-i18next';
import history from '../../core/history';
import SiteMenu from '../SiteMenu';
import { isWideFormat } from '../../core/util/device/screen';
import Logo from '../Logo';
import { clearUserInfo } from '../../core/session';
import { resetLoginData } from '../../actions/login';
import { isNativeApp } from '../../core/util/device';
import SolvPlusLogo from '../Membership/SolvPlusLogo';
import { useSolvSelector } from '~/reducers';
import { useAuthentication } from '~/core/login/AuthenticationContext';
import { CloseIcon } from '~/components/SolvDesignSystem/Icons';

const LayoutDropShadow = createGlobalStyle<{ $hideDropShadow?: boolean }>`
  .sticky-outer-wrapper.active > .sticky-inner-wrapper {
    box-shadow: ${(props) => (props.$hideDropShadow ? 'none' : '0 1px 5px rgba(0, 0, 0, .1)')};
  }
`;

export const HEADER_THEME_TRANSPARENT = 'transparent';
const HEADER_THEME_WHITE = 'white';

type HeaderProps = {
  leftElement?: React.ReactElement;
  handleBackOnClick?: React.MouseEventHandler;
  handleCloseOnClick?: React.MouseEventHandler;
  handleLoginOnClick?: React.MouseEventHandler;
  hideDropShadow?: boolean;
  rightElement?: React.ReactElement;
  shortTitle?: string;
  showBackArrow?: boolean;
  showCloseX?: boolean;
  showCloseXRight?: boolean;
  logoutLink?: boolean;
  showCog?: boolean;
  showLogo?: boolean;
  showPlusLogo?: boolean;
  showLogin?: boolean;
  showSiteMenu?: boolean;
  sticky?: boolean;
  theme?: string;
  title?: string | React.ReactElement;
  resetLogin?: () => void;
  handleLogoOnClick?: React.MouseEventHandler;
  currentUserProfileId?: string;
};

const getHeaderBackground = (theme: string) => {
  if (theme === HEADER_THEME_TRANSPARENT) {
    return 'transparent';
  }

  return palette.default.white;
};

const Header = ({
  currentUserProfileId,
  theme = HEADER_THEME_WHITE,
  hideDropShadow,
  handleCloseOnClick = history.goBack,
  leftElement,
  showBackArrow = true,
  showCloseX,
  showPlusLogo,
  showLogo = true,
  handleLogoOnClick = () => history.push('/'),
  handleLoginOnClick = () => history.push('/account/login'),
  handleBackOnClick = history.goBack,
  showSiteMenu = true,
  showCloseXRight,
  showLogin = false,
  resetLogin,
  logoutLink,
  rightElement,
  shortTitle,
  title,
  showCog,
  sticky = true,
}: HeaderProps) => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const viewport = useSolvSelector((state) => state.runtime.viewport);
  const [, , remove] = useCookies();
  const { isLoggedIn } = useAuthentication();

  const getHeaderLeftElements = () => {
    const elements = [];

    if (leftElement) {
      return leftElement;
    }

    if (showBackArrow) {
      elements.push(
        <BaseClickable key="back-button" onClick={handleBackOnClick}>
          <img alt="back arrow" src="/images/icons/Interactive-Arrow.svg" />
        </BaseClickable>
      );
    }

    if (showCloseX) {
      elements.push(<CloseIcon key="closeX" onClick={handleCloseOnClick} />);
    }

    if (showPlusLogo) {
      elements.push(<SolvPlusLogo key="plus-logo" onClick={handleLogoOnClick} />);
    } else if (showLogo) {
      elements.push(<Logo handleLogoOnClick={handleLogoOnClick} key="logo" />);
    }

    return elements;
  };

  const getTitleElement = () => {
    if (!title && !shortTitle) {
      return null;
    }

    if (typeof title === 'object') {
      return title;
    }

    const newTitle = shortTitle && !isWideFormat(viewport?.width ?? 0) ? shortTitle : title;

    return (
      <Text alignItems="center" display="flex" variant="subtitle">
        {newTitle}
      </Text>
    );
  };

  const handleLogout = () => {
    clearUserInfo({ remove });
    if (resetLogin) {
      resetLogin();
    } else {
      dispatch(resetLoginData());
    }
  };

  const getHeaderRightElements = () => {
    if (rightElement) {
      return rightElement;
    }

    if (showCog) {
      const handleCogOnClick = () => history.push('/account/settings');

      return <SettingsIcon onClick={handleCogOnClick} />;
    }

    if (showSiteMenu) {
      return <SiteMenu userProfileId={currentUserProfileId} />;
    }

    if (showCloseXRight) {
      return (
        <BaseClickable onClick={handleCloseOnClick}>
          <CloseIcon />
        </BaseClickable>
      );
    }

    if (logoutLink && isLoggedIn && !isNativeApp()) {
      return (
        <BaseClickable onClick={handleLogout}>
          <Text style={{ cursor: 'pointer' }} variant="body">
            {t('logOut')}
          </Text>
        </BaseClickable>
      );
    }

    if (showLogin) {
      return (
        <BaseClickable onClick={handleLoginOnClick}>
          <Text style={{ textDecoration: 'underline' }} variant="body">
            {t('logIn')}
          </Text>
        </BaseClickable>
      );
    }

    return null;
  };

  return (
    <>
      <LayoutDropShadow $hideDropShadow={hideDropShadow} />
      <Sticky enabled={sticky} innerZ={100}>
        {/* @ts-ignore (transparent isn't a color) */}
        <Stack bg={getHeaderBackground(theme)} flexDirection="row" p={2}>
          <Box alignItems="center" display="flex" flex="1 1 0" height="100%">
            {getHeaderLeftElements()}
          </Box>
          {getTitleElement()}

          <Box
            alignItems="center"
            alignSelf="flex-end"
            display="flex"
            flex="1 1 0"
            height="100%"
            justifyContent="flex-end"
            position="relative"
          >
            {getHeaderRightElements()}
          </Box>
        </Stack>
      </Sticky>
    </>
  );
};

export default Header;
