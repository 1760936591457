import Hidden from '@material-ui/core/Hidden';
import React from 'react';
import styled from 'styled-components';
import { Step } from '../steps';
import { colors } from '../../../../constants/colors';
import { fontFamily, fontSize } from '../../../../constants/text';
import { smallerThan } from '../../../../core/util/styledComponents';

const StepNumber = styled.div<{ $active?: boolean; $activeColor?: string }>`
  width: 40px;
  height: 40px;
  background: ${(props) => (props.$active ? props.$activeColor : colors.theOtherGray)};
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  color: white;
  flex-shrink: 0;

  ${smallerThan.md`
    margin-right: 0;
  `}
`;

const Button = styled.button<{ $active?: boolean }>`
  appearance: none;
  background: none;
  border: none;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  cursor: pointer;
  font-size: ${fontSize.medium};
  font-family: ${fontFamily.book};
  color: ${(props) => (props.$active ? colors.darkText : colors.lightGray)};
`;

type Props = {
  /** The zero-based index of the current step */
  index: number;

  /** The current step */
  step: Step;

  /** Whether this step is selected */
  active?: boolean;

  /** Color of the step when active */
  activeColor?: string;

  /** Click handler to update the step */
  onClick: (step: number) => void;
};

/**
 * A button/list item showing a single step for the "How it works" section.
 */
const StepButton = ({ index, step, active, onClick, activeColor }: Props) => {
  return (
    <Button $active={active} onClick={() => onClick(index)}>
      <StepNumber $active={active} $activeColor={activeColor}>
        {index + 1}
      </StepNumber>
      <Hidden smDown>
        <p>{step.text}</p>
      </Hidden>
    </Button>
  );
};

export default StepButton;
