import React, { useState } from 'react';
import styled from 'styled-components';
import { Text, Stack, Button } from '@solvhealth/jigsaw';
import {
  labsAndServices,
  resources,
  urgentCareByState,
  urgentCareLocations,
} from './locationLinks';
import { smallerThan, biggerThan, breakpoints } from '~/core/util/styledComponents';
import { analyticsTrackEvent } from '~/core/analytics';
import {
  HOMEPAGE_FIND_CARE_LINK_CLICK,
  HOMEPAGE_FIND_CARE_TAB_CLICK,
} from '~/core/analytics/events';
interface FindCareLink {
  name: string;
  url: string;
}
interface FindCareGroup {
  title: string;
  shortTitle: string;
  subtitle: string;
  image: string;
  imageAltText: string;
  links: FindCareLink[];
}
interface TitleProps {
  shortTitle: string;
  image: string;
  imageAltText: string;
}

interface LinkGridProps {
  links: FindCareLink[];
  hideItems: boolean;
  setHideItems: React.Dispatch<React.SetStateAction<boolean>>;
}
interface GroupItemProps {
  isSelected: boolean;
}

interface GridLinkProps {
  hide: boolean;
}

const Container = styled.div`
  padding: 34px 64px 72px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  ${smallerThan.md` 
    padding: 32px 16px;
  `}
`;

const Title = styled(Text)`
  margin: 0 auto;
`;

const StyledImg = styled.img`
  padding-right: 8px;
`;

const GroupItem = styled.div<GroupItemProps>`
  display: flex;
  align-items: center;
  ${({ isSelected }) =>
    isSelected &&
    `box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  `}
  ${biggerThan.md` 
    &:hover {
      background: #f5f7f9;
    }
  `}
  border-radius: 15px;
  padding: 16px;
  cursor: pointer;
  width: 207px;
  ${smallerThan.md`
    width: 165px;
  `}
`;
const GroupRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 32px;
  margin-right: 16px;
  ${smallerThan.md`
    margin: 0px;
  `}
`;

const GridWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(300px, 1fr));
  justify-items: left;
  width: 100%;
  padding-left: 96px;
  ${smallerThan.md`
    grid-template-columns: repeat(2, 1fr);
    padding-left: 0px;
  `}
`;

const GridLink = styled.a<GridLinkProps>`
  padding-bottom: 8px;
  ${({ hide }) => hide && smallerThan.md`display: none;`}
`;

const StyledButton = styled(Button)`
  ${biggerThan.md`
   display: none;
  `}
`;

const mdBreakPoint = `@media (max-width: ${breakpoints.md})`;

const centered = { justifyContent: 'center', alignItems: 'center' };

const linkData: FindCareGroup[] = [
  {
    title: 'Urgent care in popular cities',
    shortTitle: 'Popular cities',
    subtitle: 'Browse top-rated clinics, read patient reviews, and book online today with Solv.',
    image: 'popular-cities.svg',
    imageAltText: 'Urgent Care Icon',
    links: urgentCareLocations,
  },
  {
    title: 'Urgent care across the country',
    shortTitle: 'Care by State',
    subtitle: 'Access to same-day healthcare in every state coast-to-coast.',
    image: 'care-by-state.svg',
    imageAltText: 'United States map icon',
    links: urgentCareByState,
  },
  {
    title: 'Healthcare services',
    shortTitle: 'Services',
    subtitle: 'Find healthcare providers for the conditions and treatments you need most.',
    image: 'services.svg',
    imageAltText: 'Test vial icon',
    links: labsAndServices,
  },
  {
    title: 'Resources',
    shortTitle: 'Resources',
    subtitle: 'View our library of healthcare resources, articles, & products.',
    image: 'resources.svg',
    imageAltText: 'Mother and daughter icon',
    links: resources,
  },
];
const onLinkClick = (link: FindCareLink) => {
  analyticsTrackEvent(HOMEPAGE_FIND_CARE_LINK_CLICK, {
    link: link.name,
    url: link.url,
  });
};

const LinkGrid = ({ links, hideItems, setHideItems }: LinkGridProps) => {
  const showOrHideItems = () => {
    setHideItems(!hideItems);
  };

  return (
    <>
      <GridWrap>
        {links.map((l: FindCareLink, i) => (
          <GridLink
            hide={i > 9 && hideItems}
            href={l.url}
            key={l.name}
            onClick={() => onLinkClick(l)}
          >
            <Text sx={{ [mdBreakPoint]: { fontSize: '14px' } }} textColor="blueberry">
              {l.name}
            </Text>
          </GridLink>
        ))}
      </GridWrap>
      {hideItems && links.length > 9 && (
        <StyledButton onClick={showOrHideItems} variant="text">
          Show more
        </StyledButton>
      )}
      {!hideItems && links.length > 9 && (
        <StyledButton onClick={showOrHideItems} variant="text">
          Show less
        </StyledButton>
      )}
    </>
  );
};

function FindCare() {
  const [selectedLinks, setSelectedLinks] = useState(linkData[0].shortTitle);
  const [hideItems, setHideItems] = useState(true);

  const onTabClick = (shortTitle: string) => {
    setSelectedLinks(shortTitle);
    analyticsTrackEvent(HOMEPAGE_FIND_CARE_TAB_CLICK, {
      tab: shortTitle,
    });
  };

  const GroupTitle = ({ shortTitle, image, imageAltText }: TitleProps) => (
    <GroupItem isSelected={selectedLinks === shortTitle} onClick={() => onTabClick(shortTitle)}>
      <StyledImg alt={imageAltText} src={`images/homepage/FindCare/${image}`} />
      <Text fontWeight="medium">{shortTitle}</Text>
    </GroupItem>
  );

  return (
    <Container>
      <Title sx={{ paddingBottom: 4 }} variant="h2">
        Find care
      </Title>
      <GroupRow>
        {linkData.map(({ shortTitle, image, imageAltText }) => (
          <Stack
            key={shortTitle}
            row
            sx={{ marginRight: '16px', [mdBreakPoint]: { marginRight: '0px' } }}
          >
            <GroupTitle image={image} imageAltText={imageAltText} shortTitle={shortTitle} />
          </Stack>
        ))}
      </GroupRow>
      <Stack>
        {linkData.map(({ title, shortTitle, subtitle, links }) => (
          // display: none should be fine for SEO
          // https://www.burkdigitalfactory.com/blog/seo-css-display-none-is-it-bad-to-hide-content/
          <Stack
            key={shortTitle}
            space={4}
            sx={{ ...centered, display: shortTitle === selectedLinks ? 'flex' : 'none' }}
          >
            <Text variant="h2">{title}</Text>
            <Text>{subtitle}</Text>
            <LinkGrid hideItems={hideItems} links={links} setHideItems={setHideItems} />
          </Stack>
        ))}
      </Stack>
    </Container>
  );
}

export default FindCare;
