import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Text } from '@solvhealth/jigsaw';
import { colors } from '~/constants/colors';
import { analyticsTrackEvent } from '~/core/analytics';
import {
  ACTION_LOAD_SELF_PAY_SERVICE_TILES,
  ACTION_SEE_SELF_PAY_SERVICE_TILES,
  ACTION_VIEW_FEWER_SELF_PAY_SERVICES,
  ACTION_VIEW_MORE_SELF_PAY_SERVICES,
  HOMEPAGE,
} from '~/core/analytics/events';
import { useBreakpoint } from '~/hooks/useBreakpoint';
import Observer from '../../SolvPatternLibrary/Profilers/Observer';
import { ServiceForSearch, services } from './services';
import ServiceTag from './ServiceTag';
import { fontFamily, fontSize } from '~/constants/text';
import { smallerThan } from '~/core/util/styledComponents';
import { PricesSection } from '~/components/Home/PricesSection';

const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 1024px;
  margin: 0 80px;

  ${smallerThan.tablet`
    margin: 0 48px;
  `};

  ${smallerThan.sm`
    margin: 0 24px;
  `};

  .hidden {
    display: none;
  }
`;

const Note = styled(Text)`
  font-family: ${fontFamily.medium};
  font-size: ${fontSize.mediumLarge};
  color: ${colors.blueMonday};
  text-align: center;
`;

const ServicesTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 40px;
  gap: 16px;
`;

const Title = styled.h2`
  font-family: ${fontFamily.medium};
  font-size: ${fontSize.damnLarge};
  line-height: 52px;
  color: ${colors.darkText};
  text-align: center;

  ${smallerThan.tablet`
    font-size: ${fontSize.quiteExtraLarge};
    line-height: 32px;
    margin: 0 0 16px 0;
  `}
`;

const ViewMoreButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const ViewMoreButton = styled.button`
  font-family: ${fontFamily.book};
  font-size: ${fontSize.default};

  appearance: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  margin-top: 40px;
  color: ${colors.crunchBerry};
`;

type Props = {
  /** Callback to update the search bar */
  setSearchValues: (service: ServiceForSearch) => any;
};

/**
 * Section to show available services and quick searches.
 *
 */
const ServicesSection = ({ setSearchValues }: Props) => {
  const largerThanTablet = useBreakpoint('tablet');
  const [showMore, setShowMore] = useState(false);

  const numToShow = largerThanTablet ? 20 : 6;

  const variants = {
    visible: {
      y: 0,
      opacity: 1,
    },
    hidden: {
      y: 10,
      opacity: 0,
    },
  };

  useEffect(() => {
    analyticsTrackEvent(HOMEPAGE, {
      action: ACTION_LOAD_SELF_PAY_SERVICE_TILES,
    });
  }, []);

  // Handle whether this is seen
  const [seenOnce, setSeenOnce] = useState(false);

  const handleObserverChange = () => {
    if (!seenOnce) {
      setSeenOnce(true);
      analyticsTrackEvent(HOMEPAGE, { action: ACTION_SEE_SELF_PAY_SERVICE_TILES });
    }
  };

  const handleViewMoreClick = () => {
    const action = showMore
      ? ACTION_VIEW_FEWER_SELF_PAY_SERVICES
      : ACTION_VIEW_MORE_SELF_PAY_SERVICES;
    analyticsTrackEvent(HOMEPAGE, { action });
    setShowMore(!showMore);
  };

  return (
    <>
      <PricesSection />
      <Observer handleSawComponent={handleObserverChange}>
        <Root>
          <AnimateSharedLayout>
            <Title>See self-pay prices for common services nearby</Title>
            <Note>Pricing for more services coming soon</Note>
            <ServicesTags>
              <AnimatePresence>
                {services
                  .filter((s, i) => s.visible)
                  .filter((_, i) => showMore || i < numToShow)
                  .map((service) => (
                    <motion.div
                      animate="visible"
                      initial="hidden"
                      key={service.name}
                      variants={variants}
                    >
                      <ServiceTag onClick={() => setSearchValues(service)} title={service.name} />
                    </motion.div>
                  ))}
              </AnimatePresence>
            </ServicesTags>
            {!largerThanTablet && (
              <motion.div layout>
                <ViewMoreButtonWrapper>
                  <ViewMoreButton onClick={handleViewMoreClick}>
                    View {showMore ? 'less' : 'more'}
                  </ViewMoreButton>
                </ViewMoreButtonWrapper>
              </motion.div>
            )}
          </AnimateSharedLayout>
        </Root>
      </Observer>
    </>
  );
};

export default ServicesSection;
