import capitalize from 'lodash/capitalize';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BOOKING_REQUESTED_TIME_CHANGED, CONSUMER_APP } from '../../../constants';
import { analyticsTrackEvent } from '~/core/analytics';
import {
  ACTION_CHANGE_VALUE,
  APPOINTMENT_TIME_SET_BY_USER,
  UNIVERSAL_TIME_INPUT,
} from '~/core/analytics/events';
import { isMobile } from '~/core/util/device';
import { SolvReduxState } from '~/reducers';
import { TIME_SELECT_OPTIONS } from '../Inputs/defaults';
import DropdownInput from './inputs/DropdownInput';

type Props = { constrainToWidth?: string; collapseOnMobile?: boolean };

/**
 * Input to select the appointment time -- only shows releavant times after now()
 */
const WhenInput = (props: Props) => {
  const requestedTime = useSelector(
    (state: SolvReduxState) => state?.newBooking?.booking?.requestedAppointmentTime
  );

  const suggestions = useMemo(() => {
    const now = moment().startOf('minute');

    const suggestions = TIME_SELECT_OPTIONS.map(({ label, hourOffset, dayOffset, extraLabel }) => {
      const time =
        label === 'ASAP'
          ? now.valueOf()
          : moment().startOf('day').hour(hourOffset).add(dayOffset, 'day').valueOf();

      return {
        label,
        value: label,
        subLabel: capitalize(extraLabel.replace(/[\(\)]/g, '').trim()),
        time,
      };
    })
      // Filter to times that are after now
      .filter((d) => d.time >= moment(now).subtract(1, 'hour').valueOf());

    return suggestions;
  }, []);
  const [isDefaultSelection, setIsDefaultSelection] = useState(!requestedTime);

  const dispatch = useDispatch();
  const setAppointmentTime = useCallback(
    (value: any) => {
      const suggestion = suggestions.find((s) => s.value === value);
      dispatch({
        type: BOOKING_REQUESTED_TIME_CHANGED,
        payload: { value: { value: suggestion?.time, timeLabel: suggestion?.value } },
      });
      analyticsTrackEvent(APPOINTMENT_TIME_SET_BY_USER, { sourceOfInput: CONSUMER_APP });
      analyticsTrackEvent(UNIVERSAL_TIME_INPUT, {
        action: ACTION_CHANGE_VALUE,
        value: suggestion?.time,
        isMobile: isMobile(),
      });
    },
    [dispatch, suggestions]
  );

  const selectedDate = useMemo(() => {
    const defaultValue = 'ASAP';
    if (typeof requestedTime !== 'string') {
      if (requestedTime?.timeLabel) {
        setIsDefaultSelection(false);
        return requestedTime?.timeLabel;
      }
    }
    return defaultValue;
  }, [requestedTime]);

  return (
    <DropdownInput
      isDefaultSelection={isDefaultSelection}
      label="When?"
      labelOverride={selectedDate}
      onChange={setAppointmentTime}
      options={suggestions}
      trackingLabel={UNIVERSAL_TIME_INPUT}
      value={selectedDate}
      {...props}
    />
  );
};

export default WhenInput;
