import React from 'react';
import { useDispatch } from 'react-redux';
import { TouchableInputProps } from '..';
import { COVID_SERVICE_IDS } from '../../../../constants/services';
import { analyticsTrackEvent } from '../../../../core/analytics';
import { UNIVERSAL_SEARCH_BUTTON, ACTION_CTA_CLICK } from '../../../../core/analytics/events';
import { REQUEST_UNIVERSAL_SEARCH } from '../../../../sagas/universalSearch';
import LocationInput from '../LocationInput';
import SearchButton from '../SearchButton';
import WhenInput from '../WhenInput';

/**
 * The search form for a covid test.
 */
const CovidForm = ({ touched }: TouchableInputProps) => {
  const dispatch = useDispatch();

  // const doc = useSelector(state => state.searchPreferences)
  /**
   *
   */
  function search() {
    analyticsTrackEvent(UNIVERSAL_SEARCH_BUTTON, { action: ACTION_CTA_CLICK, isMobile: false });
    dispatch({
      type: REQUEST_UNIVERSAL_SEARCH,
      fromSrp: false,
      fullDoc: {
        highlight: 'COVID test',
        provider_type: 'urgent_care',
        type: 'service',
        name: 'COVID test',
        id: COVID_SERVICE_IDS.join(','),
        require_hard_filter: true,
      },
    });
  }

  return (
    <>
      <LocationInput hideDivider touched={touched} />
      <WhenInput collapseOnMobile />
      <SearchButton onSearch={search} />
    </>
  );
};
export default CovidForm;
