import React from 'react';
import { connect } from 'react-redux';
import { hideModal, showModal } from '../../actions/runtime';
import EmergencyModal, { EMERGENCY_MODAL_ID } from './EmergencyModal';
import { safeGet } from '../../core/util/object';

const mapStateToProps = (state: any) => ({
  open: safeGet(state, false)(`runtime.modal.${EMERGENCY_MODAL_ID}`),
  location: state.location,
});

const mapDispatchToProps = (dispatch: any) => ({
  toggleEmergencyModal: (value: any) =>
    dispatch((value ? showModal : hideModal)(EMERGENCY_MODAL_ID)),
});

const connected = connect(mapStateToProps, mapDispatchToProps)(EmergencyModal);

export default connected;
