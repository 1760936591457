import React from 'react';
import styled from 'styled-components';
import { smallerThan } from '../../core/util/styledComponents';

const logos = [
  {
    alt: 'The Wall Street Journal',
    src: '/images/homepage/logos/WSJ.png',
    hiddenSm: true,
  },
  {
    alt: 'Fortune',
    src: '/images/homepage/logos/Fortune.png',
  },
  {
    alt: 'USA Today',
    src: '/images/homepage/logos/UsaToday.png',
  },
  {
    alt: 'Forbes',
    src: '/images/homepage/logos/Forbes.png',
  },
  {
    alt: 'CNBC',
    src: '/images/homepage/logos/CNBC.png',
    hiddenSm: true,
  },
];

const Root = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: -20px -40px;
  padding: 0 20px 60px;
  transition: all 0.15s;

  ${smallerThan.lg`
    margin: -10px -20px;


  `}

  ${smallerThan.sm`
    & .hidden-sm {
        display: none;
    }
  `}
`;

const Logo = styled.img`
  flex: 0;
  height: 50px;
  margin: 20px 40px;

  ${smallerThan.lg`
    margin: 10px 20px;
    height: 30px;
  `}
`;

const LogosSection = ({ className }: { className?: string }) => {
  return (
    <Root className={className}>
      {logos.map((logo) => (
        <Logo className={logo.hiddenSm ? 'hidden-sm' : ''} key={logo.src} {...logo} />
      ))}
    </Root>
  );
};

export default LogosSection;
