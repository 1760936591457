// Tiles
import { IS_FLU_SEASON } from '../../../config';
import { PATIENT_TYPE_OPTIONS } from '~/constants';
import {
  ANTIBODY_COVID_TEST_SERVICE_ID,
  COVID_19_ANTIGEN_TEST_SERVICE_ID,
  COVID_19_PCR_TEST_SERVICE_ID,
  FLU_SHOT_SERVICE_ID,
  RAPID_COVID_19_ANTIGEN_TEST_SERVICE_ID,
  RAPID_COVID_19_PCR_TEST_SERVICE_ID,
} from '~/constants/services';
import { URGENT_CARE_VALUE } from '../Tiles/ProviderTypes';

export interface ServiceForSearch {
  name: string;
  providerType?: string;
  patientType?: typeof PATIENT_TYPE_OPTIONS[number];
  serviceIds?: string[];
  searchOverride?: string;
  searchType?: 'symptom' | 'service' | 'provider-type';
  onClick?: () => void;
  visible?: boolean;
}

export const services: ServiceForSearch[] = [
  {
    name: 'Flu shot',
    serviceIds: [FLU_SHOT_SERVICE_ID],
    searchType: 'service',
    visible: IS_FLU_SEASON,
  },
  {
    name: 'COVID PCR test',
    serviceIds: [COVID_19_PCR_TEST_SERVICE_ID],
    searchType: 'service',
    visible: true,
  },
  {
    name: 'Camp physical',
    searchType: 'service',
    visible: true,
  },
  {
    name: 'School physical',
    searchType: 'service',
    visible: true,
  },
  {
    name: 'Sports physical',
    searchType: 'service',
    visible: true,
  },
  {
    name: 'Stitches',
    searchType: 'service',
    visible: true,
  },
  {
    name: 'X-ray (chest)',
    searchType: 'service',
    visible: true,
  },
  {
    name: 'X-ray (extremity)',
    searchType: 'service',
    visible: true,
  },
];

/**
 * Returns a search update object
 */
export function getSearchUpdate(service: ServiceForSearch) {
  const update: any = {
    type: service.searchType ?? 'symptom',
    name: service.searchOverride ?? service.name,
    provider_type: service.providerType ?? URGENT_CARE_VALUE,
    highlight: service.searchOverride ?? service.name,
    patientType: service.patientType ?? PATIENT_TYPE_OPTIONS[0],
  };

  if (service.serviceIds) update.id = [...service.serviceIds, URGENT_CARE_VALUE].join('_');

  return update;
}
