import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { smallerThan } from '../../../core/util/styledComponents';
import { HomepageSearchType } from '../../../routes/home/Homepage';
import CovidIcon from '../CovidIcon';
import BottomNavCard from './BottomNavCard';

export interface BottomNavCardProps {
  title: string;
  linkText: string;
  type: HomepageSearchType;
  icon: ReactNode;
}

const cards: BottomNavCardProps[] = [
  {
    title: 'See someone, stat',
    linkText: 'Get in-person care',
    type: 'inPerson',
    icon: <PersonPinCircleIcon fontSize="inherit" />,
  },
  {
    title: 'Care from your couch',
    linkText: 'Book a video visit',
    type: 'video',
    icon: <VideoCallIcon fontSize="inherit" />,
  },
  {
    title: 'Book a COVID test',
    linkText: 'Find testing near you',
    type: 'covid',
    icon: <CovidIcon fontSize="inherit" />,
  },
];

const Root = styled.div`
  display: flex;

  > * {
    flex: 1;
  }

  > *:not(:last-child) {
    margin-right: 30px;
  }

  ${smallerThan.md`
    flex-direction: column;
    padding: 0 10px;

    > *:not(:last-child) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  `}
`;

type Props = {
  setSearchType: (t: HomepageSearchType) => void;
};
/**
 * Nav cards for the bottom of the page to key actions
 */
const BottomNavSection = ({ setSearchType }: Props) => {
  return (
    <Root>
      {cards.map((card, i) => (
        <BottomNavCard card={card} key={i} setSearch={setSearchType}></BottomNavCard>
      ))}
    </Root>
  );
};

export default BottomNavSection;
