import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Cookies } from 'react-cookie';
import {
  INTERCOM_APP_ID_CHAT_TEST,
  INTERCOM_APP_ID,
  LONG_TERM_SESSION_ID_COOKIE_NAME,
} from '../../config/index';
import { safeGet } from '../../core/util/object';
import { ADD_YOUR_AVAILABILITY } from '../../routes/addYourAvailability';
import withAuthentication from '../../core/login/withAuthentication';

const forProvidersRoute = '/for-providers';
const homePageRoute = '/';

const getSecretParamToForcePingRequest = () => ({
  // @ts-expect-error ts-migrate(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
  last_request_at: Math.floor(new Date() / 1000),
});

const containerId = 'intercom-container';

const intervalDelay = 100;

let isBooted = false;

const getIntercomContainer = () =>
  typeof document !== 'undefined' ? document.getElementById(containerId) : null;

export const hide = () => {
  const hideInterval = setInterval(() => {
    const intercomContainer = getIntercomContainer();
    if (intercomContainer) {
      intercomContainer.style.display = 'none';
      clearInterval(hideInterval);
    }
  }, intervalDelay);
};

export const show = () => {
  const showInterval = setInterval(() => {
    const intercomContainer = getIntercomContainer();
    if (intercomContainer) {
      intercomContainer.style.display = 'block';
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'string'.
      intercomContainer.style.zIndex = 10;
      clearInterval(showInterval);
    }
  }, intervalDelay);
};

type IntercomProps = {
  login?: any;
  newBooking?: any;
  path: string;
  isLoggedIn: boolean;
};

class Intercom extends PureComponent<IntercomProps> {
  componentDidMount = () => {
    if (this.shouldShowChatIcon()) {
      this.startIntercom();
    }
  };

  componentDidUpdate = (prevProps: any) => {
    if (!this.shouldShowChatIcon()) {
      return hide();
    }

    if (!isBooted) {
      return this.startIntercom();
    }

    if (this.shouldRebootWithDifferentAppId(prevProps)) {
      this.stopIntercom();
      return this.startIntercom();
    }

    show();
    return this.updateIntercom();
  };

  componentWillUnmount = () => {
    this.stopIntercom();
  };

  getAppId = (props: any) =>
    props.path === '/for-providers' ? INTERCOM_APP_ID : INTERCOM_APP_ID_CHAT_TEST;

  buildUserData = () => {
    const userData = {
      user_id: new Cookies().get(LONG_TERM_SESSION_ID_COOKIE_NAME),
    };

    const newBookingSafe = safeGet(this.props.newBooking);

    if (newBookingSafe('booking.reasonForVisit')) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'reasonForVisit' does not exist on type '... Remove this comment to see the full error message
      userData.reasonForVisit = newBookingSafe('booking.reasonForVisit');
    }

    if (newBookingSafe('booking.patientType')) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'patientType' does not exist on type '{ u... Remove this comment to see the full error message
      userData.patientType = newBookingSafe('booking.patientType');
    }

    if (newBookingSafe('booking.latitude')) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'latitude' does not exist on type '{ user... Remove this comment to see the full error message
      userData.latitude = newBookingSafe('booking.latitude');
    }

    if (newBookingSafe('booking.longitude')) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'longitude' does not exist on type '{ use... Remove this comment to see the full error message
      userData.longitude = newBookingSafe('booking.longitude');
    }

    if (this.props.isLoggedIn) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'email' does not exist on type '{ user_id... Remove this comment to see the full error message
      userData.email = this.props.login.account.email;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'phone' does not exist on type '{ user_id... Remove this comment to see the full error message
      userData.phone = this.props.login.account.phone;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'account_id' does not exist on type '{ us... Remove this comment to see the full error message
      userData.account_id = this.props.login.account.id;
    }

    return userData;
  };

  shouldShowChatIcon = () =>
    [ADD_YOUR_AVAILABILITY, forProvidersRoute, homePageRoute].includes(this.props.path) ||
    (this.props.path || '').includes('/account/people');

  shouldRebootWithDifferentAppId = (prevProps: any) =>
    this.getAppId(prevProps) !== this.getAppId(this.props);

  startIntercom = () => {
    if (isBooted) {
      return;
    }

    isBooted = true;

    const intercomSettings = {
      app_id: this.getAppId(this.props),
      ...this.buildUserData(),
    };

    const startInterval = setInterval(() => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'Intercom' does not exist on type 'Window... Remove this comment to see the full error message
      if (typeof window.Intercom === 'function') {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'Intercom' does not exist on type 'Window... Remove this comment to see the full error message
        window.Intercom('boot', intercomSettings);
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'intercomSettings' does not exist on type... Remove this comment to see the full error message
        window.intercomSettings = intercomSettings;
        clearInterval(startInterval);
      }
    }, intervalDelay);
  };

  stopIntercom = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'Intercom' does not exist on type 'Window... Remove this comment to see the full error message
    if (typeof window.Intercom === 'function') {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'Intercom' does not exist on type 'Window... Remove this comment to see the full error message
      window.Intercom('shutdown');
      isBooted = false;
    }
  };

  updateIntercom = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'Intercom' does not exist on type 'Window... Remove this comment to see the full error message
    if (typeof window.Intercom === 'function') {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'Intercom' does not exist on type 'Window... Remove this comment to see the full error message
      window.Intercom('update', this.buildUserData());
    }
  };

  render = () => <div />;
}

const mapStateToProps = (state: any) => ({
  login: state.login,
  newBooking: state.newBooking,
});

export default withAuthentication(connect(mapStateToProps)(Intercom));
