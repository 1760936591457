import { AnimatePresence, motion } from 'framer-motion';
import React, { RefObject, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { TabItem } from '.';
import { colors } from '../../../../constants/colors';
import { fontFamily, fontSize } from '../../../../constants/text';
import { smallerThan } from '../../../../core/util/styledComponents';
import Tab from './Tab';

const PillRoot = styled(motion.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  background: ${colors.darkText};
  border-radius: 1000px;
  z-index: 25;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ActiveTabText = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  white-space: nowrap;
  font-size: ${fontSize.default};
  font-family: ${fontFamily.medium};
  color: white;
  z-index: 25;

  ${smallerThan.sm`
    font-size: ${fontSize.mediumSmall};
  `}
`;

type Props = {
  /** List of refs for each tab */
  refs: { [key: string]: RefObject<any> };

  /** The key of the active tab */
  activeTab: TabItem;

  /** Whether we are currently animating */
  animating: boolean;

  /** Makes the animation stop */
  finishAnimating: () => void;

  /** The direction of the transition (+1 = right, -1 = left) */
  direction: 1 | -1;
};

/**
 * An animated Pill that follows the active tab
 */
const Pill = ({ refs, activeTab, animating, direction, finishAnimating }: Props) => {
  // Set our initial position and width
  const [{ x, width }, setAttributes] = useState({ x: 0, width: 0 });

  // Callback for whenever the tab is changed
  const updateAttributes = useCallback(() => {
    if (refs && refs[activeTab.value]) {
      setAttributes({
        x: refs[activeTab.value].current.offsetLeft,
        width: refs[activeTab.value].current.getBoundingClientRect().width,
      });
    }
  }, [activeTab, refs]);

  // Trigger the callback on tab change
  useEffect(() => {
    updateAttributes();
  }, [activeTab, refs, updateAttributes]);

  return (
    <>
      <PillRoot
        animate={{ x, width }}
        onAnimationComplete={finishAnimating}
        style={{ opacity: animating ? 1 : 0 }}
      >
        <AnimatePresence>
          <ActiveTabText
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: direction * -50 }}
            initial={{ opacity: 0, x: direction * -50 }}
            key={activeTab.value}
            transition={{ type: 'tween' }}
          >
            <Tab passive tab={activeTab} />
          </ActiveTabText>
        </AnimatePresence>
      </PillRoot>
    </>
  );
};

export default Pill;
