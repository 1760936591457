import { BaseClickable, Box, SolvTheme, Stack, Text } from '@solvhealth/jigsaw';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { analyticsTrackEvent } from '~/core/analytics';
import {
  SOLV_NOW_ENTRYPOINT_VISIBILITY,
  SOLV_PLUS_ENTRYPOINT_HOMEPAGE,
} from '~/core/analytics/events';
import { MEMBERSHIP_NAME, PREMIUM_VISIT_NAME } from '../Membership/constants';
import { useOnMembershipEntrypointClicked } from '../Membership/utils';
import history from '~/core/history';

const SolvNowSection = () => {
  const { onClick } = useOnMembershipEntrypointClicked(SOLV_PLUS_ENTRYPOINT_HOMEPAGE);

  const goToSolvNowLandingPage = () => {
    history.push('/solvnow');
  };

  const { t } = useTranslation(['membership']);

  return (
    /**
     * We set a custom max-width here because this section requires extra padding due to
     * its dark background
     **/
    <Stack maxWidth="1355px" mx="auto" p={{ _: 0, md: 3, lg: 5 }}>
      <Box
        bg="grape-medium"
        borderRadius={{ _: 'none', md: 'xl' }}
        display="grid"
        gridTemplateColumns={{ _: '1fr', md: '1fr 1fr' }}
      >
        <Stack
          alignItems="flex-start"
          maxWidth={SolvTheme.breakpoints.md}
          px={{ _: 4, md: 6, lg: 8 }}
          py={8}
          space={4}
        >
          <img alt="Solv Now" height={32} src="/images/membership/solv_now_logo.svg" width={130} />

          <Stack space={2}>
            <Text fontSize="3xl" fontWeight="medium" lineHeight="3xl" textColor="white">
              {t('membership:landingPage.solvNowSection.title')}
            </Text>
            <Text textColor="white">{t('membership:landingPage.solvNowSection.description')}</Text>
          </Stack>

          <Stack row>
            <Stack
              as={BaseClickable}
              bg="white"
              borderRadius="base"
              onClick={() => onClick()}
              px={4}
              py={2}
            >
              <Text fontWeight="medium" textColor="crunchberry">
                {t('membership:landingPage.solvNowSection.cta')}
              </Text>
            </Stack>

            <Stack as={BaseClickable} onClick={goToSolvNowLandingPage} px={4} py={2}>
              <Text fontWeight="medium" textColor="white">
                Learn more
              </Text>
            </Stack>
          </Stack>

          <Box display="inline">
            <Text display="inline" textColor="white">
              {t('membership:landingPage.solvNowSection.membershipPrompt', {
                SOLV_NOW_NAME: PREMIUM_VISIT_NAME,
              })}{' '}
            </Text>
            <BaseClickable
              contentProps={{ display: 'inline' }}
              href="/membership"
              inline
              sx={{ display: 'inline', wordWrap: 'break-word', whiteSpace: 'break-spaces' }}
            >
              <Text display="inline" fontWeight="medium" textColor="white">
                {t('membership:landingPage.solvNowSection.membershipCta', { MEMBERSHIP_NAME })}
              </Text>
            </BaseClickable>
          </Box>

          <Text textColor="grape-xlight" variant="caption">
            {t('membership:landingPage.solvNowSection.disclaimer')}
          </Text>
        </Stack>
        <Box display="grid" gridTemplateAreas="Image" pl={3} pt={3}>
          <Box
            backgroundPosition="bottom right"
            backgroundRepeat="no-repeat"
            backgroundSize="contain"
            minHeight={['275px', '480px']}
            position="relative"
            sx={{
              objectFit: 'contain',
              backgroundImage: `url(/images/homepage/solv-now-visit.svg)`,
            }}
          />
        </Box>
      </Box>
    </Stack>
  );
};

export default SolvNowSection;
