import anime from 'animejs';

/**
 * Scroll to the top searchbar
 * ! Only on the homepage
 */
export function scrollToSearch() {
  anime({
    targets: [document.body, document.documentElement],
    scrollTop: 0,
    duration: 300,
    easing: 'easeOutSine',
    complete: () => {
      const geoInputs = document.getElementsByClassName('geosuggest__input');
      if (geoInputs.length) {
        (geoInputs[0] as HTMLInputElement).focus();
      }
    },
  });
}
