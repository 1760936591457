import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { getSmsSeparator, isAndroidOS, isIOS, isPhone } from '../../core/util/device';
import { isExtraWideFormat } from '~/core/util/device/screen';
import withStyles from '../../core/isomorphic-style-loader/withStyles';
import { isEmptyObject } from '~/core/util/object';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module './SiteMenu.css' or its corresp... Remove this comment to see the full error message
import s from './SiteMenu.css';
import Link from '../Link';
import {
  BURGER_MENU_CLOSE,
  BURGER_MENU_OPEN,
  BURGER_MENU_TOUCH_ABOUT,
  BURGER_MENU_TOUCH_BLOG,
  BURGER_MENU_TOUCH_BOOK,
  BURGER_MENU_TOUCH_FOR_PROVIDERS,
  BURGER_MENU_TOUCH_HELP,
  BURGER_MENU_TOUCH_HOME,
  BURGER_MENU_TOUCH_LOGIN,
  BURGER_MENU_TOUCH_LOGOUT,
  BURGER_MENU_TOUCH_MY_ACCOUNT,
  BURGER_MENU_TOUCH_SAVE_SOLV,
  BURGER_MENU_TOUCH_SETTINGS,
  BURGER_MENU_TOUCH_SHARE_SOLV,
} from '~/core/analytics/events';
import { analyticsTrackEvent } from '~/core/analytics';
import { setHideAddToHomeScreenPopUpCookie } from '~/components/AddToHomeScreenPopUp/util';
import { getNameInitialsFromFullName, isEmptyString } from '~/core/util/string';
import { setRuntimeVariable } from '~/actions/runtime';
import { getCurrentPath } from '~/core/util/url';
import { BASE_URL, HELP_BASE_URL } from '../../config/index';
import { BLOG_PATH, FOR_PROVIDERS_PATH, SAVE_SOLV_PATH } from '../../constants/index';
import { getDefaultAccountRoute } from '~/core/login';
import InitialsBubble from '../SolvPatternLibrary/InitialsBubble';
import withAuthentication from '../../core/login/withAuthentication';
import { Viewport } from '~/components/useViewport';

const StyledInitialsBubble = styled(InitialsBubble)`
  margin-right: 5px;
`;

const trackClose = () => analyticsTrackEvent(BURGER_MENU_CLOSE);

const trackOpen = () => analyticsTrackEvent(BURGER_MENU_OPEN);

const trackAbout = () => analyticsTrackEvent(BURGER_MENU_TOUCH_ABOUT);

const trackBookNow = () => analyticsTrackEvent(BURGER_MENU_TOUCH_BOOK);

const trackHome = () => analyticsTrackEvent(BURGER_MENU_TOUCH_HOME);

const trackForProviders = () => analyticsTrackEvent(BURGER_MENU_TOUCH_FOR_PROVIDERS);

const trackLogIn = () => analyticsTrackEvent(BURGER_MENU_TOUCH_LOGIN);

const trackLogOut = () => analyticsTrackEvent(BURGER_MENU_TOUCH_LOGOUT);

const trackMyAccount = () => analyticsTrackEvent(BURGER_MENU_TOUCH_MY_ACCOUNT);

const trackSaveSolv = () => analyticsTrackEvent(BURGER_MENU_TOUCH_SAVE_SOLV);

const trackSettings = () => analyticsTrackEvent(BURGER_MENU_TOUCH_SETTINGS);

const trackHelp = () => analyticsTrackEvent(BURGER_MENU_TOUCH_HELP);

const trackBlog = () => analyticsTrackEvent(BURGER_MENU_TOUCH_BLOG);

const trackShareSolv = () => analyticsTrackEvent(BURGER_MENU_TOUCH_SHARE_SOLV);

type OwnSiteMenuProps = {
  accountSummary?: any;
  bmBurgerButton?: any;
  context?: string;
  fullScreen?: boolean;
  login?: any;
  menuIsOpen?: boolean;
  setSiteMenuOpen?: (...args: any[]) => any;
  showAddToHomeScreenPopUp: (...args: any[]) => any;
  theme?: string;
  userOnHomePage?: boolean;
  viewport?: Viewport;
  userProfileId?: string;
  isLoggedIn: boolean;
};

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'SiteMenuProps' circularly references i... Remove this comment to see the full error message
type SiteMenuProps = OwnSiteMenuProps & typeof SiteMenu.defaultProps;

// @ts-expect-error ts-migrate(7022) FIXME: 'SiteMenu' implicitly has type 'any' because it do... Remove this comment to see the full error message
const SiteMenu = (props: SiteMenuProps) => {
  const handleSaveSolvOnClick = () => {
    if (isAndroidOS() || isIOS()) {
      setHideAddToHomeScreenPopUpCookie(false);
      props.showAddToHomeScreenPopUp();
    } else {
      window.location.href = `${BASE_URL}${SAVE_SOLV_PATH}`;
    }
  };

  const handleForProvidersOnClick = () => {
    window.location.href = `${BASE_URL}${FOR_PROVIDERS_PATH}`;
  };

  const getLoginPath = () => {
    if (!isEmptyString(props.userProfileId)) {
      return `/account/login/next${getDefaultAccountRoute(props.userProfileId)}`;
    }

    return '/account/login';
  };

  const handleMenuItemOnClick = (item: any) => () => {
    props.setSiteMenuOpen(false);

    switch (item) {
      case 'about':
        return trackAbout();
      case 'bookNow':
        return trackBookNow();
      case 'forProviders':
        handleForProvidersOnClick();
        return trackForProviders();
      case 'home':
        return trackHome();
      case 'logIn':
        return trackLogIn();
      case 'logOut':
        return trackLogOut();
      case 'myAccount':
        return trackMyAccount();
      case 'saveSolv':
        handleSaveSolvOnClick();
        return trackSaveSolv();
      case 'shareSolv':
        return trackShareSolv();
      case 'settings':
        return trackSettings();
      case 'help':
        return trackHelp();
      case 'blog':
        return trackBlog();
      default:
        return null;
    }
  };

  const hamburgerOnly = () => !isExtraWideFormat(props.viewport.width);

  const shouldShowForProviders = () => {
    const currentPath = getCurrentPath();
    if (!currentPath) {
      return true;
    }

    return (
      currentPath === '/' ||
      currentPath.includes('about-us') ||
      currentPath.includes('for-providers') ||
      currentPath.includes('/info/') ||
      currentPath.includes('/review-calculator')
    );
  };

  const getMenuItemClassName = (menuItemName: any) =>
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    !hamburgerOnly() && getCurrentPath() && getCurrentPath().includes(menuItemName)
      ? s.menuItemActive
      : s.menuItem;

  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'getFirstProfileIdFromAccountSumm... Remove this comment to see the full error message
  const firstProfileId = getFirstProfileIdFromAccountSummary(props.accountSummary);

  const menuItemElements = {
    about: (
      <Link
        className={getMenuItemClassName('about')}
        key="aboutUs"
        onClick={handleMenuItemOnClick('about')}
        to="/about-us"
      >
        About
      </Link>
    ),
    bookNow: (
      <Link
        className={s.menuItem}
        key="bookNow"
        onClick={handleMenuItemOnClick('bookNow')}
        to="/account/book"
      >
        Book Now
      </Link>
    ),
    home: (
      <Link className={s.menuItem} key="home" onClick={handleMenuItemOnClick('home')} to="/">
        Home
      </Link>
    ),
    forProviders: (
      <Link
        className={getMenuItemClassName('for-providers')}
        key="forProviders"
        onClick={handleMenuItemOnClick('forProviders')}
        to="/for-providers"
      >
        For Providers
      </Link>
    ),
    logIn: (
      <Link
        className={getMenuItemClassName('login')}
        key="logIn"
        onClick={handleMenuItemOnClick('logIn')}
        to={getLoginPath()}
      >
        Log In
      </Link>
    ),
    logOut: (
      <Link
        className={s.menuItem}
        key="logOut"
        onClick={handleMenuItemOnClick('logOut')}
        to="/logout"
      >
        Log Out
      </Link>
    ),
    myAccount: (
      <Link
        className={s.menuItem}
        key="myAccount"
        onClick={handleMenuItemOnClick('myAccount')}
        to={getDefaultAccountRoute(firstProfileId)}
      >
        My Account
      </Link>
    ),
    saveSolv: !isAndroidOS() && (
      <div className={s.menuItem} key="saveSolv" onClick={handleMenuItemOnClick('saveSolv')}>
        <span className={s.saveSolv}>Download Solv</span>
      </div>
    ),
    shareSolv: isPhone() && (
      <div className={s.menuItem} key="shareSolv" onClick={handleMenuItemOnClick('shareSolv')}>
        <a
          href={
            `sms://${getSmsSeparator()}body=Hey, you should check out Solv! ` +
            "You can book a same-day doctor's appointment at a place that takes your insurance. " +
            'Super fast and free to book. http://solv.me/share/nav'
          }
        >
          Share Solv
        </a>
      </div>
    ),
    help: (
      <a
        className={s.menuItem}
        href={HELP_BASE_URL}
        key="help"
        onClick={handleMenuItemOnClick('help')}
      >
        Help
      </a>
    ),
    blog: (
      <div className={s.menuItem} key="blog">
        <a href={`${BASE_URL}${BLOG_PATH}`}>Blog</a>
      </div>
    ),
    settings: (
      <div className={s.menuItem} key="settings">
        <Link onClick={handleMenuItemOnClick('settings')} to="/account/settings">
          Settings
        </Link>
      </div>
    ),
  };

  const getLinkListItems = () => {
    const links = ['about'];

    if (shouldShowForProviders()) {
      links.push('forProviders');
    }

    links.push('blog');
    links.push('saveSolv');
    links.push('shareSolv');

    if (!props.isLoggedIn) {
      links.push('logIn');
    }

    // @ts-expect-error ts-migrate(7053) FIXME: No index signature with a parameter of type 'strin... Remove this comment to see the full error message
    return links.map((link) => menuItemElements[link]);
  };

  const getBurgerMenuItems = () => {
    let menuItems: any = [];
    const mobileOnlyItems = ['about', 'forProviders', 'saveSolv', 'blog', 'shareSolv'];
    const loggedInOnlyItems = ['home', 'myAccount', 'settings', 'help', 'logOut'];

    if (hamburgerOnly()) {
      menuItems = menuItems.concat(mobileOnlyItems);
      if (props.isLoggedIn) {
        menuItems = menuItems.concat(loggedInOnlyItems);
      } else {
        menuItems.push('logIn');
      }
    } else {
      menuItems = loggedInOnlyItems;
    }

    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return menuItems.map((item: any) => menuItemElements[item]);
  };

  const closeMenu = () => {
    trackClose();
    props.setSiteMenuOpen(false);
  };

  const openMenu = () => {
    trackOpen();
    props.setSiteMenuOpen(true);
  };

  const burgerMenu = (
    <div>
      <div className={s.menuOverlay} onClick={closeMenu} />

      <div className={s.menu}>{getBurgerMenuItems()}</div>
    </div>
  );

  if (!hamburgerOnly()) {
    if (!props.isLoggedIn || isEmptyObject(props.accountSummary)) {
      return <div className={s.linkList}>{getLinkListItems()}</div>;
    }

    const getInitials = () =>
      getNameInitialsFromFullName(
        `${props.accountSummary.first_name} ${props.accountSummary.last_name}`,
        ''
      );

    return (
      <div className={s.desktopMenu}>
        <div className={s.linkList}>{getLinkListItems()}</div>

        <div
          className={s.desktopMenuToggle}
          key="loggedInMenuToggle"
          onClick={props.menuIsOpen ? closeMenu : openMenu}
        >
          <StyledInitialsBubble
            firstName={props.accountSummary.first_name}
            lastName={props.accountSummary.last_name}
          />
          {!isEmptyString(props.accountSummary.first_name) && (
            <div className={s.greeting}>
              Hi,
              {props.accountSummary.first_name}
            </div>
          )}
        </div>
        {props.menuIsOpen && burgerMenu}
      </div>
    );
  }

  return (
    <div>
      <div className={s.menuIcon} data-testid="burger-menu" onClick={openMenu}>
        <div className={s.menuIconBar} />

        <div className={s.menuIconBar} />

        <div className={s.menuIconBar} />
      </div>
      {props.menuIsOpen && burgerMenu}
    </div>
  );
};

SiteMenu.defaultProps = {
  context: 'header',
};

const mapStateToProps = (state: any) => ({
  accountSummary: state.account.summary,
  login: state.login,
  menuIsOpen: state.runtime.siteMenuOpen || false,
  viewport: state.runtime.viewport,
});

const mapDispatchToProps = (dispatch: any) => ({
  setSiteMenuOpen: (open: any) =>
    dispatch(setRuntimeVariable({ name: 'siteMenuOpen', value: open })),

  showAddToHomeScreenPopUp: () =>
    dispatch(setRuntimeVariable({ name: 'showAddToHomeScreenPopUp', value: true })),
});

export default withStyles(s)(
  withAuthentication(connect(mapStateToProps, mapDispatchToProps)(SiteMenu))
);
