import React from 'react';
import styled from 'styled-components';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { palette } from '@solvhealth/jigsaw';
import { colors } from '~/constants/colors';
import { fontFamily, fontSize } from '~/constants/text';
import { smallerThan } from '~/core/util/styledComponents';

const Tag = styled.button`
  appearance: none;
  border: none;
  background: ${palette.egg};
  color: ${colors.darkText};
  padding: 16px 24px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  border-radius: 104px;
  font-family: ${fontFamily.medium};
  transition: background 0.15s;
  font-size: ${fontSize.default};
  cursor: pointer;
  display: flex;
  align-items: center;

  ${smallerThan.tablet`
    font-size: ${fontSize.mediumSmall};
    padding: 8px 16px;
  `}
  &:focus {
    outline: none;
  }

  &:hover {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  }
`;

const StyledChevronRight = styled(ChevronRight)`
  color: ${palette.mint.base};
`;

type Props = {
  /** The title of the service to display */
  title: string;

  /** An action to take when clicking the service */
  onClick: () => void;
};

/**
 * A small tag representing a single service or symptom
 */
const ServiceTag = ({ title, onClick }: Props) => {
  return (
    <Tag onClick={onClick}>
      {title} <StyledChevronRight />
    </Tag>
  );
};

export default ServiceTag;
