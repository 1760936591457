import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

const CovidIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg fill="none" viewBox="0 0 47 46" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M23.402 11.116V4.23M15.077 14.561l-4.714-4.706M11.623 22.892H4.852M15.078 31.215l-5.14 5.14M23.402 34.917v6.63"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="{10}"
          strokeWidth="{2}"
        />
        <path
          d="M23.4 4.23a2.079 2.079 0 100-4.158 2.079 2.079 0 000 4.158zM8.852 10.425a2.079 2.079 0 100-4.158 2.079 2.079 0 000 4.158zM2.774 24.97a2.079 2.079 0 100-4.157 2.079 2.079 0 000 4.158zM8.466 39.902a2.079 2.079 0 100-4.158 2.079 2.079 0 000 4.158z"
          fill="currentColor"
        />
        <path
          d="M31.809 31.607l4.706 4.706M35.254 23.284h6.771M31.98 14.782l4.963-4.963"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="{10}"
          strokeWidth="{2}"
        />
        <path
          d="M38.024 39.902a2.079 2.079 0 100-4.158 2.079 2.079 0 000 4.158zM44.106 25.362a2.079 2.079 0 100-4.158 2.079 2.079 0 000 4.158zM38.415 10.425a2.079 2.079 0 100-4.158 2.079 2.079 0 000 4.158zM23.4 45.704a2.079 2.079 0 100-4.158 2.079 2.079 0 000 4.158z"
          fill="currentColor"
        />
        <path
          clipRule="evenodd"
          d="M35.036 22.92c0 6.52-5.286 11.805-11.805 11.805-6.52 0-11.805-5.285-11.805-11.805S16.71 11.115 23.23 11.115s11.805 5.286 11.805 11.805zm-11.007-4.193a2.079 2.079 0 11-4.158 0 2.079 2.079 0 014.158 0zm-4.16 9.775a2.079 2.079 0 100-4.158 2.079 2.079 0 000 4.158zm10.375-4.158a2.079 2.079 0 11-4.158 0 2.079 2.079 0 014.158 0z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </svg>
    </SvgIcon>
  );
};

export default CovidIcon;
