import React from 'react';
import styled from 'styled-components';
import { capitalize } from '../../core/util/string';
import { fontFamily, fontSize } from '../../constants/text';
import { colors } from '../../constants/colors';

const Root = styled.div`
  background-color: ${colors.mrBlueSky};
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
`;

const Letters = styled.div`
  height: 15px;
  width: 17px;
  color: ${colors.white};
  font-family: ${fontFamily.book};
  font-size: ${fontSize.small};
  letter-spacing: 1.4px;
  line-height: 15px;
  margin: 0 auto;
`;

type Props = {
  className?: string;
  firstName: string;
  lastName: string;
};

const InitialsBubble = (props: Props) => {
  const { className, firstName, lastName } = props;

  return (
    <Root className={className}>
      <Letters>
        {capitalize(firstName.slice(0, 1))}
        {capitalize(lastName.slice(0, 1))}
      </Letters>
    </Root>
  );
};

export default InitialsBubble;
