import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useMemo, useState } from 'react';
import { TouchableInputProps } from '.';
import { STATE_CODE_TO_LABEL } from '../../../constants';
import { analyticsTrackEvent } from '../../../core/analytics';
import { ACTION_CHANGE_VALUE, UNIVERSAL_STATE_INPUT } from '../../../core/analytics/events';
import { apiGetJson } from '../../../core/dapi';
import { getTelemedStatesAvailableUrl } from '../../../core/dapi/location';
import { sortAlphabetical } from '../../../core/util/array';
import { AutoCompleteResult } from '../AutocompleteResults';
import { FieldDisplayProps } from './inputs/Field';
import TextInput from './inputs/TextInput';
import { useBestGuessUserLocation } from '~/components/UniversalSearch/Homepage/hooks/useBestGuessUserLocation';

type Props = { onChange: (state: string) => void } & TouchableInputProps &
  Omit<FieldDisplayProps, 'label'>;

/**
 * Input to select a state for a video visit
 */
const StateInput = ({ onChange, touched, ...props }: Props) => {
  const initialLocation = useBestGuessUserLocation();
  const locationLabel = initialLocation?.label || '';

  const [regionDisplayName, setRegionDisplayName] = useState('');

  const [eligibleTelemedRegions, setEligibleTelemedRegions] = useState<string[]>([]);

  useEffect(() => {
    if (!isEmpty(eligibleTelemedRegions)) {
      let regionName = '';
      if (Object.values(STATE_CODE_TO_LABEL).includes(locationLabel)) {
        // Location label is a (US) state name, so we can just use it
        regionName = locationLabel;
      } else {
        // Attempt to get the (US) state from location label (this is a workaround since
        // the "state" attribute is not always present on the location object)
        const usStateFromLocationLabel = locationLabel?.split(' ').pop()?.toLowerCase() || '';
        if (usStateFromLocationLabel in STATE_CODE_TO_LABEL) {
          regionName =
            STATE_CODE_TO_LABEL[usStateFromLocationLabel as keyof typeof STATE_CODE_TO_LABEL];
        }
      }

      const regionCode =
        Object.keys(STATE_CODE_TO_LABEL).find(
          (key) => STATE_CODE_TO_LABEL[key as keyof typeof STATE_CODE_TO_LABEL] === regionName
        ) || '';

      if (!eligibleTelemedRegions.includes(regionCode.toUpperCase())) {
        setRegionDisplayName('');
      } else {
        setRegionDisplayName(regionName);
      }
    }
  }, [eligibleTelemedRegions, locationLabel]);

  // For reasons currently inscrutable to me,
  // we can't call onChange directly as the onChange function
  // for the input field. (The input field stops responding to user input
  // if we do that.)
  useEffect(() => {
    onChange(regionDisplayName);
  }, [regionDisplayName, onChange]);

  useEffect(() => {
    apiGetJson(getTelemedStatesAvailableUrl()).then((json: any) => {
      const response = json?.data;
      if (!isEmpty(response)) {
        setEligibleTelemedRegions(response);
      }
    });
  }, []);

  const options = useMemo(() => {
    const entries = eligibleTelemedRegions
      .map((state) => {
        const lowerState = state.toLowerCase() as keyof typeof STATE_CODE_TO_LABEL;
        const label = STATE_CODE_TO_LABEL[lowerState];
        return {
          key: lowerState,
          primary: label,
          value: lowerState,
          onClick: () => {
            setRegionDisplayName(label);
            onChange(label);
            analyticsTrackEvent(UNIVERSAL_STATE_INPUT, {
              action: ACTION_CHANGE_VALUE,
              state: lowerState.toUpperCase(),
            });
          },
        } as AutoCompleteResult;
      })
      // Filter by those that match the current input
      .filter((state) => {
        return (
          state.key.toLowerCase().startsWith(regionDisplayName.toLowerCase()) ||
          state.primary.toLowerCase().startsWith(regionDisplayName.toLowerCase())
        );
      });
    sortAlphabetical(entries, (entry: any) => entry.primary);

    return entries;
  }, [onChange, regionDisplayName, eligibleTelemedRegions]);

  return (
    <TextInput
      hideDivider
      label="Location"
      onChange={setRegionDisplayName}
      placeholder="What state are you in?"
      results={regionDisplayName.length > 0 ? options : []}
      selectAllOnFocus
      setIsTouched={touched}
      trackingLabel={UNIVERSAL_STATE_INPUT}
      value={regionDisplayName}
      {...props}
    />
  );
};

export default StateInput;
