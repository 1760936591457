import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';
import { fontFamily, fontSize } from '../../../constants/text';
import { smallerThan } from '../../../core/util/styledComponents';
import { Testimonial } from './data';

type Props = {
  testimonial: Testimonial;
};

const Root = styled.div`
  background: ${colors.karl};
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  min-height: 16rem;
  ${smallerThan.tablet`
    min-height: 20rem;
  `}
  ${smallerThan.md`
    padding: 25px;
  `}
`;

const Avatar = styled.div<{ $src: string }>`
  width: 140px;
  height: 140px;
  border-radius: 100%;
  background-image: url('${(props) => props.$src}');
  background-size: cover;
  background-position: center;
  border: solid 5px white;
  margin-bottom: 15px;
`;

const Callout = styled.p`
  margin: 0;
  font-family: ${fontFamily.book};
  font-size: ${fontSize.extraExtraLarge};
  text-align: center;
  margin-bottom: 10px;
  color: ${colors.darkText};

  ${smallerThan.md`
    font-size: ${fontSize.extraLarge};
  `}
`;

const Text = styled.p`
  margin: 0;
  text-align: center;
  font-size: ${fontSize.default};
  color: ${colors.darkText};
  line-height: 1.5em;
  position: relative;
  max-width: 500px;
`;

/**
 * Card for a single testimonial
 */
const TestimonialCard = ({ testimonial }: Props) => {
  return (
    <Root>
      <Avatar $src={testimonial.photo}></Avatar>
      <Callout>&ldquo;{testimonial.callout}&rdquo;</Callout>
      <Text>{testimonial.text}</Text>
    </Root>
  );
};

export default TestimonialCard;
