exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
exports.locals = {
  "addToHomeScreen": "_2XaQuxYo",
  "closeButton": "_2_WWU5fR",
  "closeX": "_3vpDQncf",
  "addToHomeScreenContainer": "HfMtvyVk",
  "addButton": "_2bTknFiA",
  "addText": "_3hxIs8vH",
  "shareIcon": "m-Iux8fi"
};
exports.push([module.id, "._2XaQuxYo:after{background-color:#809db2;content:\"\";height:20px;left:0;position:relative;top:10px;-webkit-transform:rotate(45deg);transform:rotate(45deg);width:20px}._2_WWU5fR:after,._3vpDQncf:after{-webkit-transform:rotate(-45deg);transform:rotate(-45deg)}._2_WWU5fR:before,._3vpDQncf:before{-webkit-transform:rotate(45deg);transform:rotate(45deg)}._2_WWU5fR,._3vpDQncf{height:18px;position:relative;width:18px}._2_WWU5fR:after,._2_WWU5fR:before,._3vpDQncf:after,._3vpDQncf:before{background-color:#f0006a;content:\"\";height:2px;left:-2px;position:absolute;top:8px;width:23px}.HfMtvyVk{bottom:15px;display:flex;justify-content:center;position:fixed;width:100%;z-index:3}._2XaQuxYo{box-shadow:0 0 10px rgba(0,0,0,.4);-moz-box-shadow:0 0 10px rgba(0,0,0,.4);-webkit-box-shadow:0 0 10px rgba(0,0,0,.4);-o-box-shadow:0 0 10px;align-items:center;background-color:#809db2;border-radius:10px;color:#fff;display:flex;flex-direction:column;justify-content:space-between;margin:10px 0;padding-top:16px;width:300px}._2XaQuxYo>div:first-of-type{align-items:center;box-sizing:border-box;display:flex;font-size:16px;padding:0 20px 16px;position:relative;width:100%}._2bTknFiA{background-color:#fff;border-radius:10px;color:#809db2;font-size:30px;height:35px;line-height:30px;margin-right:10px;text-align:center;width:35px}._3hxIs8vH{font-family:Circular-Pro-Medium-S,Roboto,sans-serif;width:200px}._2_WWU5fR{height:10px;position:absolute;right:16px;top:0;width:10px}._2_WWU5fR:after,._2_WWU5fR:before{background-color:#fff;height:1px;width:14px}.m-Iux8fi{margin:0 7px 0 8px;width:16px}", ""]);