import React, { ComponentProps, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { symptomsSubmit } from '~/actions/symptoms';
import { CONSUMER_APP, PATIENT_TYPE_ADULTS, PATIENT_TYPE_OPTIONS } from '../../../constants';
import { analyticsTrackEvent } from '~/core/analytics';
import {
  PATIENT_TYPE_SET_BY_USER,
  UNIVERSAL_PATIENT_TYPE_INPUT,
  ACTION_CHANGE_VALUE,
} from '~/core/analytics/events';
import { isMobile } from '~/core/util/device';
import { useSolvSelector } from '~/reducers';
import DropdownInput from './inputs/DropdownInput';
import { FieldDisplayProps } from './inputs/Field';

type Props = Omit<FieldDisplayProps, 'label'>;

/**
 * Dropdown input for the homepage search to select adult or child.
 */
const PatientInput = (props: Props) => {
  const dispatch = useDispatch();

  const patientType = useSolvSelector((state) => state.newBooking?.booking?.patientType?.value);

  const [isDefaultSelection, setIsDefaultSelection] = useState(!patientType);

  useEffect(() => {
    if (patientType) {
      setIsDefaultSelection(false);
    }
  }, [patientType]);

  const setPatientType = useCallback(
    (patientType: string) => {
      const trackingProperties = {
        patientType,
        sourceOfInput: CONSUMER_APP,
        isMobile: isMobile(),
      };
      analyticsTrackEvent(PATIENT_TYPE_SET_BY_USER, trackingProperties);
      analyticsTrackEvent(UNIVERSAL_PATIENT_TYPE_INPUT, {
        action: ACTION_CHANGE_VALUE,
        ...trackingProperties,
      });
      setIsDefaultSelection(false);

      dispatch(
        symptomsSubmit({
          // we store value here as 'adults' or 'kids' but Redux needs the full object from PATIENT_TYPE_OPTIONS
          patientType:
            patientType === PATIENT_TYPE_ADULTS ? PATIENT_TYPE_OPTIONS[0] : PATIENT_TYPE_OPTIONS[1],
        })
      );
    },
    [dispatch]
  );

  return (
    <DropdownInput
      isDefaultSelection={isDefaultSelection}
      label="Who?"
      onChange={setPatientType}
      options={PATIENT_TYPE_OPTIONS as any as ComponentProps<typeof DropdownInput>['options']}
      trackingLabel={UNIVERSAL_PATIENT_TYPE_INPUT}
      value={patientType ?? PATIENT_TYPE_ADULTS}
      {...props}
    />
  );
};

export default PatientInput;
