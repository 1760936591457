import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import { smallerThan, smallerThanOrEqualTo } from '../../../core/util/styledComponents';
import { useBreakpoint } from '../../../hooks/useBreakpoint';
import { HomepageSearchType } from '../../../routes/home/Homepage';

/**
 * Constants for the background image sizes for desktop and mobile
 */
const DESKTOP_WIDTH = 1820;
const DESKTOP_HEIGHT = 790;
const DESKTOP_PADDING = (DESKTOP_HEIGHT / DESKTOP_WIDTH) * 100;

const MOBILE_WIDTH = 638;
const MOBILE_HEIGHT = 370;
const MOBILE_PADDING = (MOBILE_HEIGHT / MOBILE_WIDTH) * 100;

const Bg = styled.div<{ $color: string }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: background 0.15s;
  background: ${(props) => props.$color};
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom center;
  z-index: -1;
`;

const OuterWrap = styled.div`
  max-width: 1600px;
  margin: 144px auto auto auto;
  ${smallerThan.lg`
    margin-top: 96px;
  `}
`;

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  height: 0;
  width: 100%;
  margin-top: -150px;
  /* Derived from the desktop size constants below (height / width) */
  padding-bottom: ${DESKTOP_PADDING}%;
  ${smallerThan.lg`
     margin-top: -100px;
   `}
  ${smallerThan.md`
     margin-top: -80px;
   `}
   ${smallerThanOrEqualTo.tablet`
     margin-top: 0;
     /* Derived from the mobile size constants below (height / width) */
     padding-bottom: ${MOBILE_PADDING}%;
   `}
`;

const Image = styled(motion.img)`
  position: absolute;
  left: 0;
  bottom: 0;
  object-fit: contain;
  object-position: bottom;
  display: block;
  width: 100%;
  height: 100%;
  z-index: -1;
  padding: 0px 112px 0px 112px;
  ${smallerThan.lg`
    padding: 0px 64px 0px 64px; 
  `}
  ${smallerThan.md`
    padding: 0px 32px 0px 32px; 
  `}
  ${smallerThan.tablet`
    padding: 0px 16px 0px 16px; 
  `}
`;

/**
 * A set of backgound image/color sets to switch between
 *
 * Every desktop background is: 1470x798 (54.2857143%)
 * Every mobile background is: 642x390 (60.7476636%)
 */
const backgrounds: Record<
  HomepageSearchType,
  { foreground: string; foregroundMobile: string; color: string }
> = {
  inPerson: {
    foreground: '/images/homepage/Hero/InPerson/in-person.svg',
    foregroundMobile: '/images/homepage/Hero/InPerson/in-person-mobile.svg',
    color: '#d3f1ff',
  },
  labTest: {
    foreground: '/images/homepage/Hero/LabTest/lab-test.svg',
    foregroundMobile: '/images/homepage/Hero/LabTest/lab-test-mobile.svg',
    color: '#FDECF4',
  },
  video: {
    foreground: '/images/homepage/Hero/Video/video-visit.svg',
    foregroundMobile: '/images/homepage/Hero/Video/video-visit-mobile.svg',
    color: '#E4D8FC',
  },
  covid: {
    foreground: '/images/homepage/Hero/Covid/covid-test.svg',
    foregroundMobile: '/images/homepage/Hero/Covid/covid-test-mobile.svg',
    color: '#ffecd3',
  },
  vaccine: {
    foreground: '/images/homepage/Hero/Vaccine/fg.svg',
    foregroundMobile: '/images/homepage/Hero/Vaccine/fg-m.svg',
    color: '#D7F9F1',
  },
};

type Props = {
  /** Which type of background to show */
  type: HomepageSearchType;
};

/**
 * A hero background which animates between different booking types (in person / video / covid).
 */
const Background = ({ type }: Props) => {
  const current = backgrounds[type] ?? backgrounds.inPerson;
  const showMobileImage = useBreakpoint('tablet', 'below');
  const foregroundUrl = showMobileImage ? current.foregroundMobile : current.foreground;

  return (
    <>
      <Bg $color={current.color} />
      <OuterWrap>
        <Wrap>
          <AnimatePresence>
            <Image
              animate={{ y: 0, opacity: 1, position: 'absolute' }}
              exit={{ y: 50, opacity: 0, position: 'absolute' }}
              height={showMobileImage ? MOBILE_HEIGHT : DESKTOP_HEIGHT}
              initial={{ y: 50, opacity: 0, position: 'absolute' }}
              key={type}
              loading="lazy"
              src={foregroundUrl}
              width={showMobileImage ? MOBILE_WIDTH : DESKTOP_WIDTH}
            />
          </AnimatePresence>
        </Wrap>
      </OuterWrap>
    </>
  );
};

export default Background;
