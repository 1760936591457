import React from 'react';
import styled from 'styled-components';
import { colors } from '../../constants/colors';
import { fontFamily, fontSize } from '../../constants/text';
import { smallerThan } from '../../core/util/styledComponents';

const Box = styled.div`
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  display: flex;
  border-radius: 8px;
  overflow: hidden;

  ${smallerThan.md`
      flex-direction: column;
      width: 100%;
    `}
`;

const Image = styled.img`
  padding: 0px 0px 32px 16px;
  ${smallerThan.md`
      padding: 0px 16px 16px 16px;
    `}
`;

const Content = styled.div`
  padding: 40px 0px 40px 16px;
  width: 50%;

  ${smallerThan.md`
      padding: 0px 16px 0px 16px;
      width: auto;
    `}
`;

const Title = styled.h2`
  color: ${colors.gray600};
  font-family: ${fontFamily.bold};
  font-size: ${fontSize.damnLarge};
  margin: 0;
  margin-bottom: 15px;
`;

const Subtitle = styled.p`
  color: ${colors.crunchBerry};
  font-family: ${fontFamily.bold};
  font-size: ${fontSize.extraExtraLarge};
  margin: 0;
  margin-bottom: 10px;
`;

const Description = styled.p`
  color: ${colors.lightGray};
  font-family: ${fontFamily.book};
  margin: 0;
  margin-bottom: 40px;
`;

const CtaButton = styled.a`
  display: inline-block;
  background: ${colors.crunchBerry};
  color: white;
  text-decoration: none;
  padding: 10px 25px;
  border-radius: 4px;
  transition: background 0.15s;
  font-family: ${fontFamily.bold};
  font-size: ${fontSize.default};

  &:hover {
    background: ${colors.cherryBlossom};
  }

  &:visited {
    color: white;
  }

  ${smallerThan.md`
      display: block;
      text-align: center;
    `}
`;

/**
 * Nice little floating homepage card to link to the provider landing page.
 */
const ProviderSection = () => {
  return (
    <Box>
      <Image src="/images/homepage/Provider/solutions-for-providers.png" />
      <Content className="content">
        <Subtitle>Solutions for Healthcare Providers</Subtitle>
        <Title>Deliver a better patient experience</Title>
        <Description>
          Thousands of physicians have partnered with Solv to increase access, satisfy patients, and
          improve outcomes.
        </Description>
        <CtaButton
          href="https://www.solvhealth.com/for-providers?utm_source=homepage&utm_medium=onsite"
          target="_blank"
        >
          See how
        </CtaButton>
      </Content>
    </Box>
  );
};

export default ProviderSection;
