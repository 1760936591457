export interface Testimonial {
  photo: string;
  callout: string;
  text: string;
}

export const testimonials: Testimonial[] = [
  {
    photo: '/images/homepage/SuccessStories/1.png',
    callout: 'Every mom needs this',
    text: 'I just took my 7 year-old to urgent care and I was in and out in less than 30 minutes. Great app, great service!',
  },
  {
    photo: '/images/homepage/SuccessStories/2.png',
    callout: 'So easy to use',
    text: 'If I’m stuck in bed, I can open the app and see all the open times for urgent care appointments near me. It’s really user-friendly.',
  },
  {
    photo: '/images/homepage/SuccessStories/3.png',
    callout: 'Seriously amazing!',
    text: 'Solv saved me hours of calling clinics and likely thousands of $$. I found an appointment at a clinic that could see me right away instead of having to go to the emergency room.',
  },
];
