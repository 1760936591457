import Hidden from '@material-ui/core/Hidden';
import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../../constants/colors';
import { fontFamily, fontSize } from '../../../../constants/text';
import { smallerThan } from '../../../../core/util/styledComponents';
import { Step } from '../steps';
import StepButton from './StepButton';

const Root = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const StepText = styled.p`
  font-size: ${fontSize.medium};
  font-family: ${fontFamily.book};
  color: ${colors.darkText};
  text-align: center;
`;

const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${smallerThan.md`
    flex-direction: row;
    justify-content: center;
  `}

  & > *:not(:last-child) {
    margin-bottom: 2px;
  }
`;

type Props = {
  /** The individual steps to render in a list */
  steps: Step[];

  /** Callback to set the current step */
  setStep: (step: number) => void;

  /** The currently selected step */
  selectedStep: number;

  /** The background color of the step when active */
  activeColor: string;
};

/**
 * A list of steps for the "How it works" section
 */
const Steps = ({ steps, setStep, selectedStep, activeColor }: Props) => {
  return (
    <Root>
      <StepsContainer>
        {steps.map((step, index) => (
          <StepButton
            active={index === selectedStep}
            activeColor={activeColor}
            index={index}
            key={index}
            onClick={setStep}
            step={step}
          />
        ))}
      </StepsContainer>
      <Hidden mdUp>
        <StepText>{steps[selectedStep].text}</StepText>
      </Hidden>
    </Root>
  );
};

export default Steps;
