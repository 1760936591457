import React from 'react';

export default function TriageIcon() {
  return (
    <svg
      fill="none"
      height="250"
      viewBox="0 0 250 250"
      width="250"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2610_20779)">
        <path
          d="M125 250C194.04 250 250 194.03 250 125C250 55.97 194.03 0 125 0C55.97 0 0 55.96 0 125C0 194.04 55.96 250 125 250Z"
          fill="#A381C5"
        />
        <mask height="250" id="mask0_2610_20779" maskUnits="userSpaceOnUse" width="250" x="0" y="0">
          <path
            d="M125 250C194.04 250 250 194.03 250 125C250 55.97 194.03 0 125 0C55.97 0 0 55.96 0 125C0 194.04 55.96 250 125 250Z"
            fill="#EDF4F9"
          />
        </mask>
        <g mask="url(#mask0_2610_20779)">
          <path
            clipRule="evenodd"
            d="M12.0583 98.8302L15.4783 90.4902C15.4783 90.4902 21.3083 94.7602 20.9483 95.2902C20.5883 95.8102 16.1683 100.42 16.1683 100.42L12.0583 98.8302Z"
            fill="#222A33"
            fillRule="evenodd"
          />
          <path
            d="M169.149 30.7798L12.0591 98.8098L102.409 307.4L259.499 239.36L169.149 30.7798Z"
            fill="#222A33"
          />
          <path
            d="M172.539 22.46L15.4692 90.5L105.819 299.09L262.909 231.05L172.539 22.46Z"
            fill="#F50B7C"
          />
          <path
            clipRule="evenodd"
            d="M254.619 235.93L114.709 296.53L28.4695 96.4001L168.369 35.8301L254.609 235.94L254.619 235.93Z"
            fill="white"
            fillRule="evenodd"
          />
          <path
            d="M115.07 34.3399L104.74 38.7999C104.51 37.7899 104.2 36.7899 103.78 35.7999C100.01 27.0999 89.8896 23.0999 81.1896 26.8499C72.4896 30.6199 68.4896 40.7399 72.2396 49.4399C72.6596 50.4199 73.1796 51.3499 73.7696 52.2099L63.4796 56.6699C57.8196 59.1199 55.1996 65.6999 57.6496 71.3599L64.0596 86.2799L136.18 55.0399L129.79 40.1599C127.36 34.4999 120.76 31.8799 115.06 34.3299L115.07 34.3399ZM85.3196 36.4099C87.7496 35.3599 90.5596 36.4699 91.6096 38.8999C92.6596 41.3299 91.5496 44.1399 89.1196 45.1899C86.6896 46.2399 83.8796 45.1299 82.8296 42.6999C81.7796 40.2699 82.8896 37.4599 85.3196 36.4099Z"
            fill="#FF9F00"
          />
          <path
            d="M110.139 190.69L87.4885 200.5L97.2985 223.15L119.949 213.36L110.139 190.69Z"
            fill="#ABCE8B"
          />
          <path
            d="M128.6 233.33L105.95 243.14L115.76 265.79L138.41 255.98L128.6 233.33Z"
            fill="#ABCE8B"
          />
          <path
            d="M91.7694 148.28L69.1194 158.09L78.9294 180.74L101.579 170.93L91.7694 148.28Z"
            fill="#ABCE8B"
          />
          <path
            d="M80.1587 167.27L86.0687 168.76L88.2287 158.77"
            stroke="#F50B7C"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.37092"
          />
          <path
            d="M98.5786 209.78L104.509 211.27L106.669 201.28"
            stroke="#F50B7C"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.37092"
          />
          <path
            d="M116.989 252.3L122.899 253.79L125.079 243.8"
            stroke="#F50B7C"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.37092"
          />
          <path
            d="M58.0493 72.2903L64.0793 86.2903L136.199 55.0703"
            stroke="#222A33"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.37092"
          />
          <path
            d="M57.6492 71.3896L58.0493 72.3096"
            stroke="#222A33"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.37092"
          />
          <path
            d="M105.569 157.13L188.039 121.22"
            stroke="#222A33"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.37092"
          />
          <path
            d="M123.53 197.71L206 161.8"
            stroke="#222A33"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.37092"
          />
          <path
            d="M142.159 240.94L224.629 205.03"
            stroke="#222A33"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.37092"
          />
          <path
            d="M11.8691 98.3303L15.2591 90.0103C15.2591 90.0103 20.8791 95.3703 20.4991 96.1103C20.1391 96.8403 11.8691 98.3303 11.8691 98.3303Z"
            fill="#222A33"
          />
          <path
            d="M17.7791 95.7798L11.8691 98.3398L30.6391 141.71L36.5491 139.15L17.7791 95.7798Z"
            fill="#222A33"
          />
          <path
            d="M187.86 73.9397L193.77 71.3797L172.36 21.9697L15.26 90.0097L17.75 95.7897L168.96 30.2997L187.86 73.9497V73.9397Z"
            fill="#F50B7C"
          />
          <path
            d="M45.3691 135.36L28.2691 95.93L168.179 35.32L185.359 75.01L187.849 73.94L168.949 30.29L17.7791 95.78L36.5791 139.15L45.3791 135.36H45.3691Z"
            fill="#F50B7C"
          />
          <path
            d="M168.18 35.3203L28.2795 95.9203L45.3595 135.38L185.37 75.0103L168.19 35.3203H168.18Z"
            fill="white"
          />
          <path
            d="M114.88 33.8599L104.55 38.3199C104.32 37.3099 104.01 36.3099 103.59 35.3199C99.8199 26.6199 89.6999 22.6199 80.9999 26.3699C72.2999 30.1399 68.2999 40.2599 72.0499 48.9599C72.4699 49.9399 72.9899 50.8699 73.5799 51.7299L63.2899 56.1899C57.6299 58.6399 55.0099 65.2199 57.4599 70.8799L63.8699 85.7999L135.99 54.5599L129.6 39.6799C127.17 33.9999 120.57 31.3799 114.87 33.8499L114.88 33.8599ZM85.1299 35.9099C87.5599 34.8599 90.3699 35.9699 91.4199 38.3999C92.4699 40.8299 91.3599 43.6399 88.9299 44.6899C86.4999 45.7399 83.6899 44.6299 82.6399 42.1999C81.5899 39.7899 82.6999 36.9799 85.1299 35.9099Z"
            fill="#FF9F00"
          />
          <path
            d="M57.6992 71.3401L63.7292 85.3401L135.849 54.1201"
            stroke="#222A33"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.37092"
          />
          <path
            clipRule="evenodd"
            d="M129.568 92.2998L118.438 97.1198L113.618 85.9897L104.708 89.8497L109.528 100.98L98.3984 105.8L102.258 114.71L113.388 109.89L118.208 121.02L127.118 117.16L122.298 106.03L133.428 101.21L129.568 92.2998Z"
            fill="#F50B7C"
            fillRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2610_20779">
          <rect fill="white" height="250" width="250" />
        </clipPath>
      </defs>
    </svg>
  );
}
