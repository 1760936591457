import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import AccountNav from '../SolvPatternLibrary/AccountNav';
import HeaderV1 from '../HeaderV1';
import HeaderV2 from '../HeaderV2';
import AddToHomeScreenPopUp from '../AddToHomeScreenPopUp';
import AppFooter from '../AppFooter/AppFooter';
import { setRuntimeVariable } from '../../actions/runtime';
import useViewport, { Viewport } from '../useViewport';
import { colors } from '../../constants/colors';
import { fontFamily, fontSize } from '../../constants/text';
import withAuthentication from '../../core/login/withAuthentication';

const ConstrainedWidth = styled.div<{ $constrainWidth?: boolean }>`
  ${(props) =>
    props.$constrainWidth &&
    css`
      margin: 0 auto;
      max-width: 480px;
    `}
`;

const StyledLayoutRoot = styled.div<{ $backgroundColor?: string }>`
  background-color: ${(props) => props.$backgroundColor};
`;

const SecondHeader = styled.div`
  color: ${colors.blueMonday};
  display: flex;
  justify-content: center;
  background-color: ${colors.white};
  text-transform: uppercase;
  font-size: ${fontSize.mediumSmall};
  padding: 16px 0;
  font-family: ${fontFamily.bold};
  border-top: 1px solid ${colors.greyHounds};
  border-bottom: 1px solid ${colors.greyHounds};
`;

type LayoutProps = {
  hasDesktopStyling?: boolean;
  header?: string;
  hideFooter?: boolean;
  hideHeader?: boolean;
  hideHeaderBorder?: boolean;
  isLoggedIn?: boolean;
  hideHeaderWhenNarrowerThan?: number;
  setViewport: (viewport: Viewport) => void;
  userAgent: string;
  viewport?: Viewport;
  viewportFromState?: any;
  handleLogoOnClick?: (...args: any[]) => any;
  hideTabs?: boolean;
  hideLogIn?: boolean;
  showNav?: boolean;
  backgroundColor?: string;
  secondTitle?: string;
  className?: string;
  selectedTabId?: string;
  isConsumerOnly?: boolean;
  bottomElement?: React.ReactNode;
  children: React.ReactNode;
};

const Layout = ({
  userAgent,
  setViewport,
  viewportFromState,
  header = 'v2',
  isLoggedIn,
  hideTabs,
  hideLogIn,
  showNav,
  backgroundColor = 'white',
  className,
  secondTitle,
  hasDesktopStyling,
  children,
  hideFooter,
  hideHeader,
  hideHeaderWhenNarrowerThan,
  ...props
}: LayoutProps) => {
  const viewport = useViewport();

  useEffect(() => {
    if (!isEqual(viewportFromState, viewport) && !isEmpty(viewport)) setViewport(viewport);
  }, [viewport, setViewport, viewportFromState]);

  const hideHeaderFunc = () => {
    if (hideHeader) {
      return true;
    }
    if (hideHeaderWhenNarrowerThan) return viewportFromState.width < hideHeaderWhenNarrowerThan;
    return false;
  };

  if (!viewportFromState) return null;
  const HeaderComponent = header === 'v1' ? HeaderV1 : HeaderV2;
  const showingHeader =
    !hideHeaderFunc() && (header === 'v1' || !isLoggedIn || (hideTabs && hideLogIn));
  const showingNav = (showNav || !showingHeader) && isLoggedIn;

  return (
    <StyledLayoutRoot $backgroundColor={backgroundColor} className={className}>
      {showingNav && <AccountNav viewName="get-care" />}

      {showingHeader && <HeaderComponent {...props} />}

      {secondTitle && <SecondHeader>{secondTitle}</SecondHeader>}

      <ConstrainedWidth $constrainWidth={!hasDesktopStyling}>{children}</ConstrainedWidth>

      <AddToHomeScreenPopUp />

      {!hideFooter && <AppFooter />}
    </StyledLayoutRoot>
  );
};

const mapStateToProps = (state: any) => ({
  viewportFromState: state.runtime.viewport,
  userAgent: state.sessionTrackingProperties.userAgent,
});

const mapDispatchToProps = (dispatch: any) => ({
  setViewport: (viewport: Viewport) =>
    dispatch(setRuntimeVariable({ name: 'viewport', value: viewport })),
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuthentication(Layout));
