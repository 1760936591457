import { useEffect, useRef } from 'react';

type IntervalFunction = () => unknown | void;

/**
 * Allows an idiomatic use of setInterval with React Hooks.
 *
 * Written by Dan Abramov. Taken from:
 * https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 *
 *
 * @param callback The function to run each interval
 * @param delay  The interval delay time
 * @param deps A deps array to beforwarded to useEffect -- changes to these deps will reset the interval timer
 */
export function useInterval(callback: IntervalFunction, delay: number, deps: any[]) {
  const savedCallback = useRef<IntervalFunction | null>(null);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback, deps]);

  // Set up the interval.
  useEffect(() => {
    /**
     *
     */
    function tick() {
      if (savedCallback.current !== null) {
        savedCallback.current();
      }
    }

    let id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay, deps]);
}
