import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { STATE_CODE_TO_LABEL } from '~/constants';
import { apiGetJson } from '~/core/dapi';
import { getTelemedStatesAvailableUrl } from '~/core/dapi/location';
import { TouchableInputProps } from '..';
import { BASE_URL } from '../../../../config';
import { analyticsTrackEvent } from '../../../../core/analytics';
import { ACTION_CTA_CLICK, HOMEPAGE_VIDEO_VISIT_MODULE } from '../../../../core/analytics/events';
import { isClientSide } from '../../../../core/util/system';
import SearchButton from '../SearchButton';
import StateInput from '../StateInput';
import WhenInput from '../WhenInput';

/**
 * The search form for a video visit.
 */
const VideoForm = ({ touched }: TouchableInputProps) => {
  const [selectedRegion, setSelectedRegion] = useState('');
  const [regionCode, setRegionCode] = useState('');
  const [telemedEligibleRegions, setTelemedEligibleRegions] = useState<string[]>([]);

  useEffect(() => {
    apiGetJson(getTelemedStatesAvailableUrl()).then((json: any) => {
      const response = json?.data;
      if (!isEmpty(response)) {
        setTelemedEligibleRegions(response);
      }
    });
  }, []);

  useEffect(() => {
    if (!isEmpty(telemedEligibleRegions)) {
      const regionCode =
        Object.keys(STATE_CODE_TO_LABEL).find(
          (key) => STATE_CODE_TO_LABEL[key as keyof typeof STATE_CODE_TO_LABEL] === selectedRegion
        ) || '';

      if (telemedEligibleRegions.includes(regionCode.toUpperCase())) {
        setRegionCode(regionCode);
      } else {
        setRegionCode('');
      }
    }
  }, [selectedRegion, telemedEligibleRegions]);

  const directoryPageUrl = `${BASE_URL}/${regionCode}/s/${regionCode}-telemedicine`;

  /**
   *
   */
  function search() {
    analyticsTrackEvent(HOMEPAGE_VIDEO_VISIT_MODULE, { action: ACTION_CTA_CLICK });
    if (isClientSide()) {
      window.location.href = directoryPageUrl;
    }
  }
  return (
    <>
      <StateInput onChange={setSelectedRegion} touched={touched} />
      <WhenInput collapseOnMobile />
      <SearchButton disabled={!regionCode} onSearch={search} />
    </>
  );
};

export default VideoForm;
