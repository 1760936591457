import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { BottomNavCardProps } from '.';
import { colors } from '../../../constants/colors';
import { fontFamily, fontSize } from '../../../constants/text';
import { smallerThan } from '../../../core/util/styledComponents';
import { HomepageSearchType } from '../../../routes/home/Homepage';
import { scrollToSearch } from '../scrollToSearch';

type Props = {
  /** The card details */
  card: BottomNavCardProps;

  /** Sets the homepage search type */
  setSearch: (t: HomepageSearchType) => void;
};

const Card = styled.button`
  padding: 20px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: background 0.15s;
  text-decoration: none;
  cursor: pointer;
  appearance: none;
  border: none;
  background: white;
  font-family: ${fontFamily.regular};

  &:hover,
  &:focus {
    outline: none;
    background: ${colors.fiveSevenNineGrey};
  }

  ${smallerThan.md`
    flex-direction: row;
    justify-content: flex-start;
    padding: 15px;
  `}
`;

const Icon = styled.div`
  /** Make the icon/border a little vibrant by lightening + hue shifting MrBlueSky */
  --light-hue-shifted-mr-blue-sky: #e6f8fe;

  background: ${colors.mrBlueSky};
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--light-hue-shifted-mr-blue-sky);
  border-radius: 100%;
  font-size: 60px;
  border: solid 8px var(--light-hue-shifted-mr-blue-sky);
  margin-bottom: 12px;
  flex-shrink: 0;

  ${smallerThan.md`
    margin-bottom: 0;
    margin-right: 20px;
    width: 75px;
    height: 75px;
    font-size: 45px;
  `}

  ${smallerThan.sm`
    margin-bottom: 0;
    margin-right: 15px;
    width: 50px;
    height: 50px;
    font-size: 25px;
  `}
`;

const Text = styled.div`
  text-align: center;
  color: ${colors.darkText};
  ${smallerThan.md`
    text-align: left;
  `}
`;

const Title = styled.p`
  font-family: ${fontFamily.bold};
  font-size: ${fontSize.large};
  margin: 0;
  margin-bottom: 8px;
  text-align: center;

  ${smallerThan.md`
    text-align: left;
  `}

  ${smallerThan.sm`
    font-size: ${fontSize.default};
  `}
`;

const Link = styled.span`
  color: ${colors.crunchBerry};
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  ${smallerThan.md`
    justify-content: flex-start;
  `}

  ${smallerThan.sm`
    font-size: ${fontSize.mediumSmall};
  `}
`;

/**
 * A card which has an icon, title, subtitle, and links to a page
 */
const BottomNavCard = ({ card: { icon, title, type, linkText }, setSearch }: Props) => {
  /**
   *
   */
  function handleClick() {
    setSearch(type);
    scrollToSearch();
  }
  return (
    <Card onClick={handleClick}>
      <Icon>{icon}</Icon>
      <Text>
        <Title>{title}</Title>
        <Link>
          {linkText} <ChevronRightIcon opacity={0.7} />
        </Link>
      </Text>
    </Card>
  );
};

export default BottomNavCard;
