import { MutableRefObject, RefObject, useEffect } from 'react';

/**
 * Calls `callback` when the `ref`'s element is fully visible on the screen.
 *
 * Uses an IntersectionObserver under the hood, so it will not work (fail gracefully) on IE11.
 *
 * @param ref The object to observe
 * @param callback Called when the ref is fully visible on screen
 */
export function useObserveVisibility<T extends Element>(
  ref: RefObject<T | undefined> | MutableRefObject<T | undefined> | undefined,
  callback: () => void
) {
  useEffect(() => {
    // IE 11 and below do not support
    if (typeof IntersectionObserver === 'undefined') return;

    let observer = new IntersectionObserver(
      (entries) => {
        for (let entry of entries) {
          if (entry.isIntersecting && entry.target === ref?.current) {
            callback();
          }
        }
      },
      {
        threshold: 1, // we want the full item to be visible
      }
    );
    if (ref?.current) {
      observer.observe(ref.current);
    }
  }, [callback, ref]);
}
