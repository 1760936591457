import React from 'react';
import styled from 'styled-components';
import { Link, Text } from '@solvhealth/jigsaw';
import { colors } from '~/constants/colors';
import { fontFamily, fontSize } from '~/constants/text';

const Content = styled.div`
  margin: 0 24px;
`;

const EmphasizedText = styled(Text)`
  color: ${colors.darkText};
  font-size: ${fontSize.mediumLarge};
  font-family: ${fontFamily.bold};
  line-height: 32px;
`;

const MainText = styled(Text)`
  color: ${colors.darkText};
  font-family: ${fontFamily.regular};
  font-size: ${fontSize.mediumLarge};
  line-height: 32px;
`;

const Header = styled.div`
  background: linear-gradient(180deg, rgba(0, 213, 177, 0.08) 0%, rgba(0, 213, 177, 0) 100%);
  margin-top: 16px;
`;

const Title = styled.h2`
  font-family: ${fontFamily.medium};
  font-size: ${fontSize.damnLarge};
  line-height: 48px;
  color: ${colors.darkText};
  text-align: left;
  margin: 40px 24px;
`;

const HeaderImg = styled.img`
  width: 75vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 30px;
`;

const StyledLink = styled(Link)`
  font-size: ${fontSize.mediumLarge};
  margin: 16px 0px 40px 0px;
  text-decoration: underline;
`;

type Props = {
  title: React.ReactElement;
  emphasizedText: string;
  mainText: string;
  learnMoreButtonLabel: string;
  handleLearnMoreButtonClick: () => void;
};

export const NarrowPricesSection = ({
  title,
  emphasizedText,
  mainText,
  learnMoreButtonLabel,
  handleLearnMoreButtonClick,
}: Props) => (
  <>
    <Header>
      <HeaderImg loading="lazy" src="/images/homepage/clear-prices-intro.svg" />
      <Title>{title}</Title>
    </Header>
    <Content>
      <EmphasizedText>{emphasizedText}</EmphasizedText>
      <MainText>{mainText}</MainText>
      <StyledLink onClick={handleLearnMoreButtonClick}>{learnMoreButtonLabel}</StyledLink>
    </Content>
  </>
);
