import React from 'react';
import { useDispatch } from 'react-redux';
import { TouchableInputProps } from '..';
import { analyticsTrackEvent } from '../../../../core/analytics';
import { HOMEPAGE_LAB_TAB, ACTION_CTA_CLICK } from '../../../../core/analytics/events';
import { REQUEST_UNIVERSAL_SEARCH } from '../../../../sagas/universalSearch';
import LocationInput from '../LocationInput';
import PatientInput from '../PatientInput';
import SearchButton from '../SearchButton';
import UniversalInput from '../UniversalInput';
import WhenInput from '../WhenInput';

/**
 * The search fields for a lab test.
 */
const LabTestForm = ({ touched }: TouchableInputProps) => {
  const dispatch = useDispatch();

  const search = () => {
    analyticsTrackEvent(HOMEPAGE_LAB_TAB, { action: ACTION_CTA_CLICK, isMobile: false });
    dispatch({ type: REQUEST_UNIVERSAL_SEARCH, fromSrp: false });
  };

  return (
    <>
      <UniversalInput tab="labTest" touched={touched} />
      <LocationInput collapseOnMobile />
      <WhenInput collapseOnMobile constrainToWidth="250px" />
      <PatientInput collapseOnMobile constrainToWidth="150px" />
      <SearchButton onSearch={search} />
    </>
  );
};

export default LabTestForm;
