import React from 'react';
import { connect } from 'react-redux';
import Home from './Homepage';
import { HOMEPAGE_DESCRIPTION, HOMEPAGE_TITLE } from '../../core/opengraph';

function mapStateToProps(state: any) {
  return {
    login: state.login,
    pastBookings: state.pastBookings,
    viewport: state.runtime.viewport,
    accountSummary: state.account.summary,
  };
}

const description = HOMEPAGE_DESCRIPTION;

const ConnectedHome = connect(mapStateToProps)(Home);

export default (context: any) => {
  return {
    chunks: ['home'],

    component: <ConnectedHome />,
    description,
    title: HOMEPAGE_TITLE,
    accountWrapper: {
      loginRequired: false,
      nonBlocking: true,
    },
  };
};

if (module.hot) {
  module.hot.accept();
}
