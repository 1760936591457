import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { SolvReduxState } from '~/reducers';
import { SearchPreferencesState } from '~/reducers/searchPreferences';
import useUserPosition from '~/hooks/geo/useUserPosition';

export const useBestGuessUserLocation = () => {
  // GPS or IP location
  const { position } = useUserPosition();

  // Selected location
  const searchPreferences = useSelector<SolvReduxState, SearchPreferencesState>(
    (state) => state.searchPreferences
  );

  // Computed location that defers to 1) selected location 2) browser location 3) no location
  return useMemo(() => {
    return searchPreferences?.location ?? position?.result ?? null;
  }, [position?.result, searchPreferences?.location]);
};
