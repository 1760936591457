import React from 'react';
import { Cookies, withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { ADD_TO_HOME_SCREEN_POP_UP_COOKIE_NAME } from '../../config/index';
import { isAndroidOS, isIOS } from '../../core/util/device';
import { analyticsTrackEvent } from '../../core/analytics';
import { SAVE_SOLV_POP_UP_TOUCH_CLOSE } from '../../core/analytics/events';
import { setRuntimeVariable } from '../../actions/runtime';
import withStyles from '../../core/isomorphic-style-loader/withStyles';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module './AddToHomeScreenPopUp.css' or... Remove this comment to see the full error message
import s from './AddToHomeScreenPopUp.css';
import { show as showIntercom, hide as hideIntercom } from '../Intercom';

export const setHideAddToHomeScreenPopUpCookie = (hide: any) =>
  new Cookies().set(ADD_TO_HOME_SCREEN_POP_UP_COOKIE_NAME, hide, {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setYear' does not exist on type 'Date'.
    expires: new Date(new Date().setYear(2099)),
    path: '/',
  });

type AddToHomeScreenPopUpProps = {
  isVisible?: boolean;
  allCookies?: any;
  togglePopUp?: (...args: any[]) => any;
  userAgent?: string;
};

class AddToHomeScreenPopUp extends React.PureComponent<AddToHomeScreenPopUpProps> {
  showPopUp = () => {
    const { isVisible, userAgent, allCookies } = this.props;
    return (
      isVisible ||
      ((isAndroidOS(userAgent) || isIOS(userAgent)) &&
        allCookies[ADD_TO_HOME_SCREEN_POP_UP_COOKIE_NAME] === 'false')
    );
  };

  render = () => {
    if (!this.showPopUp()) {
      showIntercom();
      return null;
    }

    hideIntercom();

    const getPhoneNoun = () => (isIOS(this.props.userAgent) ? 'iPhone' : 'phone');

    const getButtonIconUrl = () =>
      isIOS(this.props.userAgent)
        ? '/images/icons/iphone_share.svg'
        : '/images/icons/android_menu.svg';

    const handleCloseOnClick = () => {
      analyticsTrackEvent(SAVE_SOLV_POP_UP_TOUCH_CLOSE);
      setHideAddToHomeScreenPopUpCookie(true);

      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      this.props.togglePopUp(false);
    };

    return (
      <div className={s.addToHomeScreenContainer}>
        <div className={s.addToHomeScreen}>
          <div>
            <div className={s.addButton}>+</div>

            <div className={s.addText}>
              Download Solv to your {getPhoneNoun()} for faster future bookings:
            </div>

            <div className={s.closeButton} onClick={handleCloseOnClick} />
          </div>

          <div>
            1. Tap
            <img alt="share" className={s.shareIcon} src={getButtonIconUrl()} />
            and then...
            <br />
            2. Tap "Add to Home Screen"
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state: any) => ({
  isVisible:
    typeof state.runtime.showAddToHomeScreenPopUp === 'undefined'
      ? false
      : state.runtime.showAddToHomeScreenPopUp,
  userAgent: state.sessionTrackingProperties.userAgent,
});

const mapDispatchToProps = (dispatch: any) => ({
  togglePopUp: (show: any) =>
    dispatch(setRuntimeVariable({ name: 'showAddToHomeScreenPopUp', value: show })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(s)(withCookies(AddToHomeScreenPopUp)));
