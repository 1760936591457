import { BaseClickable, Button, Icons, SolvTheme, Stack, style, Text } from '@solvhealth/jigsaw';
import React, { useEffect, useRef } from 'react';
import { analyticsTrackEvent } from '~/core/analytics';
import {
  ALMA_CARD_SEEN,
  ALMA_CARD_VISIBLE,
  ALMA_ENTRYPOINT_CLICKED,
  ALMA_ENTRYPOINT_HOMEPAGE,
} from '~/core/analytics/events';
import { useLDFlags } from '~/core/launch-darkly/hooks/useLDFlags';
import { useBreakpoint } from '~/hooks/useBreakpoint';
import { useObserveVisibility } from '~/hooks/useObserveVisibility';

function MentalHealthBanner() {
  const { snailsAlmaHomepageEnabled } = useLDFlags();

  useEffect(() => {
    analyticsTrackEvent(ALMA_CARD_VISIBLE, {
      entrypoint: ALMA_ENTRYPOINT_HOMEPAGE,
      visible: snailsAlmaHomepageEnabled,
    });
  }, [snailsAlmaHomepageEnabled]);

  const desktopRef = useRef(null);
  useObserveVisibility(desktopRef, () => {
    analyticsTrackEvent(ALMA_CARD_SEEN, {
      entrypoint: ALMA_ENTRYPOINT_HOMEPAGE,
    });
  });

  const mobileRef = useRef(null);
  useObserveVisibility(mobileRef, () => {
    analyticsTrackEvent(ALMA_CARD_SEEN, {
      entrypoint: ALMA_ENTRYPOINT_HOMEPAGE,
    });
  });

  if (!snailsAlmaHomepageEnabled) {
    return null;
  }

  return (
    <>
      <BaseClickable
        display={{ _: 'block', md: 'none' }}
        event={ALMA_ENTRYPOINT_CLICKED}
        href="/services/mental-health"
        meta={{ entrypoint: ALMA_ENTRYPOINT_HOMEPAGE }}
        ref={mobileRef}
        style={{
          maxWidth: SolvTheme.breakpoints.lg,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Stack alignItems="flex-end" mx="auto" px={2} row style={{ gap: SolvTheme.space[1.5] }}>
          <img alt="Mental health" height={86} src="images/homepage/mental-health.svg" width={78} />
          <Stack alignSelf="center" py={0.5} space={0.5}>
            <Stack alignItems="center" row space={0.5}>
              <Text variant="h5">Mental health services now available</Text>
              <Icons.ChevronLeft fontSize="xs" style={{ transform: 'rotate(.5turn)' }} />
            </Stack>
            <Text muted variant="body2">
              Connect with a certified therapist who can help with anxiety, depression and more.
            </Text>
          </Stack>
        </Stack>
      </BaseClickable>

      <Stack
        display={{ _: 'none', md: 'block' }}
        ref={desktopRef}
        style={{
          maxWidth: SolvTheme.breakpoints.lg,
          width: '100%',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Stack
          alignItems="center"
          mx="auto"
          pt={2}
          px={3}
          row
          style={{ gap: SolvTheme.space[5] }}
          width="100%"
        >
          <img
            alt="Mental health"
            height={132}
            src="images/homepage/mental-health.svg"
            width={120}
          />
          <Stack flex={1} space={0.5}>
            <Text variant="h1">Mental health services now available</Text>
            <Text>
              Connect with a certified therapist who can help with anxiety, depression and more.
            </Text>
          </Stack>
          <Stack>
            <Button
              event={ALMA_ENTRYPOINT_CLICKED}
              href="/services/mental-health"
              meta={{ entrypoint: ALMA_ENTRYPOINT_HOMEPAGE }}
            >
              Get started
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}

export default MentalHealthBanner;
